import { useQuery, type UseQueryOptions } from '@tanstack/react-query'
import { type IGetOrdersReservedResponde } from '../services/types'
import { GET_ORDERS_RESERVED } from '../constants'
import { getOrdersReserved } from '../services/api'

interface IUseOrdersReservedProps
  extends UseQueryOptions<IGetOrdersReservedResponde> {}

export function useOrdersReserved({ enabled = true }: IUseOrdersReservedProps) {
  return useQuery({
    enabled,
    queryKey: [GET_ORDERS_RESERVED],
    queryFn: async () => await getOrdersReserved().then(({ data }) => data),
  })
}
