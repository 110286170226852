import React from 'react'
import { Typography, Avatar } from '@material-ui/core'
import { ProposalTypography, Spacer } from '@77sol/core'
import palette from 'app_palette'
import moment from 'moment'
import 'moment/locale/pt-br'
import { useSelector } from 'react-redux'
import { ContainerQuotePdf } from '../ContainerQuotePdf'

export function ContainerPdf({
  container,
  index,
  logo,
  userName,
  stateQuotesPdf,
}) {
  const proposal = useSelector((state) => state.Reducer1)

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <div>
        {container.id === 'ClientPdf' && (
          <div style={{ marginTop: index == 0 ? 0 : '12px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex' }}>
                <Avatar
                  variant="rounded"
                  style={{
                    height: 'auto',
                    width: 'auto',
                    marginRight: '16px',
                    backgroundColor: !logo && '#D9DBE9',
                    fontSize: '40px',
                  }}
                  imgProps={{
                    style: {
                      height: 'initial',
                      width: 'initial',
                      maxHeight: '100px',
                      maxWidth: '140px',
                    },
                  }}
                  src={logo}
                >
                  {userName
                    ?.match(/(\b\S)?/g)
                    .join('')
                    .match(/(^\S|\S$)?/g)
                    .join('')
                    .toUpperCase()}
                </Avatar>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <ProposalTypography
                    type="display_x_small_bold"
                    color="primary"
                    colorWeight="300"
                  >
                    {userName}
                  </ProposalTypography>
                  <Spacer size="4" direction="horizontal" />
                  <Typography
                    style={{
                      fontSize: '10px',
                      color: palette.grayscale[500],
                      lineHeight: '15px',
                    }}
                  >
                    Proposta emitida:{' '}
                    {moment(proposal.proposal.created_at).format('DD/MM/YYYY')}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: '10px',
                      color: palette.grayscale[500],
                      lineHeight: '15px',
                    }}
                  >
                    Cliente:{' '}
                    {
                      proposal?.clients.find(
                        (client) => client.id == proposal.proposal.customer_id,
                      )?.label
                    }
                  </Typography>
                  {proposal?.clients.find(
                    (client) => client.id == proposal.proposal.customer_id,
                  )?.endereco && (
                    <Typography
                      style={{
                        fontSize: '10px',
                        color: palette.grayscale[500],
                        lineHeight: '15px',
                      }}
                    >
                      {
                        proposal?.clients.find(
                          (client) =>
                            client.id == proposal.proposal.customer_id,
                        )?.endereco
                      }
                      ,{' '}
                      {
                        proposal?.clients.find(
                          (client) =>
                            client.id == proposal.proposal.customer_id,
                        )?.numero
                      }{' '}
                      -{' '}
                      {
                        proposal?.clients.find(
                          (client) =>
                            client.id == proposal.proposal.customer_id,
                        )?.cidade
                      }
                      ,{' '}
                      {
                        proposal?.clients.find(
                          (client) =>
                            client.id == proposal.proposal.customer_id,
                        )?.uf
                      }
                    </Typography>
                  )}
                </div>
              </div>
              <Spacer size="4" direction="horizontal" />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {proposal.proposal.validity && (
                  <Typography
                    style={{
                      fontSize: '10px',
                      color: palette.grayscale[500],
                      lineHeight: '15px',
                    }}
                  >
                    Proposta válida por:{' '}
                    {moment(proposal.proposal.validity).diff(moment(), 'days') +
                      1}{' '}
                    dias
                  </Typography>
                )}
              </div>
            </div>
          </div>
        )}
        {proposal.quotes.map((quote, i) => (
          <>
            {container.id == `QuotePdf${i}` && (
              <div>
                {stateQuotesPdf[i].map((container, index) => (
                  <ContainerQuotePdf
                    container={container}
                    quote={quote}
                    i={i}
                    key={index}
                  />
                ))}
              </div>
            )}
          </>
        ))}

        {container.id == 'LinkPdf' && (
          <div>
            <div style={{ marginTop: '12px', width: '100%' }}>
              <Typography
                style={{
                  fontSize: '10px',
                  backgroundColor: palette.grayscale[300],
                  color: palette.grayscale[700],
                  padding: '4px 10px',
                }}
              >
                Para aceitar a proposta ou requisitar mudanças, acesse:
              </Typography>
              <table
                style={{
                  borderSpacing: '0px',
                  width: '100%',
                  border: '1px solid #DFE3E8',
                }}
              >
                <tr>
                  <td>
                    <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                      <a
                        style={{
                          fontSize: '10px',
                          wordBreak: 'break-all',
                          lineHeight: '15px',
                          color: palette.grayscale[700],
                        }}
                        href={() => false}
                      >
                        {`${process.env.REACT_APP_PROPOSAL_LINK}/${proposal.proposal.token}`}
                      </a>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        )}

        {Number.isInteger(container.id) && (
          <div>
            {proposal.extraConfigs.extra_configs
              .find((item) => item.id == container.id)
              ?.title?.split('/')[1] ? (
              <div style={{ marginTop: '12px' }}>
                <img
                  alt="extra config"
                  style={{ width: '100%', height: 'auto' }}
                  src={
                    proposal.extraConfigs.extra_configs.find(
                      (item) => item.id == container.id,
                    )?.content
                  }
                />
              </div>
            ) : (
              <div style={{ marginTop: '12px' }}>
                <Typography
                  style={{
                    fontSize: '10px',
                    backgroundColor: palette.grayscale[300],
                    color: palette.grayscale[700],
                    padding: '4px 10px',
                  }}
                >
                  {
                    proposal.extraConfigs.extra_configs.find(
                      (item) => item.id == container.id,
                    )?.title
                  }
                </Typography>
                <table
                  style={{
                    borderSpacing: '0px',
                    width: '100%',
                    border: '1px solid #DFE3E8',
                  }}
                >
                  <tr>
                    <td>
                      {proposal.extraConfigs.extra_configs
                        .find((item) => item.id == container.id)
                        ?.content.includes('.s3') ? (
                        <img
                          alt="extra config"
                          style={{ width: '100%', height: 'auto' }}
                          src={
                            proposal.extraConfigs.extra_configs.find(
                              (item) => item.id == container.id,
                            )?.content
                          }
                        />
                      ) : (
                        <Typography
                          style={{
                            fontSize: '8px',
                            padding: '4px 10px',
                            lineHeight: '12px',
                            color: palette.grayscale[600],
                          }}
                        >
                          {
                            proposal.extraConfigs.extra_configs.find(
                              (item) => item.id == container.id,
                            )?.content
                          }
                        </Typography>
                      )}
                    </td>
                  </tr>
                </table>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
