import React from 'react'
import { Grid, Tooltip } from '@material-ui/core'
import { Spacer, Paper } from '@77sol/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import * as S from './styles'
import { colors } from '@77sol-ui/tokens'
import { Alert } from '@77sol-ui/atoms'
import { Pencil, Trash } from 'lucide-react'
import UploadIcon from './UploadIcon'

function CoverImageEdit({
  image,
  handleFinish,
  handleRemoveImage,
  square,
  hideText,
  equips,
  noBackground,
  loading,
}) {
  const handleChangeStatus = ({ file, remove }, status) => {
    if (status === 'preparing') {
      remove()
      handleFinish(file)
    }
  }

  return (
    <S.Container hideText={hideText}>
      {!hideText && (
        <div className="context">
          <Alert.Root rounded variant="info">
            <Alert.Content alignment="horizontal">
              <Alert.Icon variant="info" />
              <Alert.Description description="São suportados apenas arquivos em PNG ou JPEG de até 10MB." />
            </Alert.Content>
          </Alert.Root>
        </div>
      )}
      <Grid spacing={2} container>
        <Grid item xs={12} md={12}>
          <Paper
            style={{
              display: 'flex',
              width: square ? '200px' : '100%',
              height: noBackground ? '150px' : '200px',
              alignItems: 'center',
              padding: '0px',
              background: noBackground && 'none',
            }}
          >
            <Dropzone
              maxFiles={1}
              onChangeStatus={handleChangeStatus}
              multiple={false}
              PreviewComponent={() => (
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress
                    disableShrink
                    style={{ color: colors.blue[300] }}
                    size={30}
                    thickness={4}
                  />
                </div>
              )}
              inputContent={
                loading ? (
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress
                      disableShrink
                      style={{ color: colors.blue[300] }}
                      size={30}
                      thickness={4}
                    />
                  </div>
                ) : image ? (
                  <div
                    style={{
                      backgroundImage:
                        equips && typeof image !== 'string'
                          ? `url(${URL.createObjectURL(image)})`
                          : `url(${image})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    <Paper
                      style={{
                        position: 'absolute',
                        bottom: '8px',
                        right: '8px',
                        display: 'flex',
                        width: 'auto',
                        height: 'auto',
                        padding: '8px',
                      }}
                    >
                      <Tooltip title="Alterar">
                        <Pencil width="24" color={colors.gray[700]} />
                      </Tooltip>
                      <Spacer size="16" direction="vertical" />
                      <Tooltip title="Remover">
                        <Trash
                          onClick={(e) => {
                            handleRemoveImage(image)
                            e.preventDefault()
                            e.stopPropagation()
                          }}
                          width="24"
                          color={colors.red[300]}
                        />
                      </Tooltip>
                    </Paper>
                  </div>
                ) : (
                  <S.DropzoneContent>
                    <UploadIcon />
                    <h3>Clique para fazer o upload da imagem</h3>
                    <span>PNG ou JPG (max. 10MB)</span>
                  </S.DropzoneContent>
                )
              }
              accept="image/*"
              styles={{
                dropzone: {
                  padding: '30px',
                  border: image ? 'none' : `1px dashed ${colors.blue[200]}`,
                  flexGrow: 1,
                  alignSelf: 'stretch',
                  borderRadius: '8px',
                  flexDirection: 'column',
                  overflow: 'hidden',
                  color: '#1335c6',
                  width: '100%',
                  height: image ? '100%' : 'auto',
                },
                preview: {
                  border: 'none',
                },
                input: {
                  display: 'none',
                },
                inputLabel: {
                  color: '#1335c6',
                  fontSize: '16px',
                  textAlign: 'center',
                  padding: image ? '0px' : '16px',
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </S.Container>
  )
}

export default CoverImageEdit
