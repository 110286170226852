import { useLocation } from 'react-router-dom'

export function useFinancingRequestParams() {
  const location = useLocation()

  const URL = new URLSearchParams(location.search)
  const search = URL.get('search') ?? ''
  const page = URL.get('page') ?? ''
  const status = URL.get('status') ?? ''
  const started_at = URL.get('started_at') ?? ''
  const ended_at = URL.get('ended_at') ?? ''

  const params = URL.toString()

  return { page, status, started_at, ended_at, search, params }
}
