import { Root } from './styles'

const STATUS_COLOR = {
  Solicitado: 'yellow',
  'Pré aprovado': 'blue',
  'Em análise': 'orange',
  'Com pendências': 'purple',
  'Crédito aprovado': 'green',
  Concluído: 'dark-blue',
  Negado: 'red',
  Cancelado: 'gray',
}

function FinancialLabel({ status }) {
  const color = STATUS_COLOR[status]

  if (color) {
    return <Root variant="filled" color={color} text={status} />
  }

  return <Root variant="filled" color="gray" text="Não solicitado" />
}

export default FinancialLabel
