import { useCallback, useMemo } from 'react'
import { ChooseTemplate } from '../steps/ChooseTemplate'
import { HEADER, MODAL_SIZE, STEP_TRACKERS } from '../constants'
import { ChooseQuotation } from '../steps/ChooseQuotation'
import { ProposalPreview } from '../steps/ProposalPreview'
import { useSelector } from 'react-redux'
import { type IReduxStore } from 'types/redux'
import { DownloadPDF } from '../steps/DownloadPDF'
import { usePdfStore } from './usePDFStore'
import { type IQuotationDTO } from 'dto/QuotationDTO'
import { type QUOTATION_PDF_TEMPLATE_ID_ENUM } from 'enums/quotationPDF/QuotationPDFEnum'
import { type IReadUserSettingsResponse } from 'domains/pdf-express/read-user-settings/services/types'
import { type IQuotationPDFData } from '../store/usePDFDataStore'
import type {
  IDimensioningGETResponse,
  IDimensioningPropertiesDTO,
} from 'domains/dimensioning/services/types'
import { useNavigationSteps } from './useNavigationSteps'
import { useDisableButtons } from './useDisableButtons'
import { useDownloadPDF } from './useDownloadPDF'
import { type IProposalDTO } from 'dto/ProposalDTO'
import { useAmplitude } from 'hooks/useAmplitude'
import { proposalPDFTracker } from 'services/tracker/events/proposalPDF'
import { fileTracker } from 'services/tracker/events/files/trackers'
import { proposalTracker } from 'services/tracker/events/proposal/trackers'

interface useModalExportPDFStepsProps {
  initialStep?: number
  userSettingsData: IReadUserSettingsResponse | undefined
  onOpenChange: (open: boolean) => void
  dimensioningData: IDimensioningGETResponse | undefined
  dimensioningDataIsLoading?: boolean
  dimensioningDataIsError?: boolean
}

export interface IPDFData {
  template: '1' | '2' | '3' | undefined
  selectedQuotations: IQuotationDTO[]
  dimensioning: IDimensioningGETResponse
}

export const useModalExportPDFSteps = ({
  userSettingsData,
  initialStep,
  onOpenChange,
  dimensioningData,
  dimensioningDataIsLoading,
  dimensioningDataIsError,
}: useModalExportPDFStepsProps) => {
  const {
    allPDFsLoaded,
    quotationsPDFBuilt,
    selectedQuotations,
    setAllPDFsLoaded,
    setSelectedQuotations,
    setSelectedQuotationsBuilt,
    setTemplate,
    template,
    firstPDFPreviewLoaded,
    setFirstPdfLoaded,
  } = usePdfStore()

  const { logEvent } = useAmplitude()

  const PDFData = useMemo(
    () => ({
      dimensioning: dimensioningData,
      selectedQuotations,
      template,
    }),
    [dimensioningData, selectedQuotations, template],
  )

  const { userProfile, logo } = useSelector(
    (state: IReduxStore) => state.ReducerProfile,
  )

  const {
    handleDownloadPDF,
    isLoading: downloadIsLoading,
    revokeURLS,
  } = useDownloadPDF()

  const selectedTemplate = (PDFData?.template ??
    userSettingsData?.default_template_id ??
    '1') as '1' | '2' | '3'

  function trackerAdvanceStep(from: number, to: number) {
    const eventName = proposalPDFTracker.actions.advanceStep

    const eventProps = new Map()

    eventProps.set('from', STEP_TRACKERS[from])

    eventProps.set('to', STEP_TRACKERS[to])

    if (from === 0) {
      eventProps.set('template_selected', selectedTemplate)
    }

    if (from === 1) {
      eventProps.set('quotation_quantity', selectedQuotations.length)
    }

    logEvent(eventName, {
      ...Object.fromEntries(eventProps),
    })
  }

  const { currentStep, handleNextStep, handlePrevStep } = useNavigationSteps({
    initialStep: initialStep ?? 0,
    onClose: () => {
      onOpenChange(false)
    },
    onSubmit: () => {
      handleDownloadPDFs()
    },
    onChangedStep: (from, to) => {
      if (from < to) {
        trackerAdvanceStep(from, to)
      }
    },
  })

  const { cancelDisabled, okDisabled, okIsLoading } = useDisableButtons({
    allPDFsLoaded,
    currentStep,
    hasSelectedQuotation: PDFData.selectedQuotations.length > 0,
    userSettingsDataIsLoaded: Boolean(userSettingsData?.user_id),
    firstPDFPreviewLoaded,
    downloadIsLoading,
  })

  const handleAllPDFsLoaded = useCallback(
    (data: Array<{ quotationId: number }>) => {
      const updatedQuotations: IQuotationPDFData[] = []
      data.forEach((element) => {
        const findQuotation = selectedQuotations.find(
          (item) => item.id === element.quotationId,
        )
        if (findQuotation)
          updatedQuotations.push({
            ...element,
            products: [
              ...(findQuotation.products || []),
              ...(findQuotation.added_products || []),
            ],
          })
      })
      setSelectedQuotationsBuilt(updatedQuotations)
      setAllPDFsLoaded(true)
    },
    [selectedQuotations, setAllPDFsLoaded, setSelectedQuotationsBuilt],
  )

  const handleDownloadPDFs = () => {
    for (const quotation of quotationsPDFBuilt) {
      logEvent(fileTracker.actions.downloadFile, {
        quotation_id: quotation.quotationId,
        template_id: selectedTemplate,
        file: 'Proposta PDF',
        origin: `${window.location.pathname} - Modal Proposta PDF`,
      })
    }

    logEvent(proposalTracker.actions.sendProposalToCustomer, {
      quotations_quantity: quotationsPDFBuilt.length,
      template_id: selectedTemplate,
      origin: `${window.location.pathname} - Modal Proposta PDF`,
      sendBy: 'export',
    })

    handleDownloadPDF({
      dimensioningProps:
        dimensioningData?.dimensioning_properties as IDimensioningPropertiesDTO,
      pdfData: quotationsPDFBuilt,
      proposal: dimensioningData?.proposal as IProposalDTO,
      userLogged: {
        ...userProfile,
        company_logo: logo,
      },
      // @ts-expect-error userSettings unused property
      userSettings: {
        ...userSettingsData,
        default_template_id: selectedTemplate,
      },
    })
  }

  const steps = useMemo(
    () => [
      <ChooseTemplate
        key={0}
        selectedTemplate={selectedTemplate}
        onSelect={(template) => {
          setTemplate(template as QUOTATION_PDF_TEMPLATE_ID_ENUM)
        }}
      />,
      <ChooseQuotation
        key={1}
        quotations={dimensioningData?.quotations ?? []}
        initialSelectedQuotations={selectedQuotations ?? []}
        dimensioningDataIsError={dimensioningDataIsError}
        dimensioningDataIsLoading={dimensioningDataIsLoading}
        onChangeQuotations={(selectedQuotations) => {
          setSelectedQuotations(selectedQuotations)
        }}
      />,
      <ProposalPreview
        key={2}
        onFirstPdfLoaded={() => {
          setFirstPdfLoaded(true)
        }}
        selectedTemplate={selectedTemplate}
        dimensioning={dimensioningData}
        userSettings={userSettingsData}
        userLogged={{
          ...userProfile,
          company_logo: logo,
        }}
        pdfData={PDFData as IPDFData}
        onAllPDFsLoaded={(data) => {
          handleAllPDFsLoaded(data)
        }}
      />,
      <DownloadPDF key={3} />,
    ],
    [
      selectedTemplate,
      dimensioningData,
      selectedQuotations,
      userSettingsData,
      userProfile,
      logo,
      PDFData,
      setTemplate,
      setSelectedQuotations,
      setFirstPdfLoaded,
      handleAllPDFsLoaded,
    ],
  )

  const stepToRender = useMemo(
    () => ({
      icon: HEADER[currentStep].icon,
      title: HEADER[currentStep].title,
      description: HEADER[currentStep].description,
      content: steps[currentStep],
      modalWidth: MODAL_SIZE[currentStep].width,
      modalHeight: MODAL_SIZE[currentStep].height,
      cancelText: HEADER[currentStep].cancelText,
      okText: HEADER[currentStep].okText,
      okDisabled,
      cancelDisabled,
      okIsLoading,
      revokeURLS,
    }),
    [cancelDisabled, currentStep, okDisabled, okIsLoading, revokeURLS, steps],
  )

  return {
    handlePrevStep,
    handleNextStep,
    stepToRender,
    currentStep,
    PDFData,
    okDisabled,
    cancelDisabled,
    revokeURLS,
  }
}
