import { fontFamily } from '@77sol-ui/tokens'
import styled, { keyframes } from 'styled-components'

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const StyledAlert = styled.div`
  animation: ${fade} 0.3s ease-in-out;
  margin-bottom: 4px;

  * {
    font-family: ${fontFamily.sans[0]};
  }

  @media (min-width: 992px) {
    * {
      white-space: nowrap;
    }
  }
`
