import { PDFViewer, type IPDFViewerProps } from 'components/PDFViewer'
import { useReactPDFViewer } from './hooks/useReactPDFViewer'
import { ErrorState } from 'components/ErrorState'
import { CircularProgress } from 'components/CircularProgress'
import { type IPDFTemplatesPreviewProps } from '../..'
import { useEffect } from 'react'
import { useAmplitude } from 'hooks/useAmplitude'
import { proposalPDFTracker } from 'services/tracker/events/proposalPDF'

export interface IReactPDFViewerProps extends IPDFTemplatesPreviewProps {
  viewerProps: Omit<IPDFViewerProps, 'url'>
  onLoaded?: (value: boolean) => void
}

export function ReactPDFViewer({
  viewerProps,
  onLoaded,
  ...props
}: IReactPDFViewerProps) {
  const { url, isLoading, isError, revokeObjectURL } = useReactPDFViewer({
    previewProps: props,
  })

  const { logEvent } = useAmplitude()

  useEffect(() => {
    if (!isLoading && !isError && onLoaded) {
      onLoaded(true)
    }
  }, [isError, isLoading, onLoaded])

  if (isLoading) {
    return (
      <CircularProgress
        data-testid="loading-react-pdf-viewer"
        text="Carregando..."
      />
    )
  }

  if (!isLoading && isError) {
    return (
      <ErrorState
        data-testid="error-react-pdf-viewer"
        title="Ops! Algo deu errado ao carregar o PDF."
        subtitle="Não foi possível carregar o visualidor de PDF."
        showImage
      />
    )
  }

  const trackerPreviewPagination = (from: number, to: number) => {
    const eventProps = new Map()

    eventProps.set('origin', `${window.location.pathname} > Modal Preview PDF`)
    eventProps.set('from', `Page ${from}`)
    eventProps.set('to', `Page ${to}`)

    if (from < to) {
      eventProps.set('action', 'Avançar')
    } else if (from > to) {
      eventProps.set('action', 'Voltar')
    }

    logEvent(proposalPDFTracker.actions.navigateBetweenPdfPages, {
      ...Object.fromEntries(eventProps),
    })
  }

  return (
    <PDFViewer
      url={url}
      {...viewerProps}
      onChangedPage={(from, to) => {
        trackerPreviewPagination(from, to)
        viewerProps?.onChangedPage?.(from, to)
      }}
      documentProps={{
        ...viewerProps.documentProps,
        onLoadSuccess: (e) => {
          viewerProps.documentProps?.onLoadSuccess?.(e)
          revokeObjectURL()
        },
      }}
    />
  )
}
