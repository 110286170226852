import {
  TEMPLATES_NAME,
  TEMPLATES_INFOS,
} from 'containers/PDFTemplatesSelector/constants'
import { TemplateSelector } from '../../TemplateSelector'
import { TEMPLATE_SIMPLE_ID } from 'containers/PDFTemplatesSelector/test/constants'

interface ISimpleProps {
  showPreview?: boolean
  onExpandPreview?: () => void
}

export function Simple({ onExpandPreview, showPreview = true }: ISimpleProps) {
  const { id, image, title } = TEMPLATES_INFOS.SIMPLE

  return (
    <TemplateSelector
      data-testid={TEMPLATE_SIMPLE_ID}
      name={TEMPLATES_NAME}
      showPreview={showPreview}
      onExpandPreview={onExpandPreview}
      image={image}
      title={title}
      value={id}
    />
  )
}
