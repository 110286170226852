import { DropdownMenu } from '@77sol-ui/atoms'
import { Tooltip } from 'components/Tooltip'
import { type ISendProposalProps } from 'containers/SendProposal'
import { ButtonSend, StyledDropdownItem } from 'containers/SendProposal/styles'
import { Send } from 'lucide-react'
import React from 'react'

interface ISingleButtonProps
  extends Omit<ISendProposalProps, 'dimensioningId' | 'buttonType'> {
  onPdfProposalClick: () => void
  onDigitalProposalClick: () => void
}
export const SingleButton: React.FC<ISingleButtonProps> = ({
  asIcon,
  buttonSize = 'lg',
  variant,
  onPdfProposalClick,
  onDigitalProposalClick,
}) => {
  return (
    <DropdownMenu.Root dir="ltr">
      {asIcon && (
        <Tooltip variant="white" content="Enviar">
          <DropdownMenu.Trigger asChild>
            <ButtonSend size={buttonSize} variant={variant}>
              <Send size={16} />
            </ButtonSend>
          </DropdownMenu.Trigger>
        </Tooltip>
      )}
      {!asIcon && (
        <DropdownMenu.Trigger asChild>
          <ButtonSend size={buttonSize} variant={variant}>
            <span>Enviar</span>
            <Send size={16} />
          </ButtonSend>
        </DropdownMenu.Trigger>
      )}
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          side="bottom"
          sideOffset={4}
          onCloseAutoFocus={(e) => {
            e.preventDefault()
          }}
        >
          <StyledDropdownItem onSelect={onPdfProposalClick}>
            Proposta PDF
          </StyledDropdownItem>

          <StyledDropdownItem onSelect={onDigitalProposalClick}>
            Proposta Digital
          </StyledDropdownItem>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
