import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;

  > h3 {
    flex: 1;
  }

  > div {
    display: flex;
    align-items: center;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;

  button {
    > svg {
      width: 20px;
      height: 20px;
    }
  }
`
