import { useQuery, type UseQueryOptions } from '@tanstack/react-query'
import { readUserSettings } from '../services/api'
import { READ_USER_SETTINGS } from '../constants/endpointKeys'
import { type IUserSettingsDTO } from 'dto/UserSettingsDTO'
import { type IReadUserSettingsResponse } from '../services/types'

interface IUseReadUserSettings
  extends Omit<UseQueryOptions, 'onSuccess' | 'onError'> {
  userId: IUserSettingsDTO['user_id']
  onSuccess?: (data: IReadUserSettingsResponse) => void
  onError?: () => void
}

export function useReadUserSettings({
  enabled,
  retry = false,
  userId,
  onSuccess,
  onError,
  staleTime = 60 * 1000, // 1 minute
}: IUseReadUserSettings) {
  return useQuery({
    queryKey: [`${READ_USER_SETTINGS}`, userId],
    queryFn: async () => {
      try {
        const data = await readUserSettings({ userId })
        onSuccess?.(data)
        return data
      } catch (error) {
        onError?.()
        throw new Error(error as string)
      }
    },
    enabled,
    retry,
    staleTime,
  })
}
