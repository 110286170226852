import * as S from './styles'
import errorImage from './assets/error-state.svg'
import { EmptyState, type IEmptyStateRootProps } from '@77sol-ui/molecules'

interface IErrorState extends Partial<IEmptyStateRootProps> {
  title?: string
  subtitle?: string
  buttonText?: string
  showImage?: boolean
  onButtonAction?: () => void
}

export function ErrorState({
  title,
  subtitle,
  buttonText,
  showImage = true,
  onButtonAction,
  ...props
}: IErrorState) {
  const buttonIsVisible = Boolean(buttonText && onButtonAction)

  return (
    <EmptyState.Root {...props}>
      {showImage && <S.Image src={errorImage} alt="tomada desconectada" />}
      {title && <EmptyState.Title>{title}</EmptyState.Title>}
      {subtitle && <EmptyState.Subtitle>{subtitle}</EmptyState.Subtitle>}
      {buttonIsVisible && (
        <EmptyState.Button onClick={onButtonAction}>
          {buttonText}
        </EmptyState.Button>
      )}
    </EmptyState.Root>
  )
}
