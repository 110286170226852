import { UserRole } from 'acl/config/types'
import { useAcl } from 'acl/hooks/useAcl'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useCallback, useEffect, useMemo, useState } from 'react'

interface IUseDisplayNewsAnnouncementProps {
  localStorageKey: string
  enable?: boolean
}

export type ViewHistory = Record<string, number[]>

export function useDisplayNewsAnnouncement({
  localStorageKey,
  enable = true,
}: IUseDisplayNewsAnnouncementProps) {
  const [isOpen, setIsOpen] = useState(false)
  const { user, userRole } = useAcl()

  const [viewHistory, setViewHistory] = useLocalStorage<ViewHistory | null>(
    `news-${localStorageKey}`,
    null,
  )

  const isAdminOrSuperAdmin = useMemo(
    () => userRole === UserRole.ADMIN || userRole === UserRole.SUPER_ADMIN,
    [userRole],
  )

  const hasAlreadySeenFiveTimes = useMemo(() => {
    return viewHistory?.[user[0].id] && viewHistory?.[user[0].id]?.length >= 5
  }, [viewHistory, user])

  const hasAlreadySeenToday = useMemo(() => {
    return viewHistory?.[user[0].id]?.some((timestamp) => {
      const date = new Date(timestamp)
      const today = new Date()
      return date.toDateString() === today.toDateString()
    })
  }, [viewHistory, user])

  useEffect(() => {
    if (
      enable &&
      isAdminOrSuperAdmin &&
      !hasAlreadySeenFiveTimes &&
      !hasAlreadySeenToday
    ) {
      setViewHistory((oldValue) => {
        return {
          ...oldValue,
          [user[0].id]: [
            ...(oldValue?.[user[0].id] || []),
            new Date().getTime(),
          ],
        }
      })
      setIsOpen(true)
    }
  }, [
    hasAlreadySeenFiveTimes,
    hasAlreadySeenToday,
    isAdminOrSuperAdmin,
    setViewHistory,
    user,
    enable,
  ])

  const handleChangeIsOpen = useCallback((value: boolean) => {
    setIsOpen(value)
  }, [])

  return {
    isOpen,
    handleChangeIsOpen,
  }
}
