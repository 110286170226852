import { colors, fontFamily, fontWeight } from '@77sol-ui/tokens'
import { BREAKPOINTS } from 'constants/breakpoints'
import styled from 'styled-components'

export const StyledHeader = styled.header`
  width: 100%;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.white[0]};

  .erd_scroll_detection_container {
    display: none !important;
  }

  @media (min-width: ${BREAKPOINTS.xl}) {
    height: 110px;
    padding: 36px 30px;
    container-type: inline-size;
    container-name: header;
  }
`

export const ContentLeft = styled.div`
  display: flex;
  align-items: center;

  gap: 8px;

  > h3 {
    font-family: ${fontFamily.sans[0]};
    font-weight: ${fontWeight.regular};
    font-size: 1.125rem;
    line-height: 28px;
    letter-spacing: 0.5px;
  }

  @container header (min-width: ${BREAKPOINTS.lg}) {
    > h3 {
      font-size: 1.25rem;
      line-height: 38px;
      letter-spacing: 0.75px;
    }
  }
`

export const ContentRight = styled.div`
  display: none;
  gap: 16px;

  > button {
    font-size: 0.875rem;
    > span {
      font-family: ${fontFamily.sans[0]};
    }

    padding: 0.5rem 1rem;
    gap: 8px;
  }

  @media (min-width: ${BREAKPOINTS.xl}) {
    display: flex;
    align-items: center;

    @container header (min-width: ${BREAKPOINTS.lg}) {
      > button {
        gap: 16px;
        padding: 12px 32px;
        height: 46px;
      }
    }
  }
`
