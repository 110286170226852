import { Modal } from '@77sol-ui/molecules'
import { BREAKPOINTS } from 'constants/breakpoints'
import styled from 'styled-components'

export const Wrapper = styled(Modal.Wrapper)`
  height: 100%;
  max-height: 90% !important;

  @media (min-width: ${BREAKPOINTS.sm}) {
    max-width: 50% !important;
  }

  @media (min-width: ${BREAKPOINTS.xl}) {
    max-width: 45% !important;
  }

  @media (min-width: ${BREAKPOINTS.xxl}) {
    max-width: 40% !important;
  }
`
