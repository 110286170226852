import { useQueries, type UseQueryOptions } from '@tanstack/react-query'
import { proposalQuotationRoiCalculate } from '../services/api'
import { PROPOSAL_QUOTATION_ROI_CALCULATE } from '../constants/endpointKeys'

import {
  type IProposalQuotationRoiCalculateParamsAll,
  type IProposalQuotationRoiCalculateResponse,
} from '../services/types'

type returnQueryType = Array<{
  data: IProposalQuotationRoiCalculateResponse[] | undefined
  error: unknown
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
}>

export function useProposalQuotationRoiCalculateAll({
  quotationIds,
  ...queryOptions
}: IProposalQuotationRoiCalculateParamsAll & UseQueryOptions) {
  return useQueries<IProposalQuotationRoiCalculateResponse[]>({
    queries: quotationIds.map((quotationId) => ({
      queryKey: [`${PROPOSAL_QUOTATION_ROI_CALCULATE}`, quotationId],
      queryFn: async () => await proposalQuotationRoiCalculate({ quotationId }),
      ...queryOptions,
    })),
  }) as returnQueryType
}
