import { useQuery, type UseQueryOptions } from '@tanstack/react-query'
import { proposalQuotationPaymentMethods } from '../services/api'
import { PROPOSAL_QUOTATION_PAYMENT_METHODS } from '../constants/endpointKeys'

import { type IProposalQuotationPaymentMethodsParams } from '../services/types'

export function useProposalQuotationPaymentMethods({
  proposalId,
  ...queryOptions
}: IProposalQuotationPaymentMethodsParams & UseQueryOptions) {
  return useQuery({
    queryKey: [`${PROPOSAL_QUOTATION_PAYMENT_METHODS}`, proposalId],
    queryFn: async () => await proposalQuotationPaymentMethods({ proposalId }),
    ...queryOptions,
  })
}
