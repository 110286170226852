import { type Features } from 'acl/config/features.types'
import { permissions } from 'acl/config/permissions'
import { type FeaturesRoutes } from 'acl/config/routes.types'
import { UserRole, UserType } from 'acl/config/types'
import { type IUserLoggedDTO } from 'dto/UserLoggedDTO'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

export function useAcl() {
  const userLogged = useSelector(
    (state: {
      ReducerAuth: {
        userLogged: {
          regra: UserRole
          user_type: UserType
          0: IUserLoggedDTO
        }
      }
    }) => state.ReducerAuth.userLogged,
  )

  const userRole: UserRole = userLogged?.regra || UserRole.ADMIN
  const userType: UserType = userLogged?.user_type || UserType.INTEGRATOR

  const checkIfUserHasPermission = useCallback(
    (featureThatWantAccess: Features) => {
      const userPermissions = permissions[userRole]

      return !userPermissions?.featuresCannotAccess.includes(
        featureThatWantAccess,
      )
    },
    [permissions, userRole],
  )

  const checkIfUserCanAccessRoute = useCallback(
    (routeThatWantAccess?: string | string[]) => {
      if (!routeThatWantAccess) return true
      const userPermissions = permissions[userRole]

      if (typeof routeThatWantAccess === 'string') {
        return !userPermissions?.routesCannotAccess.includes(
          routeThatWantAccess as FeaturesRoutes,
        )
      }

      return !userPermissions?.routesCannotAccess.some((route) =>
        routeThatWantAccess.some((i) => i.startsWith(route)),
      )
    },
    [permissions, userRole],
  )

  return {
    checkIfUserHasPermission,
    checkIfUserCanAccessRoute,
    userType,
    userRole,
    user: userLogged,
  }
}
