import { Alert } from '@77sol-ui/atoms'
import * as S from './styles'

export const QuotationAlert = () => {
  return (
    <S.StyledAlert>
      <Alert.Root variant="warning">
        <Alert.Icon variant="warning" />
        <Alert.Description
          description="Ao selecionar duas ou mais cotações, os arquivos PDF serão gerados
          separadamente."
        />
      </Alert.Root>
    </S.StyledAlert>
  )
}
