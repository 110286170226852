export const financingTracker = {
  eventName: 'Financiamento',
  actions: {
    financingCreated: 'Financiamento: Financiamento criado',
    financingCanceled: 'Financiamento: Simulação de financiamento cancelada',
    financingListStep:
      'Financiamento: Saiu do formulário e voltou para a listagem de cotações',
    projectFinancingSelected:
      'Financiamento: Avançou na simulação de financiamento por cotação',
    handeSimulateFinancing: 'Financiamento: Clicou em financiar',
    handleAttachToOrderModal:
      'Financiamento: Abriu o modal de atrelar financimento a um pedido',
    handleBindToOrder:
      'Financiamento: Clicou no botão de atrelar financiamento ao pedido',
    openedCancelFinancingModal:
      'Financiamento: Abriu o modal para cancelar financimento',
    confirmedCancellationFinancing:
      'Financiamento: Confirmou o cancelamento do financiamento',
    gaveUpCancellationFinancing:
      'Financiamento: Desistiu do cancelamento do financiamento',
    expandFinancing: 'Financiamento: Clicou em expandir financiamento',
    copyFormalizationLink: 'Clicou em copiar o link de formalização',
    sendFormalizationLinkToWhatsapp:
      'Clicou em enviar o link de formalização pelo Whatsapp',
    accessFinancingSimulation:
      'Financiamento: Clicou em acessar a simulação do financiamento',
    financingsFilter: 'Financiamento: Clicou no botão de aplicar filtros',
    clearFinancingsFilter: 'Financiamento: Clicou no botão de limpar filtros',
    openInsuranceAlert: 'Financiamento: Abriu o alerta de seguro',
    closeInsuranceAlert: 'Financiamento: Fechou o alerta de seguro',
    enableInsurance: 'Financiamento: Habilitou o seguro',
    disableInsurance: 'Financiamento: Desabilitou o seguro',
    selectBank: 'Financiamento: Selecionar banco',
    talkToManager: 'Financiamento: Falar com o gerente',
    shareForm: 'Financiamento: Compartilhar formulário',
    shareType: 'Financiamento: Alternar tipo de compartilhamento',
    viewProject: 'Financiamento: Visualizar projeto',
    viewOrder: 'Financiamento: Visualizar pedido',
    backContent: 'Financiamento: Voltar conteúdo',
    closeContent: 'Financiamento: Fechar conteúdo',
    selectOrder: 'Financiamento: Selecionar pedido',
  },
}
