import { usePDFDataStore } from '../store/usePDFDataStore'

export const usePdfStore = () => {
  const template = usePDFDataStore((state) => state.template)

  const setTemplate = usePDFDataStore((state) => state.setTemplate)

  const firstPDFPreviewLoaded = usePDFDataStore(
    (state) => state.firstPDFPreviewLoaded,
  )

  const selectedQuotations = usePDFDataStore(
    (state) => state.selectedQuotations,
  )

  const quotationsPDFBuilt = usePDFDataStore(
    (state) => state.quotationsPDFBuilt,
  )

  const setFirstPdfLoaded = usePDFDataStore(
    (state) => state.setfirstPDFPreviewLoaded,
  )

  const setSelectedQuotations = usePDFDataStore(
    (state) => state.setSelectedQuotations,
  )

  const setSelectedQuotationsBuilt = usePDFDataStore(
    (state) => state.setQuotationsPDFBuilt,
  )

  const setAllPDFsLoaded = usePDFDataStore((state) => state.setAllPDFsLoaded)
  const allPDFsLoaded = usePDFDataStore((state) => state.allPDFsLoaded)

  return {
    template,
    allPDFsLoaded,
    setTemplate,
    setSelectedQuotations,
    setSelectedQuotationsBuilt,
    setAllPDFsLoaded,
    selectedQuotations,
    quotationsPDFBuilt,
    firstPDFPreviewLoaded,
    setFirstPdfLoaded,
  }
}
