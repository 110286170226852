import { create } from 'zustand'
import { type SlideContentValues } from '../enums/simulationSlide'

interface IFinancingSimulationsSlideStore {
  financingId: number | null
  simulationId: number | null
  orderId: number | null
  slideContent: SlideContentValues | null
  setFinancingId: (financingId: number | null) => void
  setSimulationId: (simulationId: number | null) => void
  setOrderId: (orderId: number | null) => void
  setSlideContent: (slideContent: SlideContentValues | null) => void
  reset: () => void
}

export const useFinancingSimulationsSlideStore =
  create<IFinancingSimulationsSlideStore>((set) => ({
    financingId: null,
    simulationId: null,
    orderId: null,
    slideContent: null,
    isOpenSimulationDetailsSlide: false,
    isOpenShareDocumentsSlide: false,
    setFinancingId: (financingId: number | null) => {
      set({ financingId })
    },
    setSimulationId: (simulationId: number | null) => {
      set({ simulationId })
    },
    setOrderId: (orderId: number | null) => {
      set({ orderId })
    },
    setSlideContent: (slideContent: SlideContentValues | null) => {
      set({ slideContent })
    },
    reset: () => {
      set({
        orderId: null,
        financingId: null,
        simulationId: null,
        slideContent: null,
      })
    },
  }))
