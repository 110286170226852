import { fontFamily } from '@77sol-ui/tokens'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  filledButton: {
    background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
    borderRadius: '8px',
    color: '#FFF',
    textTransform: 'none',
    padding: '8px 12px',
    '&:hover': {
      background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
      borderRadius: '8px',
    },
  },
  backdrop: {
    zIndex: 1000,
    color: '#fff',
    flexDirection: 'column',
  },
  outlinedButton: {
    backgroundColor: 'none',
    border: '1px solid #1335c6',
    color: '#1335c6',
    textTransform: 'none',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  topTitle: ({ isFromProposalList }) => ({
    width: isFromProposalList ? 'auto' : '100%',
    '@media (min-width:350px)': {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      marginBottom: '16px',
    },
    '@media (min-width:1250px)': {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginBottom: '0px',
    },
  }),
  modalSm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& > button': {
      height: '42px',
    },
  },

  title: ({ isFromProposalList }) => ({
    padding: isFromProposalList ? '4.8px 0px' : '7.8px 0px',
    fontFamily: fontFamily.sans[0],
    fontSize: '1.063rem',
    '@container header (max-width: 400px)': {
      fontSize: '0.875rem',
    },
  }),
})
