import styled from 'styled-components'

export const Container = styled.div`
  footer {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: 1280px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .actions {
      button {
        @media (max-width: 1280px) {
          width: 100%;
        }
      }
    }
  }
`
