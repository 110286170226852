import { BREAKPOINTS_VALUES } from 'constants/breakpoints'
import { Grid3X3, FileText, Send } from 'lucide-react'

const isMobile = window.innerWidth <= BREAKPOINTS_VALUES.sm

export const HEADER = [
  {
    icon: FileText,
    title: 'Selecione um modelo de proposta PDF',
    description: (onClick: () => void) => (
      <p>
        Escolha 1 dos modelos para prosseguir. Você poderá personalizá-los
        quando quiser na{' '}
        <a
          target="_blank"
          href="/configuracoes/propostas"
          className="link"
          onClick={onClick}
        >
          tela de configuração
        </a>
        .
      </p>
    ),
    cancelText: 'Cancelar',
    okText: 'Selecionar'.concat(!isMobile ? ' modelo' : ''),
  },
  {
    icon: Grid3X3,
    title: 'Escolha quais cotações seguir em sua proposta',
    description: 'Escolha 1 ou mais cotações para incluir, abaixo:',
    cancelText: 'Voltar',
    okText: 'Confirmar',
  },
  {
    icon: FileText,
    title: 'Pré-visualização de proposta',
    description: 'Abaixo você poderá visualizar o modelo da proposta',
    cancelText: 'Voltar',
    okText: 'Confirmar',
  },
  {
    icon: Send,
    title: 'Envie sua proposta',
    description: 'Exporte sua proposta para enviar ao seu cliente.',
    cancelText: 'Voltar',
    okText: 'Exportar PDF',
  },
]

export const MODAL_SIZE = [
  { width: '600px', height: '90%' },
  { width: '732px', height: '80%' },
  { width: '726px', height: '90%' },
  { width: '726px', height: '90%' },
]

export const STEP_TRACKERS: Record<number, string> = {
  0: 'Selecionar modelo PDF',
  1: 'Escolher cotação',
  2: 'Visualizar proposta',
  3: 'Fazer download da proposta',
}
