export const proposalTracker = {
  actions: {
    systemPower: 'Escolheu Dimensionamento (Básico) por Potência do Sistema',
    averageConsumptionValue:
      'Escolheu Dimensionamento (Básico) por Valor de consumo médio',
    monthlyConsumption:
      'Escolheu Dimensionamento (Completo) por Consumo mensal',
    systemPowerPRO:
      'Escolheu Dimensionamento (Completo) por Potência do Sistema',
    consumptionAveragePRO:
      'Escolheu Dimensionamento (Completo) por Média de consumo',
    monthlyConsumptionPRO:
      'Escolheu Dimensionamento (Completo) por consumo mês a mês',
    averageConsumptionValuePRO:
      'Escolheu Dimensionamento (Completo) por Valor de consumo médio',
    simulateProject: 'Criação de proposta: Botão Simular projeto',
    calculateSizing: 'Criação de proposta: Botão Calcular o dimensionamento',
    createEquipmentQuote:
      'Criação de proposta: Botão Fazer cotação de equipamentos',
    AddLossRelatedToAzimuthalAngle:
      'Criação de proposta: Botão Adicionar perda relacionada ao ângulo azimutal',
    AddLossRelatedToAzimuthalAngleNotProUser:
      'Criação de proposta: Botão Adicionar perda relacionada ao ângulo azimutal (Clicou mas não é PRO)',
    reserveEquipment: 'Criação de proposta: Botão Reservar',
    openProposalSendModal: 'Proposta: Abriu o modal de envio de proposta',
    sendProposalToCustomer: 'Proposta: Enviou a proposta para o cliente',
    accessCustomizeProposal: 'Acessar Personalização de proposta',
    editProposal: 'Proposta: Editar proposta',
    editProposalTab: 'Proposta: Tab editar proposta',
    openDigitalProposal: 'Proposta: Acessar proposta digital',
  },
}
