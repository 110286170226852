import { Button } from '@77sol-ui/atoms'
import { Modal } from '@77sol-ui/molecules'
import { ReactPDFViewer } from 'modules/PDF/PDFTemplateRender/components/ReactPDFViewer'
import { useReadUserSettings } from 'domains/pdf-express/read-user-settings/hooks/useReadUserSettings'
import { useUserId } from 'hooks/useUserId'
import { FileText } from 'lucide-react'
import { Wrapper } from './styles'
import { useSelector } from 'react-redux'
import { type TemplateId } from 'containers/PDFTemplatesSelector/types'
import { CircularProgress } from 'components'
import { ErrorState } from 'components/ErrorState'
import { useState } from 'react'
import { type IReduxStore } from 'types/redux'

export interface IPreviewModalProps {
  templateId: TemplateId
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  onSelect: (templateId: TemplateId) => void
}

export function PreviewModal({
  templateId,
  isOpen,
  onOpenChange,
  onSelect,
}: IPreviewModalProps) {
  const [isCreatingPDF, setIsCreatingPDF] = useState(true)

  const userId = useUserId()
  const { data, isError, isSuccess, isLoading, refetch } = useReadUserSettings({
    userId,
  })
  const { userProfile, logo } = useSelector(
    (state: IReduxStore) => state.ReducerProfile,
  )

  const disabled = isLoading || isError || isCreatingPDF

  return (
    <Modal.Root open={isOpen} onOpenChange={onOpenChange}>
      <Modal.Portal>
        <Modal.Overlay />
        <Wrapper>
          <Modal.Close />
          <Modal.Header>
            <Modal.Icon icon={FileText} size={24} />

            <Modal.Title>
              Pré-visualização de proposta - Modelo {templateId}
            </Modal.Title>
            <Modal.Description>
              Abaixo você poderá visualizar o modelo da proposta com dados
              fictícios.
            </Modal.Description>
          </Modal.Header>

          {isLoading && <CircularProgress text="Carregando..." />}

          {isError && !isLoading && (
            <ErrorState
              title="Algo deu errado ao carregar as informações."
              subtitle="Atualize a página e em alguns minutos tente novamente."
              buttonText="Atualizar página"
              onButtonAction={refetch}
            />
          )}

          {data && isSuccess && (
            <ReactPDFViewer
              viewerProps={{
                headerText: `Modelo de Proposta ${templateId}`,
                documentProps: {
                  onLoadSuccess: () => {
                    setIsCreatingPDF(false)
                  },
                },
              }}
              userSettings={{
                ...data,
                default_template_id: templateId,
              }}
              userLogged={{
                ...userProfile,
                company_logo: logo,
              }}
            />
          )}

          <Modal.Footer>
            <Modal.Close asChild>
              <Button fullWidth variant="outline">
                Fechar
              </Button>
            </Modal.Close>
            <Button
              fullWidth
              onClick={() => {
                onSelect(templateId)
              }}
              disabled={disabled}
            >
              Selecionar Modelo
            </Button>
          </Modal.Footer>
        </Wrapper>
      </Modal.Portal>
    </Modal.Root>
  )
}
