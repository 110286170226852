import { useAmplitude } from 'hooks/useAmplitude'
import { useHistory } from 'react-router-dom'
import { ordersTracker } from 'services/tracker/events/orders/trackers'
import { useCheckoutModalStore } from 'store/modals/CheckoutModalStore'
import { usePayCreatedOrder } from 'views/Financing/components/SimulationSlide/hooks/usePayCreatedOrder'
import {
  TRACKER_OPTIONS_DEFAULT,
  TRACKER_OPTIONS_FINANCING,
} from '../constants/TrackerOptions'

interface IUseFooterActionsProps {
  orderId: number
  paymentLink: string | null
  paymentTypeSlug: string
  onClose: () => void
}

const ROUTES = {
  financing: (financingId: number, simulationId: number) =>
    `/financiamentos/${financingId}/simulacao/${simulationId}`,
  orders: '/pedidos',
  orderDetails: (orderId: number) => `/pedidos/${orderId}`,
}

export const useFooterActions = ({
  orderId,
  paymentLink,
  paymentTypeSlug,
  onClose,
}: IUseFooterActionsProps) => {
  const history = useHistory()
  const { logEvent } = useAmplitude()
  const { payOrder } = usePayCreatedOrder(false)
  const { financingId = null, simulationId = null } = useCheckoutModalStore()

  const isFinancingAction = financingId && simulationId

  const eventOptions = isFinancingAction
    ? TRACKER_OPTIONS_FINANCING
    : TRACKER_OPTIONS_DEFAULT

  const descriptionContent = isFinancingAction
    ? 'Dê continuidade ao fluxo de financiamento realizando o pagamento deste pedido!'
    : 'Complete agora o cadastro para garantir a cotação e acelerar seu pedido!'

  const handleContinueText = (paymentTypeSlug: string) => {
    switch (paymentTypeSlug) {
      case 'financing':
        return isFinancingAction ? 'Pagar pedido' : 'Acelerar confirmação'
        break

      default:
        return 'Pagar agora!'
        break
    }
  }

  const continueText = handleContinueText(paymentTypeSlug)

  const exitText = 'Agora não'

  const handleContinue = () => {
    const event = isFinancingAction
      ? ordersTracker.successOrderModal.valueFinancing.orderToValueFinancing
      : ordersTracker.successOrderModal.default.orderToPending

    const route = isFinancingAction
      ? ROUTES.financing(financingId, simulationId)
      : ROUTES.orderDetails(orderId)

    if (paymentLink) {
      const newTab = window.open(paymentLink, '_blank')
      if (newTab) {
        newTab.focus()
      }
    }

    if (isFinancingAction) {
      payOrder(orderId)
    }

    logEvent(event, eventOptions)
    history.push(route)

    onClose()
  }

  const handleExit = () => {
    const event = financingId
      ? ordersTracker.successOrderModal.valueFinancing.orderToOrderList
      : ordersTracker.successOrderModal.default.orderToOrderList

    logEvent(event, eventOptions)
    history.push(ROUTES.orders)
    onClose()
  }

  return {
    handleContinue,
    handleExit,
    continueText,
    descriptionContent,
    exitText,
  }
}
