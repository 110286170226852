import { colors } from '@77sol-ui/tokens'
import styled from 'styled-components'

export const Container = styled.div`
  padding: ${(props) => (props.hideText ? '0px' : '0px 32px')};

  .context {
    margin-bottom: 32px;

    p {
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
  }
`

export const DropzoneContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 250px;

  h3 {
    margin-top: 10px;
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: ${colors.gray[600]};
  }

  span {
    font-family: 'DM Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: ${colors.gray[500]};
  }
`
