import { type IQuotationDTO } from 'dto/QuotationDTO'
import { useCallback, useMemo, useState } from 'react'

export interface IUseNavigateBetweenQuotationsProps {
  quotations: IQuotationDTO[]
  defaultPosition?: number
  onNavigationSuccess?: (type: 'prev' | 'next') => void
}

export function useNavigateBetweenQuotations({
  quotations,
  defaultPosition = 0,
  onNavigationSuccess,
}: IUseNavigateBetweenQuotationsProps) {
  const hasMultipleQuotations = quotations.length > 1

  const [selectedQuotationIndex, setSelectedQuotationIndex] =
    useState<number>(defaultPosition)

  const selectedQuotation = useMemo<IQuotationDTO>(() => {
    return quotations[selectedQuotationIndex]
  }, [selectedQuotationIndex, quotations])

  const hasNextQuotation = selectedQuotationIndex < quotations.length - 1
  const hasPrevQuotation = selectedQuotationIndex > 0

  const handleNextQuotation = useCallback(() => {
    if (!hasNextQuotation) return
    setSelectedQuotationIndex((oldValue) => oldValue + 1)

    onNavigationSuccess?.('next')
  }, [hasNextQuotation, onNavigationSuccess])

  const handlePrevQuotation = useCallback(() => {
    if (!hasPrevQuotation) return
    setSelectedQuotationIndex((oldValue) => oldValue - 1)
    onNavigationSuccess?.('prev')
  }, [hasPrevQuotation, onNavigationSuccess])

  return {
    hasMultipleQuotations,
    selectedQuotation,
    selectedQuotationIndex,
    hasNextQuotation,
    hasPrevQuotation,
    handleNextQuotation,
    handlePrevQuotation,
  }
}
