/* eslint-disable @typescript-eslint/promise-function-async */
import { lazy } from 'react'
import { Redirect } from 'react-router-dom'
import { type INestedRoute } from 'types/routes'
import { errorRoutes } from 'routes/errorRoutes'
import { HomeLayout } from '_Distributor/layouts/Home'
import { Home } from '../views/Home'

const distributorRoutes: INestedRoute[] = [
  {
    path: '/dashboard',
    exact: true,
    component: () => <Redirect to="/" />,
  },
  { ...errorRoutes },
  {
    route: '*',
    component: HomeLayout,
    routes: [
      {
        path: '/',
        exact: true,
        component: Home,
      },
      {
        path: [
          '/financiamentos',
          '/financiamentos/:financingId?',
          '/financiamentos/:financingId?/simulacao/:simulationId?',
        ],
        exact: true,
        component: lazy(() => import('../views/Financing')),
      },
      {
        path: ['/time', '/time/:tab'],
        exact: true,
        component: lazy(() => import('../views/MyTeam')),
      },
      {
        path: '/configuracoes',
        exact: true,
        component: lazy(() => import('../views/Settings')),
      },
      {
        path: '/configuracoes/:tab',
        exact: true,
        component: lazy(() => import('../views/Settings')),
      },
      {
        component: () => <Redirect to="/erro/404" />,
      },
    ],
  },
]

export default distributorRoutes
