import { FINANCING_REQUEST_STATUS_ENUM } from 'enums/financingRequest/FinancingRequestStatusEnum'

export const useFinancingStatusRequestBadge = (
  status: FINANCING_REQUEST_STATUS_ENUM,
) => {
  const {
    SOLICITADO,
    CANCELADO,
    NEGADO,
    PRE_APROVADO,
    COM_PENDENCIAS,
    CONCLUIDO,
    CREDITO_APROVADO,
    EM_ANALISE,
  } = FINANCING_REQUEST_STATUS_ENUM

  const statusVariant = {
    [SOLICITADO]: {
      color: 'yellow',
      text: 'Solicitado',
    },
    [PRE_APROVADO]: {
      color: 'blue',
      text: 'Pré aprovado',
    },
    [EM_ANALISE]: {
      color: 'orange',
      text: 'Em análise',
    },
    [COM_PENDENCIAS]: {
      color: 'purple',
      text: 'Com pendências',
    },
    [CREDITO_APROVADO]: {
      color: 'green',
      text: 'Crédito aprovado',
    },
    [CONCLUIDO]: {
      color: 'dark-blue',
      text: 'Concluído',
    },
    [NEGADO]: {
      color: 'red',
      text: 'Negado',
    },
    [CANCELADO]: {
      color: 'gray',
      text: 'Cancelado',
    },
  }

  return (
    statusVariant?.[status] || {
      color: 'blue',
      text: '-',
    }
  )
}
