import { NOTIFICATION_URLS } from 'containers/Notifications/constants/redirectNotificationURLs'
import { type INotificationDTO } from 'dto/NotificationDTO'
import { type INotificationFinancingParams } from 'dto/NotificationDTO/financing'

export const redirectFinancingNotification = (
  notification: INotificationDTO,
) => {
  const { financingId } = notification.params as INotificationFinancingParams

  const path = NOTIFICATION_URLS[notification.type].concat(`/${financingId}`)

  return path
}
