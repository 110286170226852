import { type IBannerImage } from '..'

import { BannersTrackers } from 'services/tracker/events/Banners'

export const CAROUSEL_DEFAULT_CONFIGS = {
  key: 'autoplay-slide',
  autoplay: true,
  autoplaySpeed: 10000,
  infinite: true,
  speed: 500,
  centerMode: true,
}

export const CARROUSEL_CONTENT: IBannerImage[] = [
  {
    image: `https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/home/banner_3.png`,
    mobileImage: `https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/home/banner_3_mobile.png`,
    largeImage: `https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/home/banner_3_large.png`,
    url: '?quotationModal',
    event: BannersTrackers.actions.clickOnBanner,
    bannerName: 'Comece bem o mês: Faça sua primeira compra com desconto de R$200',
    target: '_self',
  },
  {
    image: `https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/home/banner_2.png`,
    mobileImage: `https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/home/banner_2_mobile.png`,
    largeImage: `https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/home/banner_2_large.png`,
    event: BannersTrackers.actions.clickOnBanner,
    url: '?financingModal',
    bannerName: 'Financiamento: Faça seu financiamento e ganhe prêmios',
    target: '_self',
  },
  {
    image: `https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/home/banner_1.png`,
    mobileImage: `https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/home/banner_1_mobile.png`,
    largeImage: `https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/home/banner_1_large.png`,
    url: 'https://runrun.it/share/form/cPs_CLIwHgEdKMp8',
    event: BannersTrackers.actions.clickOnBanner,
    bannerName: 'Homologação: Projetos fotovoltaicos com máxima eficiência e automações de processos',
    target: '_blank',
  },
]
