import { colors } from '@77sol-ui/tokens'
import styled from 'styled-components'

export const Container = styled.div`
  h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .summary {
    margin-top: 16px;
    border-top: 1px solid ${colors.gray[150]};

    .summaryValue {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;

      span {
        display: flex;
        align-items: center;
        font-size: 14px;
      }
    }

    .laborValue {
      width: 65%;
      > div {
        input {
          width: 164px;
          text-align: right;
          margin-left: auto;
        }
        p {
          direction: rtl;
        }
      }
    }
  }
`

export const IconContainer = styled.div`
  cursor: pointer;
  margin-left: 6px;
`
