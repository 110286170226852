export enum SLIDE_CONTENT_ENUM {
  SIMULATION_LIST = 'lista_simulacao',
  SELECT_SIMULATION = 'selecionar_simulacao',
  SIMULATION_HISTORY = 'historico_simulacao',
  SHARE_SIMULATION = 'compartilhar_simulacao',
  APPROVED_CREDIT = 'credito_aprovado',
  PAY_ORDER = 'pagar_pedido',
  SELECT_ORDER = 'selecionar_pedido',
  ORDER_SELECTED = 'pedido_selecionado',
}

export type SlideContentValues = `${SLIDE_CONTENT_ENUM}`
