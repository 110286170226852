export function isIOS(): boolean {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return /iphone|ipad|ipod/.test(userAgent)
}

export function isIPhone(): boolean {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return userAgent.includes('iphone')
}

export function isIPad(): boolean {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return (
    userAgent.includes('ipad') ||
    (userAgent.includes('macintosh') && navigator.maxTouchPoints > 1)
  )
}

export function isSafari(): boolean {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return /^((?!chrome|android).)*safari/i.test(userAgent)
}

export function isMobileDevice(): boolean {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  )
}
