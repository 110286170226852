import { Modal } from '@77sol-ui/molecules'
import { colors, fontFamily, fontSize } from '@77sol-ui/tokens'
import { BREAKPOINTS } from 'constants/breakpoints'
import styled from 'styled-components'

export const Paragraph = styled.p`
  font-family: ${fontFamily.sans[0]};

  font-size: ${fontSize.sm};

  margin-bottom: 24px;
  @media (min-width: ${BREAKPOINTS.sm}) {
    font-size: ${fontSize.base};
  }
`

export const ModalWrapper = styled(Modal.Wrapper)`
  width: 100% !important;
  max-height: min(90dvh, 690px) !important;

  &[data-loading='true'] {
    height: 100% !important;
  }
`

export const ModalTitle = styled(Modal.Title)`
  width: 90%;
  font-size: ${fontSize.sm};

  @media (min-width: ${BREAKPOINTS.xs}) {
    font-size: ${fontSize.base};
  }

  @media (min-width: ${BREAKPOINTS.sm}) {
    font-size: ${fontSize.lg};
  }
`

export const CarouselContent = styled.div`
  height: 100%;
  opacity: 1;

  @keyframes skeleton {
    0% {
      background-color: ${colors.gray[100]};
    }

    50% {
      background-color: ${colors.gray[150]};
    }

    100% {
      background-color: ${colors.gray[100]};
    }
  }

  &[data-loading='true'] {
    animation: 3s ease-in infinite skeleton;

    .slick-slider {
      opacity: 0;
    }
  }
`
