import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { List, Tooltip, Divider } from '@material-ui/core'
import 'react-dropzone-uploader/dist/styles.css'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dropzone from 'react-dropzone-uploader'
import {
  EditIcon,
  SettingsIcon,
  LogOutCircleIcon,
  UploadIcon,
  ProfileIcon,
  ArrowChevronBackIcon,
} from '@77sol/icons/dist'
import { Typography, Spacer, Paper } from '@77sol/core'
import { urlLogin } from 'urls'
import palette from 'app_palette'
import API from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { setLogo, setAvatar } from 'store/actions/profile'
import { clearStorage } from 'utils/clearStorage'
import { ProfilePhotoModal } from 'containers/ProfilePhotoModal'

const useStyles = makeStyles(() => ({
  root: {},
}))

function DropzonePreview() {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress
        disableShrink
        style={{ color: palette.primary[300] }}
        size={30}
        thickness={4}
      />
    </div>
  )
}

function MenuList({
  directRestrict,
  restrict,
  className,
  nivelLogin,
  hideClick,
  rest,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { logo, avatar } = useSelector((state) => state.ReducerProfile)
  const [cropImageIsOpen, setCropImageIsOpen] = useState(false)
  const [imageToCropURL, setImageToCropURL] = useState('')
  const [openModal, setOpenModal] = React.useState(false)
  const [openModalLogo, setOpenModalLogo] = React.useState(false)

  const [showMessage, setShowMessage] = useState(false)

  const handleConfigs = () => {
    window.location.href = '/configuracoes'
    return window.location.href
  }

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleOpenModalLogo = () => {
    setOpenModalLogo(true)
  }

  const [loadingUploadImage, setLoadingUploadImage] = useState(false)

  const handleLogout = () => {
    clearStorage()
    window.location.href = urlLogin
    return window.location.href
  }

  const updateLogo = (image) => {
    setCropImageIsOpen(false)
    setLoadingUploadImage(true)
    const formData = new FormData()
    const fileName = `newProfilePhoto_${new Date().toISOString()}.png`
    formData.append('company_logo', image, fileName)

    API.post('/user/add/company-logo', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res) => {
        setLoadingUploadImage(false)
        dispatch(setLogo(res.data.company_logo))
        setShowMessage(true)
      })
      .catch(() => {
        setLoadingUploadImage(false)
      })
  }

  const updateAvatar = (image) => {
    setLoadingUploadImage(true)
    const formData = new FormData()
    formData.append('avatar', image, image.fileName)

    API.post('/user/add/avatar', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res) => {
        setLoadingUploadImage(false)
        setShowMessage(true)
        dispatch(setAvatar(res.data.avatar))
      })
      .catch(() => {
        setLoadingUploadImage(false)
      })
  }

  const handleChangeStatus = ({ file, remove }, status) => {
    if (status === 'preparing') {
      // setImageLoading(true)
      remove()
      dispatch(setAvatar(file))
      updateAvatar(file)
    }
  }

  const handleChangeStatusLogo = ({ file, remove }, status) => {
    if (status === 'preparing') {
      remove()
      dispatch(setLogo(file))
      const url = URL.createObjectURL(file)
      setImageToCropURL(url)
      setCropImageIsOpen(true)
    }
  }

  return (
    <>
      <List {...rest} className={clsx(classes.root, className)} disablePadding>
        {restrict === 'company_logo' && directRestrict ? (
          <>
            <Typography
              size="medium"
              style={{
                color: '#063EF9',
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold',
              }}
            >
              Logo da empresa
            </Typography>
            <Spacer size="12" direction="horizontal" />
            <Divider style={{ border: '1px solid #D9DBE9' }} />
            <Spacer size="16" direction="horizontal" />
            <Typography size="small">Upload da imagem</Typography>
            <Typography size="xsmall" style={{ color: palette.grayscale[500] }}>
              São suportados apenas arquivos PNG, JPEG e GIF de até 10MB
            </Typography>
            <Spacer size="24" direction="horizontal" />
            <Dropzone
              maxFiles={1}
              onChangeStatus={handleChangeStatusLogo}
              multiple={false}
              PreviewComponent={DropzonePreview}
              inputContent={
                loadingUploadImage ? (
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress
                      disableShrink
                      style={{ color: palette.primary[300] }}
                      size={30}
                      thickness={4}
                    />
                  </div>
                ) : logo ? (
                  <div
                    style={{
                      backgroundImage: `url(${logo})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    <Paper
                      style={{
                        position: 'absolute',
                        bottom: '8px',
                        right: '8px',
                        display: 'flex',
                        width: 'auto',
                        height: 'auto',
                        padding: '8px',
                      }}
                    >
                      <Tooltip title="Alterar">
                        <EditIcon width="24" color={palette.grayscale[800]} />
                      </Tooltip>
                    </Paper>
                  </div>
                ) : (
                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      maxWidth: '450px',
                    }}
                  >
                    <UploadIcon
                      width="32"
                      height="32"
                      color={palette.grayscale[600]}
                    />
                    <Spacer size="10" direction="horizontal" />
                    <Typography
                      type="text_x_small"
                      color="grayscale"
                      colorWeight="600"
                    >
                      Clique aqui ou arraste o arquivo para fazer o upload do
                      logo
                    </Typography>
                  </span>
                )
              }
              accept="image/*"
              styles={{
                dropzone: {
                  padding: '100px 30px',
                  border: logo
                    ? 'none'
                    : `1px dashed ${palette.grayscale[400]}`,
                  flexGrow: 1,
                  alignSelf: 'stretch',
                  borderRadius: '8px',
                  flexDirection: 'column',
                  overflow: 'hidden',
                  color: '#1335c6',
                  width: '100%',
                  height: logo ? '100%' : 'auto',
                },
                preview: {
                  border: 'none',
                },
                input: {
                  display: 'none',
                },
                inputLabel: {
                  color: '#1335c6',
                  fontSize: '16px',
                  textAlign: 'center',
                  padding: logo ? '0px' : '16px',
                },
              }}
            />
            {showMessage && (
              <Typography
                type="link"
                color="orange"
                colorWeight="300"
                style={{
                  marginTop: '16px',
                  fontSize: '13px',
                  lineHeight: '16px',
                }}
              >
                Pode ser necessário atualizar a pagina para a mudança fazer
                efeito!
              </Typography>
            )}
          </>
        ) : (
          <>
            {openModal && (
              <>
                <Typography
                  size="medium"
                  style={{
                    color: '#063EF9',
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  <ArrowChevronBackIcon
                    width={24}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setOpenModal(false)
                    }}
                  />
                  &nbsp; Foto de perfil
                </Typography>
                <Spacer size="12" direction="horizontal" />
                <Divider style={{ border: '1px solid #D9DBE9' }} />
                <Spacer size="16" direction="horizontal" />
                <Typography size="small">Upload da imagem</Typography>
                <Typography
                  size="xsmall"
                  style={{ color: palette.grayscale[500] }}
                >
                  São suportados apenas arquivos PNG, JPEG e GIF de até 10MB
                </Typography>
                <Spacer size="24" direction="horizontal" />
                <Dropzone
                  maxFiles={1}
                  // getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  multiple={false}
                  PreviewComponent={DropzonePreview}
                  inputContent={
                    loadingUploadImage ? (
                      <div
                        style={{
                          height: '100%',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <CircularProgress
                          disableShrink
                          style={{ color: palette.primary[300] }}
                          size={30}
                          thickness={4}
                        />
                      </div>
                    ) : avatar ? (
                      <div
                        style={{
                          backgroundImage: `url(${avatar})`,
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          width: '100%',
                          height: '100%',
                          position: 'relative',
                        }}
                      >
                        <Paper
                          style={{
                            position: 'absolute',
                            bottom: '8px',
                            right: '8px',
                            display: 'flex',
                            width: 'auto',
                            height: 'auto',
                            padding: '8px',
                          }}
                        >
                          <Tooltip title="Alterar">
                            <EditIcon
                              width="24"
                              color={palette.grayscale[800]}
                            />
                          </Tooltip>
                        </Paper>
                      </div>
                    ) : (
                      <span
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          maxWidth: '450px',
                        }}
                      >
                        <UploadIcon
                          width="32"
                          height="32"
                          color={palette.grayscale[600]}
                        />
                        <Spacer size="10" direction="horizontal" />
                        <Typography
                          type="text_x_small"
                          color="grayscale"
                          colorWeight="600"
                        >
                          Clique aqui ou arraste o arquivo para fazer o upload
                          da foto de perfil
                        </Typography>
                      </span>
                    )
                  }
                  accept="image/*"
                  styles={{
                    dropzone: {
                      padding: '100px 30px',
                      border: avatar
                        ? 'none'
                        : `1px dashed ${palette.grayscale[400]}`,
                      flexGrow: 1,
                      alignSelf: 'stretch',
                      borderRadius: '8px',
                      flexDirection: 'column',
                      overflow: 'hidden',
                      color: '#1335c6',
                      width: '100%',
                      height: avatar ? '100%' : 'auto',
                    },
                    preview: {
                      border: 'none',
                    },
                    input: {
                      display: 'none',
                    },
                    inputLabel: {
                      color: '#1335c6',
                      fontSize: '16px',
                      textAlign: 'center',
                      padding: avatar ? '0px' : '16px',
                    },
                  }}
                />
                {showMessage && (
                  <Typography
                    type="link"
                    color="orange"
                    colorWeight="300"
                    style={{
                      marginTop: '16px',
                      fontSize: '13px',
                      lineHeight: '16px',
                    }}
                  >
                    Pode ser necessário atualizar a pagina para a mudança fazer
                    efeito!
                  </Typography>
                )}
              </>
            )}

            {openModalLogo && (
              <>
                <Typography
                  size="medium"
                  style={{
                    color: '#063EF9',
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  <ArrowChevronBackIcon
                    width={24}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setOpenModalLogo(false)
                    }}
                  />
                  &nbsp; Logo da empresa
                </Typography>
                <Spacer size="12" direction="horizontal" />
                <Divider style={{ border: '1px solid #D9DBE9' }} />
                <Spacer size="16" direction="horizontal" />
                <Typography size="small">Upload da imagem</Typography>
                <Typography
                  size="xsmall"
                  style={{ color: palette.grayscale[500] }}
                >
                  São suportados apenas arquivos PNG, JPEG e GIF de até 10MB
                </Typography>
                <Spacer size="24" direction="horizontal" />
                <Dropzone
                  maxFiles={1}
                  onChangeStatus={handleChangeStatusLogo}
                  multiple={false}
                  PreviewComponent={DropzonePreview}
                  inputContent={
                    loadingUploadImage ? (
                      <div
                        style={{
                          height: '100%',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <CircularProgress
                          disableShrink
                          style={{ color: palette.primary[300] }}
                          size={30}
                          thickness={4}
                        />
                      </div>
                    ) : logo ? (
                      <div
                        style={{
                          backgroundImage: `url(${logo})`,
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          width: '100%',
                          height: '100%',
                          position: 'relative',
                        }}
                      >
                        <Paper
                          style={{
                            position: 'absolute',
                            bottom: '8px',
                            right: '8px',
                            display: 'flex',
                            width: 'auto',
                            height: 'auto',
                            padding: '8px',
                          }}
                        >
                          <Tooltip title="Alterar">
                            <EditIcon
                              width="24"
                              color={palette.grayscale[800]}
                            />
                          </Tooltip>
                        </Paper>
                      </div>
                    ) : (
                      <span
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          maxWidth: '450px',
                        }}
                      >
                        <UploadIcon
                          width="32"
                          height="32"
                          color={palette.grayscale[600]}
                        />
                        <Spacer size="10" direction="horizontal" />
                        <Typography
                          type="text_x_small"
                          color="grayscale"
                          colorWeight="600"
                        >
                          Clique aqui ou arraste o arquivo para fazer o upload
                          do logo
                        </Typography>
                      </span>
                    )
                  }
                  accept="image/*"
                  styles={{
                    dropzone: {
                      padding: '100px 30px',
                      border: logo
                        ? 'none'
                        : `1px dashed ${palette.grayscale[400]}`,
                      flexGrow: 1,
                      alignSelf: 'stretch',
                      borderRadius: '8px',
                      flexDirection: 'column',
                      overflow: 'hidden',
                      color: '#1335c6',
                      width: '100%',
                      height: logo ? '100%' : 'auto',
                    },
                    preview: {
                      border: 'none',
                    },
                    input: {
                      display: 'none',
                    },
                    inputLabel: {
                      color: '#1335c6',
                      fontSize: '16px',
                      textAlign: 'center',
                      padding: logo ? '0px' : '16px',
                    },
                  }}
                />
                {showMessage && (
                  <Typography
                    type="link"
                    color="orange"
                    colorWeight="300"
                    style={{
                      marginTop: '16px',
                      fontSize: '13px',
                      lineHeight: '16px',
                    }}
                  >
                    Pode ser necessário atualizar a pagina para a mudança fazer
                    efeito!
                  </Typography>
                )}
              </>
            )}

            {!openModal &&
              !openModalLogo &&
              (restrict === 'company_logo' && !directRestrict ? (
                <div>
                  <Typography
                    size="small"
                    onClick={() => {
                      handleOpenModalLogo()
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <EditIcon width={20} />
                    &nbsp; Logo da empresa
                  </Typography>
                  <Spacer size="16" direction="horizontal" />
                </div>
              ) : (
                <>
                  <Typography
                    size="medium"
                    style={{
                      color: '#063EF9',
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    <ProfileIcon width={24} />
                    &nbsp; Perfil
                  </Typography>
                  <Spacer size="12" direction="horizontal" />
                  <Divider style={{ border: '1px solid #D9DBE9' }} />

                  <div>
                    <Spacer size="16" direction="horizontal" />
                    <Typography
                      size="small"
                      onClick={() => {
                        handleOpenModal()
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <EditIcon width={20} />
                      &nbsp; Foto de perfil
                    </Typography>
                    <Spacer size="16" direction="horizontal" />
                    {nivelLogin < 3 && (
                      <>
                        {!hideClick && (
                          <>
                            <Typography
                              size="small"
                              onClick={() => {
                                handleOpenModalLogo()
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              <EditIcon width={20} />
                              &nbsp; Logo da empresa
                            </Typography>
                            <Spacer size="16" direction="horizontal" />
                          </>
                        )}
                        <Typography
                          size="small"
                          onClick={() => {
                            handleConfigs()
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <SettingsIcon width={20} />
                          &nbsp; Configurações
                        </Typography>
                        <Spacer size="16" direction="horizontal" />
                      </>
                    )}
                    <Typography
                      size="small"
                      onClick={handleLogout}
                      style={{ cursor: 'pointer' }}
                    >
                      <LogOutCircleIcon width={20} />
                      &nbsp; Sair
                    </Typography>
                  </div>
                </>
              ))}
          </>
        )}
      </List>
      {cropImageIsOpen && (
        <ProfilePhotoModal
          onSave={updateLogo}
          imageURL={imageToCropURL}
          onCancel={() => setCropImageIsOpen(false)}
        />
      )}
    </>
  )
}

MenuList.propTypes = {
  className: PropTypes.string,
}

export default MenuList
