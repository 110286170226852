import { DropdownMenu } from '@77sol-ui/atoms'
import { Tooltip } from 'components/Tooltip'
import { type ISendProposalProps } from 'containers/SendProposal'
import { ButtonSend, StyledDropdownItem } from 'containers/SendProposal/styles'
import { Send, FileText } from 'lucide-react'
import React from 'react'
import * as S from './styles'

interface IDoubleButtonProps
  extends Omit<ISendProposalProps, 'dimensioningId' | 'buttonType'> {
  onClickEditDigitalProposal: () => void
  onClickSendDigitalProposal: () => void
  onClickPDF: () => void
}

export const DoubleButton: React.FC<IDoubleButtonProps> = ({
  asIcon,
  buttonSize = 'lg',
  onClickEditDigitalProposal,
  onClickSendDigitalProposal,
  onClickPDF,
}) => {
  return (
    <S.DoubleButtonContainer>
      <DropdownMenu.Root dir="ltr">
        {asIcon && (
          <Tooltip variant="white" content="Proposta digital">
            <DropdownMenu.Trigger asChild>
              <ButtonSend size={buttonSize} variant="secondary">
                <Send size={16} />
              </ButtonSend>
            </DropdownMenu.Trigger>
          </Tooltip>
        )}
        {!asIcon && (
          <DropdownMenu.Trigger asChild>
            <ButtonSend size={buttonSize} variant="secondary">
              <span>Proposta Digital</span>
              <Send size={16} />
            </ButtonSend>
          </DropdownMenu.Trigger>
        )}
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            side="bottom"
            sideOffset={4}
            onCloseAutoFocus={(e) => {
              e.preventDefault()
            }}
          >
            <StyledDropdownItem onSelect={onClickEditDigitalProposal}>
              Editar
            </StyledDropdownItem>

            <StyledDropdownItem onSelect={onClickSendDigitalProposal}>
              Enviar
            </StyledDropdownItem>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
      {!asIcon && (
        <ButtonSend onClick={onClickPDF} size={buttonSize} variant="primary">
          <span>Proposta PDF</span>
          <FileText size={16} />
        </ButtonSend>
      )}
      {asIcon && (
        <Tooltip variant="white" content="Proposta PDF">
          <ButtonSend onClick={onClickPDF} size={buttonSize} variant="primary">
            <FileText size={16} />
          </ButtonSend>
        </Tooltip>
      )}
    </S.DoubleButtonContainer>
  )
}
