import { useEffect, useRef, useState } from 'react'

export function useCalculateWidth() {
  const [pageWidth, setPageWidth] = useState<number>(0)
  const frameRef = useRef<number | null>(null)

  function handleCalculateWidth(
    container?: HTMLDivElement | null | undefined,
    canvas?: HTMLCanvasElement | null | undefined,
  ) {
    if (!container || !canvas) return

    frameRef.current = requestAnimationFrame(() => {
      const { width: containerWidth } = container.getBoundingClientRect()

      if (containerWidth === pageWidth) return

      setPageWidth(containerWidth)
    })
  }

  useEffect(() => {
    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current)
      }
    }
  }, [])

  return { handleCalculateWidth, pageWidth }
}
