const PREFIX = 'Proposta PDF:'

export const proposalPDFTracker = {
  actions: {
    openProposalPDF: `${PREFIX} Abrir proposta PDF`,
    changeProposalModel: `${PREFIX} Alterar modelo de proposta`,
    advanceStep: `${PREFIX} Avançar etapa`,
    changeSettings: `${PREFIX} Alterar configurações`,
    navigateBetweenQuotations: `${PREFIX} Navegar Entre Pré visualização das Cotações`,
    navigateBetweenPdfPages: `${PREFIX} Navegar Entre Páginas da Pré visualização de proposta`,
    togglePDFSection: `${PREFIX} Habilitar Seção PDF`,
  },
}
