import API from 'api'
import copy from 'copy-to-clipboard'
import { useToast } from '@77sol/core'
import { useCallback } from 'react'
import { urlPdfApi, urlApi } from 'urls'
import { type IShareType } from '../types/shareType'
import { type IProposalDTO } from 'dto/ProposalDTO'
import { type ISendEmailForm } from 'components/SendModal/components/SendEmailForm/validators'
import { type ISendWhatsAppForm } from 'components/SendModal/components/SendWhatsAppForm/validators'
import { formatProposalForTable } from '../utils/formatProposalForTable'
import { ProposalStatusEnum } from '../types/proposalStatusEnum'
import { useWhatsappSend } from 'hooks/useWhatsappSend'

interface IUseProposalShare {
  proposal: IProposalDTO
  onClose: () => void
  onSend: (type: IShareType) => void
}

export function useProposalShare({
  proposal,
  onClose,
  onSend,
}: IUseProposalShare) {
  const { handleWhatsAppSend } = useWhatsappSend()
  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  const proposalId = proposal?.id
  const proposalTableData = formatProposalForTable(proposal)
  const proposalUrl = `${process.env.REACT_APP_PROPOSAL_LINK as string}/${
    proposal.token
  }`

  const changeStatusToSended = useCallback(async () => {
    if (proposal.status === ProposalStatusEnum.Rascunho.toString()) {
      await API.patch(`proposal/change-status-send/${proposal.id}`)
    }
  }, [proposal])

  const handleEmailSubmit = useCallback(
    async (params: ISendEmailForm) => {
      if (proposalId) {
        try {
          await API.get(`/proposal/send_mail/${proposalId}`, {
            params,
          })

          onSuccessOpenToast('Proposta enviada com sucesso!')
          onClose()

          await changeStatusToSended()
        } catch {
          onErrorOpenToast('Ocorreu um erro ao enviar a proposta!')
        }
      }
    },
    [proposalId],
  )

  const handleWhatsAppSubmit = useCallback(
    async (data: ISendWhatsAppForm) => {
      handleWhatsAppSend(
        data.celular,
        `Olá ${proposal.customer.nome}! Segue a sua proposta solar: ${proposalUrl}. Qualquer dúvida é só me chamar!`,
      )

      await changeStatusToSended()
    },
    [proposal, proposalUrl],
  )

  const handleProposalLinkCopy = useCallback(async () => {
    onSend('copy')

    copy(proposalUrl)
    onSuccessOpenToast('Link da proposta copiado!')

    await changeStatusToSended()
  }, [proposalUrl])

  const handleProposalExport = useCallback(async () => {
    onSend('export')

    const apiToRedirect = urlPdfApi ?? urlApi ?? ''
    window.open(
      `${apiToRedirect}/api/proposal/pdf-generate/${proposal.token}`,
      '_blank',
    )

    await changeStatusToSended()
  }, [proposal])

  return {
    proposalTableData,
    handleEmailSubmit,
    handleWhatsAppSubmit,
    handleProposalLinkCopy,
    handleProposalExport,
  }
}
