export const formatDoc = (value = '') => {
  const valueFormatted = String(value || '').replace(/\D/g, '')
  return valueFormatted.length > 11
    ? valueFormatted
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    : valueFormatted
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
}

export const formatTransferData = (format = '', value = '') => {
  return (
    {
      agency: String(value || '')
        .replace(/\D/g, '')
        .replace(/(\d{4})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{1})\d+?$/, '$1'),
      account: String(value || '')
        .replace(/\D/g, '')
        .replace(/(\d{11})\d+?$/, '$1'),
      digit: String(value || '')
        .replace(/\D/g, '')
        .replace(/(\d{1})\d+?$/, '$1'),
    }[format] || value
  )
}

export const formatReal = (value) => {
  return parseFloat(value || 0).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  })
}

export const isNull = (values = []) => {
  return values.some((value) => !value)
}

export const removeNonDigits = (value) => {
  return String(value).replace(/\D/g, '')
}

export const parseBRLToNumber = (value) => {
  return parseFloat(
    value
      .replace(/R\$\s?/g, '')
      .replace(/\./g, '')
      .replace(',', '.'),
  )
}

export const cleanMask = (value) => {
  return String(value).replace(/[.\-/]/g, '')
}

export const formatCEP = (cep) => {
  cep = cep.replace(/\D/g, '')
  return cep.replace(/^(\d{5})(\d{3})$/, '$1-$2')
}
