import { useProposalListBudgetsAll } from 'domains/proposal/proposal-list-budgets/hooks/useProposalListBudgetsAll'
import { STALE_TIME } from '../constants'
import { useClientesView } from 'domains/clientes/clientes-view'
import { useProposalQuotationRoiCalculateAll } from 'domains/proposal/proposal-quotation-roi-calculate'
import { useProposalQuotationPaymentMethods } from 'domains/proposal/proposal-quotation-payment_methods'
import { useEffect } from 'react'
import { type IQuotationDTO } from 'dto/QuotationDTO'
import { handlePaymentMethodsData } from '../utils/handlePaymentMethodsData'

export const useQuotationQueries = ({
  quotations,
  customerId,
  proposalId,
  onAllPDFsLoaded,
}: {
  quotations: IQuotationDTO[]
  customerId: number
  proposalId: number
  onAllPDFsLoaded: (value: any) => void
}) => {
  const quotationIds = quotations.map((quotation) => quotation.id)

  const budgetQueries = useProposalListBudgetsAll({
    quotationIds,
    staleTime: STALE_TIME,
  })

  const roiQueries = useProposalQuotationRoiCalculateAll({
    quotationIds,
    staleTime: STALE_TIME,
  })

  const paymentMethodsQuery = useProposalQuotationPaymentMethods({
    proposalId,
    staleTime: STALE_TIME,
  })

  const clienteViewQuery = useClientesView({
    customerId,
    staleTime: STALE_TIME,
  })

  const budgetQueriesHasErrors = budgetQueries.some((query) => query.isError)
  const roiQueriesHasErrors = roiQueries.some((query) => query.isError)

  const budgetQueriesSuccess = budgetQueries.every((query) => query.isSuccess)
  const roiQueriesHasSuccess = roiQueries.every((query) => query.isSuccess)

  const allSucceded = [
    budgetQueriesSuccess,
    roiQueriesHasSuccess,
    paymentMethodsQuery.isSuccess,
    clienteViewQuery.isSuccess,
  ].every((value) => Boolean(value))

  const errorQueries = [
    budgetQueriesHasErrors,
    roiQueriesHasErrors,
    paymentMethodsQuery.isError,
    clienteViewQuery.isError,
  ].some((value) => Boolean(value))

  useEffect(() => {
    if (allSucceded) {
      const data = quotations.map((quotation, index) => {
        // @ts-expect-error array
        const find = paymentMethodsQuery?.data?.find(
          (item: IQuotationDTO) => item.id === quotation.id,
        )
        return {
          quotationId: quotation.id,
          clientInfo: clienteViewQuery.data,
          financingQuotationPaymentMethods: find.payments_methods,
          paymentMethods: handlePaymentMethodsData(find.payments_methods),
          roi: roiQueries[index].data,
          issuedIn: quotations[index].updated_at,
          buddget: budgetQueries.find((budget) =>
            budget.data?.find((item) => item?.quotation_id === quotation.id),
          )?.data,
        }
      })
      onAllPDFsLoaded(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSucceded])

  return {
    budgetQueries,
    roiQueries,
    paymentMethodsQuery,
    clienteViewQuery,
    errorQueries,
    allSucceded,
  }
}
