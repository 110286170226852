import { useQuery, type UseQueryOptions } from '@tanstack/react-query'
import { CLIENTES_VIEW } from '../constants/endpointKeys'
import { type IClientesViewParams } from '../services/types'
import { clientesView } from '../services/api'

export function useClientesView({
  customerId,
  enabled,
  retry,
  staleTime,
}: IClientesViewParams &
  Pick<UseQueryOptions, 'enabled' | 'staleTime' | 'retry'>) {
  return useQuery({
    queryKey: [`${CLIENTES_VIEW}`, customerId],
    queryFn: async () => await clientesView({ customerId }),
    enabled,
    retry,
    staleTime,
  })
}
