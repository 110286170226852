enum QUOTATION_PDF_TEMPLATE_ID_ENUM {
  FIRST = '1',
  SECOND = '2',
  THIRD = '3',
}

const QUOTATION_PDF_TEMPLATE_NAME = {
  '1': 'Modelo 1 (Simples)',
  '2': 'Modelo 2 (Cotação Lista)',
  '3': 'Modelo 3 (Cotação Grade)',
}

export { type QUOTATION_PDF_TEMPLATE_ID_ENUM, QUOTATION_PDF_TEMPLATE_NAME }
