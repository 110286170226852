const TEXT_LIMIT = 20

/**
 * Truncates a given text while prioritizing meaningful word selection.
 *
 * - Select the first two words.
 * - Includes a third word only if the second word has three or fewer characters.
 * - If the third word has three or fewer characters other words are added until find some word with more than 3 characters
 * - Appends an ellipsis (`...`) if the truncated text exceeds the specified limit.
 *
 * @param text - The input text to be truncated.
 * @param textLimit - The maximum character length of the returned string (default: `TEXT_LIMIT`).
 * @param showEllipsisInFirstText - If `true`, forces an ellipsis even if only the first word is present.
 * @returns A truncated string based on the specified limit and conditions.
 */

export function truncateText(
  text: string,
  textLimit = TEXT_LIMIT,
  showEllipsisInFirstText = false,
) {
  if (!text) return ''

  const brokenText = String(text)
    .split(' ')
    .filter((i) => i)

  const [firstText = '', secondText = '', ...otherWords] = brokenText

  const words: string[] = []

  if (firstText) {
    words.push(firstText)
  }

  if (secondText) {
    words.push(secondText)
  }

  if (secondText.length <= 3 && otherWords.length > 0) {
    let hasNext = true

    otherWords.forEach((word) => {
      if (hasNext) {
        words.push(word)
      }
      if (word.length > 3) {
        hasNext = false
      }
    })
  }

  const result = words.join(' ')

  if (result.length > textLimit && (secondText || showEllipsisInFirstText)) {
    return result.slice(0, textLimit).trim() + '...'
  }

  return result
}
