/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, createRef } from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import Tooltip from '@material-ui/core/Tooltip'
import { Typography, Avatar } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import smoothscroll from 'smoothscroll-polyfill'
import palette from 'app_palette'
import { NewContainer } from 'components'
import { ProposalTypography, Spacer, Toggle } from '@77sol/core'
import {
  EditIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  ViewIcon,
  LockIcon,
} from '@77sol/icons/dist'
import moment from 'moment'
import API from 'api'
import * as ProposalAction from 'store/actions'
import { ContainerQuotePdf } from '..'
import { CronogramaEdit } from '../../..'
import 'moment/locale/pt-br'

window.__forceSmoothScrollPolyfill__ = true
smoothscroll.polyfill()

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  let indexFinal = endIndex
  if (!list[endIndex].visible) {
    if (startIndex - endIndex == 1) {
      if (endIndex != 0) {
        indexFinal -= 1
      }
    } else {
      indexFinal += 1
    }
  }
  result.splice(indexFinal, 0, removed)

  return result
}

function ContainerPdf({
  container,
  index,
  proposal,
  logo,
  userName,
  containers,
  setStatePdf,
  isEditing,
  setIsEditing,
  isEditingMore,
  setIsEditingMore,
  stateQuotesPdf,
  setStateQuotesPdf,
  editRef,
  checkPdfDragAndDrop,
  checkDragAndDrop,
}) {
  const [editingFieldTitle, setEditingFieldTitle] = useState(
    proposal?.extraConfigs?.extra_configs?.find(
      (item) => item.id == container.id,
    )?.title,
  )
  const [editingFieldContent, setEditingFieldContent] = useState(
    proposal?.extraConfigs?.extra_configs?.find(
      (item) => item.id == container.id,
    )?.content,
  )
  const [imageEditContainer, setImageEditContainer] = useState(
    proposal?.extraConfigs?.extra_configs?.find(
      (item) => item.id == container.id,
    )?.content,
  )
  const [fieldType, setFieldType] = useState(
    proposal?.extraConfigs?.extra_configs?.find(
      (item) => item.id == container.id,
    )?.type
      ? proposal?.extraConfigs?.extra_configs?.find(
          (item) => item.id == container.id,
        )?.type
      : 'text',
  )

  const [themeCustomSwitch, setThemeCustomSwitch] = useState(
    proposal?.extraConfigs?.extra_configs?.find(
      (item) => item.id == container.id,
    )?.type == 'theme'
      ? 0
      : 1,
  )
  const [themeType, setThemeType] = useState('covers')
  const [selectedTheme, setSelectedTheme] = useState('')

  const handleEditContainer = () => {
    setLoadingExtras(true)

    const formData = new FormData()
    if (fieldType == 'image') {
      if (imageEditContainer.fileName) {
        formData.append(
          'content',
          imageEditContainer,
          imageEditContainer.fileName,
        )
      } else {
        formData.append('content', imageEditContainer)
      }
    } else {
      formData.append('content', editingFieldContent)
    }
    if (themeCustomSwitch == 0) {
      formData.append('content_key', editingFieldTitle?.split('/')[1])
    }
    formData.append('id', container.id)
    formData.append('proposal_id', proposal.proposal.id)
    formData.append('title', editingFieldTitle)
    formData.append('type', themeCustomSwitch == 0 ? 'theme' : fieldType)
    formData.append('status', 1)

    API.post('/proposal/extra-config/edit', formData, {
      headers: {
        token: tokenRegister,
      },
    })
      .then((res) => {
        setIsEditingMore('')
        setIsEditing(false)
        setLoadingExtras(false)
        handleGetExtraConfigs(proposal.proposal.id)
        checkPdfDragAndDrop(
          'update',
          container.id,
          editingFieldTitle,
          editingFieldContent,
        ).then(() => {
          checkDragAndDrop(
            'update',
            container.id,
            editingFieldTitle,
            editingFieldContent,
          )
        })
      })
      .catch(() => {
        setLoadingExtras(false)
      })
  }

  const handleGetExtraConfigs = (id) => {
    API.get(`/proposal/extra-configs/${id}`).then((res) => {
      dispatch(ProposalAction.SaveExtraConfigsRedux(res.data))
    })
  }

  // Scroll
  const refContainer = createRef()
  const handleClickContainer = (event) => {
    if (event) {
      event.preventDefault()
    }
    if (refContainer.current) {
      refContainer.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    }
  }

  useEffect(() => {
    if (!refContainer.current) {
      return
    }
    handleClickContainer()
  }, [refContainer])

  const tokenRegister = localStorage.getItem('sessionToken')
  const dispatch = useDispatch()
  const [loadingExtras, setLoadingExtras] = useState(false)
  const [qtdSchedule, setQtdSchedule] = useState(
    proposal?.extraConfigs?.schedules?.data
      ? proposal.extraConfigs.schedules.data.length
      : 1,
  )
  const [schedules, setSchedules] = useState(
    proposal?.extraConfigs?.schedules?.data
      ? proposal.extraConfigs.schedules.data
      : [{ title: '', weeks: '' }],
  )

  const handleSaveSchedules = (schedules) => {
    setLoadingExtras(true)

    API.post('/proposal/edit/schedules', {
      proposal_id: proposal.proposal.id,
      schedules,
    }).then((res) => {
      setLoadingExtras(false)
      dispatch(ProposalAction.SaveProposalRedux(res.data.proposal))
      setSchedules(res.data.proposal.schedules)
      setIsEditingMore('')
    })
  }

  const isString = (thing) =>
    Object.prototype.toString.call(thing) === '[object String]'

  const handleClickOutside = (event) => {
    if (editRef.current && !editRef.current.contains(event.target)) {
      setIsEditing('')
      setIsEditingMore('')
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
        cursor: 'pointer',
        border:
          isEditing == container.id && `2px solid ${palette.primary[300]}`,
        borderRadius: '8px',
        padding: isEditing == container.id && '8px',
        boxSizing: 'content-box',
      }}
    >
      <Draggable
        isDragDisabled
        type="droppableItem"
        key={container.id}
        draggableId={container.id}
        index={index}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            onClick={(e) => {
              const hasNumber = /\d/
              if (
                hasNumber.test(isEditing) ||
                (isString(isEditing) && isEditing?.includes('QuotePdf'))
              ) {
                e.stopPropagation()
              } else if (isEditing == container.id) {
                setIsEditing('')
              } else {
                setIsEditing(container.id)
              }
            }}
          >
            {container.id == 'ClientPdf' && (
              <div style={{ marginTop: index == 0 ? 0 : '12px' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div style={{ display: 'flex' }}>
                    <Avatar
                      variant="rounded"
                      style={{
                        height: 'auto',
                        width: 'auto',
                        marginRight: '16px',
                        backgroundColor: !logo && '#D9DBE9',
                        fontSize: '40px',
                      }}
                      imgProps={{
                        style: {
                          height: 'initial',
                          width: 'initial',
                          maxHeight: '100px',
                          maxWidth: '140px',
                        },
                      }}
                      src={logo}
                    >
                      {userName
                        ?.match(/(\b\S)?/g)
                        .join('')
                        .match(/(^\S|\S$)?/g)
                        .join('')
                        .toUpperCase()}
                    </Avatar>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <ProposalTypography
                        type="display_x_small_bold"
                        color="primary"
                        colorWeight="300"
                      >
                        {userName}
                      </ProposalTypography>
                      <Spacer size="4" direction="horizontal" />
                      <Typography
                        style={{
                          fontSize: '10px',
                          color: palette.grayscale[500],
                          lineHeight: '15px',
                        }}
                      >
                        Proposta emitida:{' '}
                        {moment(proposal.proposal.created_at).format(
                          'DD/MM/YYYY',
                        )}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: '10px',
                          color: palette.grayscale[500],
                          lineHeight: '15px',
                        }}
                      >
                        Cliente:{' '}
                        {
                          proposal?.clients.find(
                            (client) =>
                              client.id == proposal.proposal.customer_id,
                          )?.label
                        }
                      </Typography>
                      {proposal?.clients.find(
                        (client) => client.id == proposal.proposal.customer_id,
                      )?.endereco && (
                        <Typography
                          style={{
                            fontSize: '10px',
                            color: palette.grayscale[500],
                            lineHeight: '15px',
                          }}
                        >
                          {
                            proposal?.clients.find(
                              (client) =>
                                client.id == proposal.proposal.customer_id,
                            )?.endereco
                          }
                          ,{' '}
                          {
                            proposal?.clients.find(
                              (client) =>
                                client.id == proposal.proposal.customer_id,
                            )?.numero
                          }{' '}
                          -{' '}
                          {
                            proposal?.clients.find(
                              (client) =>
                                client.id == proposal.proposal.customer_id,
                            )?.cidade
                          }
                          ,{' '}
                          {
                            proposal?.clients.find(
                              (client) =>
                                client.id == proposal.proposal.customer_id,
                            )?.uf
                          }
                        </Typography>
                      )}
                    </div>
                  </div>
                  <Spacer size="4" direction="horizontal" />
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {proposal.proposal.validity && (
                      <Typography
                        style={{
                          fontSize: '10px',
                          color: palette.grayscale[500],
                          lineHeight: '15px',
                        }}
                      >
                        Proposta válida por:{' '}
                        {moment(proposal.proposal.validity).diff(
                          moment(),
                          'days',
                        ) + 1}{' '}
                        dias
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
            )}
            {isEditing == container.id && (
              <div
                ref={(el) => {
                  refContainer.current = el
                }}
                onClick={(e) => e.stopPropagation()}
                style={{
                  position: 'absolute',
                  zIndex: 10,
                  top: '-30px',
                  right: '-30px',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
                  padding: '12px',
                  backgroundColor: '#FFF',
                  cursor: 'auto',
                  maxWidth: '100%',
                  minWidth:
                    isEditingMore &&
                    !container?.id?.toString()?.includes('QuotePdf')
                      ? '-webkit-fill-available'
                      : 'auto',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    style={{
                      fontSize: '14px',
                      color: '#707072',
                      paddingRight: '12px',
                      borderRight: '1px solid #e2e6e7',
                    }}
                  >
                    {isEditing == container.id &&
                    container.display.split('/')[1] ? (
                      <>
                        {(container.display.split('/')[0] == 'covers' &&
                          `${`${'Capa' + ' ('}${
                            containers
                              ?.filter(
                                (item) =>
                                  item.display.split('/')[0] == 'covers',
                              )
                              .findIndex((item) => item.id == container.id) + 1
                          }`})`) ||
                          (container.display.split('/')[0] == 'explanations' &&
                            `${`${'Explicações' + ' ('}${
                              containers
                                ?.filter(
                                  (item) =>
                                    item.display.split('/')[0] ==
                                    'explanations',
                                )
                                .findIndex((item) => item.id == container.id) +
                              1
                            }`})`) ||
                          (container.display.split('/')[0] == 'benefits' &&
                            `${`${'Vantagens' + ' ('}${
                              containers
                                ?.filter(
                                  (item) =>
                                    item.display.split('/')[0] == 'benefits',
                                )
                                .findIndex((item) => item.id == container.id) +
                              1
                            }`})`) ||
                          (container.display.split('/')[0] == 'partners' &&
                            `${`${'Parceiros' + ' ('}${
                              containers
                                ?.filter(
                                  (item) =>
                                    item.display.split('/')[0] == 'partners',
                                )
                                .findIndex((item) => item.id == container.id) +
                              1
                            }`})`) ||
                          container.display.split('/')[0]}
                      </>
                    ) : (
                      <>{isEditing == container.id && container.display}</>
                    )}
                  </Typography>
                  <div
                    style={{
                      paddingLeft: '12px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {container.id != 'LinkPdf' &&
                      container.id != 'ClientPdf' && (
                        <Tooltip title="Editar">
                          <EditIcon
                            onClick={() => {
                              if (isEditingMore == container.id) {
                                setIsEditingMore('')
                              } else {
                                setIsEditingMore(container.id)
                              }
                            }}
                            width="16"
                            color={palette.grayscale[500]}
                            style={{ cursor: 'pointer', marginRight: '8px' }}
                          />
                        </Tooltip>
                      )}
                    {index != 0 &&
                      containers.filter(
                        (container) => container.visible == true,
                      )[0].id != container.id && (
                        <Tooltip title="Mover para cima">
                          <ArrowUpIcon
                            width="16"
                            color={palette.grayscale[500]}
                            style={{ cursor: 'pointer', marginRight: '8px' }}
                            onClick={() => {
                              if (index != 0) {
                                const newContainers = reorder(
                                  containers,
                                  index,
                                  index - 1,
                                )
                                setStatePdf({ containers: newContainers })
                              }
                            }}
                          />
                        </Tooltip>
                      )}
                    {index != containers.length - 1 &&
                      containers
                        .filter((container) => container.visible == true)
                        .reverse()[0].id != container.id && (
                        <Tooltip title="Mover para baixo">
                          <ArrowDownIcon
                            onClick={() => {
                              const newContainers = reorder(
                                containers,
                                index,
                                index + 1,
                              )
                              setStatePdf({ containers: newContainers })
                            }}
                            width="16"
                            color={palette.grayscale[500]}
                            style={{ cursor: 'pointer', marginRight: '8px' }}
                          />
                        </Tooltip>
                      )}
                    <Tooltip title="Esconder">
                      <ViewIcon
                        onClick={() => {
                          const newContainer = Array.from(containers)
                          const indexx = newContainer.findIndex(
                            (containerr) => containerr.id == container.id,
                          )
                          newContainer[indexx].visible = false
                          setStatePdf({ containers: newContainer })
                        }}
                        width="16"
                        color={palette.grayscale[500]}
                        style={{ cursor: 'pointer' }}
                      />
                    </Tooltip>
                  </div>
                </div>
                {isEditingMore == container.id && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {container.id == 'LinkPdf' && <></>}
                    {isString(container.id) &&
                      container.id.includes('QuotePdf') && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            marginTop: '16px',
                          }}
                        >
                          {stateQuotesPdf
                            .find(
                              (quote) =>
                                quote[0].id.replace(/[^0-9]/g, '') ==
                                container.id.replace(/[^0-9]/g, ''),
                            )
                            .map((option, i) => (
                              <div
                                key={i}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  marginBottom: '8px',
                                }}
                              >
                                <Typography
                                  style={{
                                    color: palette.grayscale[700],
                                    fontSize: '11px',
                                    lineHeight: '12px',
                                    marginRight: '18px',
                                  }}
                                >
                                  {option.display}
                                </Typography>
                                {option.display == 'Formas de pagamento' ? (
                                  <LockIcon
                                    width="16"
                                    color={palette.grayscale[600]}
                                    style={{ marginRight: '6px' }}
                                  />
                                ) : (
                                  <Toggle
                                    size="small"
                                    checked={option.visible}
                                    onChange={() => {
                                      const newArr = [...stateQuotesPdf]
                                      const index = newArr.findIndex(
                                        (quote) =>
                                          quote[0].id.replace(/[^0-9]/g, '') ==
                                          container.id.replace(/[^0-9]/g, ''),
                                      )
                                      newArr[index][i].visible =
                                        !newArr[index][i].visible
                                      setStateQuotesPdf([...newArr])
                                    }}
                                  />
                                )}
                              </div>
                            ))}
                        </div>
                      )}
                    {container.id == 'SchedulesPdf' && (
                      <CronogramaEdit
                        qtdSchedule={qtdSchedule}
                        setQtdSchedule={setQtdSchedule}
                        schedules={schedules}
                        setSchedules={setSchedules}
                        handleFinish={handleSaveSchedules}
                        loadingExtras={loadingExtras}
                      />
                    )}
                    {Number.isInteger(container.id) && (
                      <NewContainer
                        title={editingFieldTitle}
                        setTitle={setEditingFieldTitle}
                        content={editingFieldContent}
                        setContent={setEditingFieldContent}
                        handleSave={handleEditContainer}
                        loading={loadingExtras}
                        image={imageEditContainer}
                        setImage={setImageEditContainer}
                        fieldType={fieldType}
                        setFieldType={setFieldType}
                        themeCustomSwitch={themeCustomSwitch}
                        setThemeCustomSwitch={setThemeCustomSwitch}
                        themeType={themeType}
                        setThemeType={setThemeType}
                        selectedTheme={selectedTheme}
                        setSelectedTheme={setSelectedTheme}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
            {proposal.quotes.map((quote, i) => (
              <>
                {container.id == `QuotePdf${i}` && (
                  <Droppable
                    type="droppableQuotePdf"
                    droppableId="droppableQuotePdf"
                  >
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {stateQuotesPdf[i].map((containerQuote, index) => (
                          <ContainerQuotePdf
                            key={index}
                            container={containerQuote}
                            containerFather={container}
                            index={index}
                            quote={quote}
                            i={i}
                            isEditing={isEditing}
                            setIsEditing={setIsEditing}
                            isEditingMore={isEditingMore}
                            setIsEditingMore={setIsEditingMore}
                            setStateQuotesPdf={setStateQuotesPdf}
                            stateQuotesPdf={stateQuotesPdf}
                          />
                        ))}
                      </div>
                    )}
                  </Droppable>
                )}
              </>
            ))}
            {container.id == 'LinkPdf' && (
              <div onClick={() => setIsEditing(container.id)}>
                <div style={{ marginTop: '12px', width: '100%' }}>
                  <Typography
                    style={{
                      fontSize: '10px',
                      backgroundColor: palette.grayscale[300],
                      color: palette.grayscale[700],
                      padding: '4px 10px',
                    }}
                  >
                    Para aceitar a proposta ou requisitar mudanças, acesse:
                  </Typography>
                  <table
                    style={{
                      borderSpacing: '0px',
                      width: '100%',
                      border: '1px solid #DFE3E8',
                    }}
                  >
                    <tr>
                      <td>
                        <div
                          style={{ paddingLeft: '10px', paddingRight: '10px' }}
                        >
                          <a
                            href={() => false}
                            style={{
                              fontSize: '10px',
                              wordBreak: 'break-all',
                              lineHeight: '15px',
                              color: palette.grayscale[700],
                            }}
                          >
                            {`${process.env.REACT_APP_PROPOSAL_LINK}/${proposal.proposal.token}`}
                          </a>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            )}
            {Number.isInteger(container.id) && (
              <div onClick={() => setIsEditing(container.id)}>
                {proposal.extraConfigs.extra_configs
                  .find((item) => item.id == container.id)
                  ?.title?.split('/')[1] ? (
                  <div style={{ marginTop: '12px' }}>
                    <img
                      alt="extra field"
                      style={{ width: '100%', height: 'auto' }}
                      src={
                        proposal.extraConfigs.extra_configs.find(
                          (item) => item.id == container.id,
                        )?.content
                      }
                    />
                  </div>
                ) : (
                  <div style={{ marginTop: '12px' }}>
                    <Typography
                      style={{
                        fontSize: '10px',
                        backgroundColor: palette.grayscale[300],
                        color: palette.grayscale[700],
                        padding: '4px 10px',
                      }}
                    >
                      {
                        proposal.extraConfigs.extra_configs.find(
                          (item) => item.id == container.id,
                        )?.title
                      }
                    </Typography>
                    <table
                      style={{
                        borderSpacing: '0px',
                        width: '100%',
                        border: '1px solid #DFE3E8',
                      }}
                    >
                      <tr>
                        <td>
                          {proposal.extraConfigs.extra_configs
                            .find((item) => item.id == container.id)
                            ?.content.includes('.s3') ? (
                            <img
                              alt="extra config"
                              style={{ width: '100%', height: 'auto' }}
                              src={
                                proposal.extraConfigs.extra_configs.find(
                                  (item) => item.id == container.id,
                                )?.content
                              }
                            />
                          ) : (
                            <Typography
                              style={{
                                fontSize: '8px',
                                padding: '4px 10px',
                                lineHeight: '12px',
                                color: palette.grayscale[600],
                              }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: proposal.extraConfigs.extra_configs
                                    .find((item) => item.id == container.id)
                                    ?.content.replaceAll(/\n/g, '<br/>'),
                                }}
                              />
                            </Typography>
                          )}
                        </td>
                      </tr>
                    </table>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </Draggable>
    </div>
  )
}

export default ContainerPdf
