import { useState } from 'react'

export const useNavigationSteps = ({
  initialStep,
  onSubmit,
  onClose,
  onChangedStep,
}: {
  initialStep: number
  onSubmit: () => void
  onClose: () => void
  onChangedStep?: (from: number, to: number) => void
}) => {
  const [currentStep, setCurrentStep] = useState(initialStep ?? 0)

  function handleNextStep() {
    if (currentStep === 3) {
      onSubmit()
      return
    }
    setCurrentStep(currentStep + 1)
    onChangedStep?.(currentStep, currentStep + 1)
  }

  function handlePrevStep() {
    if (currentStep === 0) {
      onClose()
      return
    }
    setCurrentStep(currentStep - 1)
    onChangedStep?.(currentStep, currentStep - 1)
  }

  return { currentStep, handleNextStep, handlePrevStep }
}
