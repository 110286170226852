import {
  type ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react'

interface SuccessFinancingRequestModalData {
  financingIdCreated: number | null
  isSuccessFinancingModalOpen: boolean
  setSuccessFinancingModalOpen: (value: boolean, financingId?: number) => void
}

interface ProviderProps {
  children: ReactNode
}

const SuccessFinancingRequestModalContext =
  createContext<SuccessFinancingRequestModalData>(
    {} as SuccessFinancingRequestModalData,
  )

function SuccessFinancingRequestModalProvider({ children }: ProviderProps) {
  const [isSuccessFinancingModalOpen, setIsSuccessFinancingModalOpen] =
    useState(false)
  const [financingIdCreated, setFinancingIdCreated] =
    useState<SuccessFinancingRequestModalData['financingIdCreated']>(null)

  const setSuccessFinancingModalOpen: SuccessFinancingRequestModalData['setSuccessFinancingModalOpen'] =
    useCallback((value, financingId) => {
      setIsSuccessFinancingModalOpen(value)

      if (financingId) {
        setFinancingIdCreated(financingId)
      }
    }, [])

  const contextValue = useMemo(
    () => ({
      financingIdCreated,
      isSuccessFinancingModalOpen,
    }),
    [financingIdCreated, isSuccessFinancingModalOpen],
  )

  return (
    <SuccessFinancingRequestModalContext.Provider
      value={{
        ...contextValue,
        setSuccessFinancingModalOpen,
      }}
    >
      {children}
    </SuccessFinancingRequestModalContext.Provider>
  )
}

const useSuccessFinancingRequestModalContext = () => {
  const context = useContext(SuccessFinancingRequestModalContext)
  return context
}

export {
  useSuccessFinancingRequestModalContext,
  SuccessFinancingRequestModalProvider,
}
