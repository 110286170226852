import { type IButtonProps } from '@77sol-ui/atoms'
import { useCallback } from 'react'
import { ModalExportPDF } from './components/ModalExportPDF'
import { urlProposalLink } from 'urls'
import { SingleButton } from './variants/SingleButton'
import { DoubleButton } from './variants/DoubleButton'
import { usePDFDataStore } from './components/ModalExportPDF/store/usePDFDataStore'
import { useOpenExportModalStore } from './stores/useOpenExportModalStore'
import { useAmplitude } from 'hooks/useAmplitude'
import { proposalPDFTracker } from 'services/tracker/events/proposalPDF'
import { proposalTracker } from 'services/tracker/events/proposal/trackers'

export interface ISendProposalProps {
  urlToken?: string
  buttonSize?: 'md' | 'lg'
  asIcon?: boolean
  variant?: IButtonProps['variant']
  dimensioningId: string
  buttonType?: 'single' | 'double'
  onClickEditDigitalproposal?: () => void
}

export function SendProposal({
  urlToken,
  buttonSize = 'lg',
  asIcon = false,
  variant,
  dimensioningId,
  buttonType = 'single',
  onClickEditDigitalproposal = () => {},
}: ISendProposalProps) {
  const { exportModalIsOpen, openExportModal } = useOpenExportModalStore()

  const { logEvent } = useAmplitude()
  const resetStore = usePDFDataStore((state) => state.reset)

  const handleOpenDigitalProposal = useCallback(() => {
    if (!urlToken) return
    logEvent(proposalTracker.actions.openDigitalProposal, {
      origin: window.location.pathname,
    })
    window.open(
      `https://${urlProposalLink as string}/${urlToken}`,
      '_blank',
      'noopener,noreferrer',
    )
  }, [urlToken])

  const handleChangeOpenModal = (value: boolean) => {
    if (!value) resetStore()
    openExportModal(value)
  }

  const handleEventTrigger = () => {
    logEvent(proposalPDFTracker.actions.openProposalPDF, {
      origin: window.location.pathname,
    })
  }
  return (
    <>
      {buttonType === 'single' && (
        <SingleButton
          buttonSize={buttonSize}
          variant={variant}
          asIcon={asIcon}
          onDigitalProposalClick={handleOpenDigitalProposal}
          onPdfProposalClick={() => {
            handleChangeOpenModal(true)
            handleEventTrigger()
          }}
        />
      )}
      {buttonType === 'double' && (
        <DoubleButton
          buttonSize={buttonSize}
          variant={variant}
          asIcon={asIcon}
          onClickEditDigitalProposal={onClickEditDigitalproposal}
          onClickSendDigitalProposal={handleOpenDigitalProposal}
          onClickPDF={() => {
            handleChangeOpenModal(true)
            handleEventTrigger()
          }}
        />
      )}
      {exportModalIsOpen && (
        <ModalExportPDF
          open={exportModalIsOpen}
          dimensioningId={dimensioningId}
          onOpenChange={(value) => {
            handleChangeOpenModal(value)
          }}
        />
      )}
    </>
  )
}
