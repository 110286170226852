import { PROPOSAL_LIST_BUDGETS } from '../constants/endpointKeys'
import api from 'api'
import {
  type IProposalListBudgetsParams,
  type IProposalListBudgetsResponse,
} from './types'

export const proposalListBudgets = async ({
  quotationId,
}: IProposalListBudgetsParams) => {
  return await api
    .get<IProposalListBudgetsResponse[]>(
      `${PROPOSAL_LIST_BUDGETS}/${quotationId}`,
    )
    .then(({ data }) => data)
}
