import { colors, fontFamily, fontWeight } from '@77sol-ui/tokens'
import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;

  }
`

export const Wrapper = styled.div`
  min-height: 0;
  flex: auto;
  height: 100%;
  width: 100%;

  animation: ${fadeIn} 0.3s ease-in-out;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track:hover {
    background-color: ${colors.gray[500]};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.gray[400]};
    border-radius: 0;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${colors.gray[300]};
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background-color: #323539;
  display: flex;
  flex-direction: column;

  .react-pdf__Document {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .react-pdf__Page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: auto;
    background-color: ${colors.gray[500]} !important;
  }

  .react-pdf__message {
    width: 100%;
    height: 100%;
  }
`

export const ButtonCircle = styled.button`
  width: 22px;
  min-width: 22px;

  border-radius: 999px;
  > svg {
    width: 12px !important;
    height: 12px !important;
  }

  height: 22px;

  background-color: transparent;
  border: 1px solid ${colors.gray[400]};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.white[0]};

  transition: background-color 0.2s ease-in-out;

  &:hover:not(:disabled) {
    background-color: ${colors.gray[500]};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${colors.gray[400]};
    opacity: 0.4;
  }
`

export const Header = styled.header`
  height: 37px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  gap: 24px;

  .header__left,
  .header__right {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .header__center {
    min-width: 0;
    flex: auto;
  }
`
export const Text = styled.span<{ hidden?: boolean }>`
  font-family: ${fontFamily.sans[0]};
  font-weight: ${fontWeight.regular};
  font-size: 0.625rem;
  color: ${colors.white[0]};
  display: block;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
`

export const ScrollArea = styled.div`
  height: 100%;
  min-height: 0;
  flex: auto;
  overflow: auto;

  margin: 0 4px 4px 4px;
`

export const Container = styled.div`
  height: 100%;

  .delay-to-appear {
    opacity: 0;
    animation: ${fade} 0.2s forwards;

    animation-delay: 2s;
  }

  #error-container > h2 {
    color: ${colors.gray[200]};
  }

  canvas {
    animation: ${fade} 0.3s ease-in-out;
  }
`

export const ActionsGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  &:after {
    content: '';
    width: 1px;
    height: 16px;
    background-color: ${colors.gray[400]};
  }
`
