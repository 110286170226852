import simpleImage from '../../assets/simple.png'
import quotationListImage from '../../assets/quotation-list.png'
import quotationGridImage from '../../assets/quotation-grid.png'

export const PDF_TEMPLATE_ID = 'pdf-template-selector'

export const TEMPLATE_SELECTOR_ID = 'template-selector'
export const TEMPLATE_SELECTOR_TITLE_ID = 'template-selector-title'
export const TEMPLATE_SELECTOR_BUTTON_OPEN_PREVIEW =
  'template-selector-button-open-preview'
export const TEMPLATE_SELECTOR_CONTENT_ID = 'template-selector-content'
export const TEMPLATE_SELECTOR_RADIO_ID = 'template-selector-radio'
export const TEMPLATE_SELECTOR_IMAGE_ID = 'template-selector-image'

export const TEMPLATE_SIMPLE_ID = 'template-simple'
export const TEMPLATE_QUOTATION_LIST_ID = 'template-quotation-list'
export const TEMPLATE_QUOTATION_GRID_ID = 'template-quotation-grid'

export const IMAGES = {
  simpleImage,
  quotationListImage,
  quotationGridImage,
}
