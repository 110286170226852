import { V2_TOKEN_API_STORAGE } from 'storage/v2ApiTokenStorage'

export const useTokenInterceptor = (
  config: any,
  token = V2_TOKEN_API_STORAGE.get(),
) => {
  if (!token) return config
  config.headers.Authorization = `Bearer ${token}`
  return config
}
