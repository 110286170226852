import { useCallback, useRef, useState } from 'react'
import { calculateScale } from '../utils/calculateScale'

export interface IUseZoomProps {
  defaultScale?: number
  defaultFit?: boolean
}

export function useZoom({
  defaultScale = 1,
  defaultFit = false,
}: IUseZoomProps = {}) {
  const [scale, setScale] = useState<number>(defaultScale)
  const [fit, setFit] = useState(defaultFit)
  const scaleFit = useRef(defaultScale)

  function handleZoomIn(valueToAdd: number) {
    if (scale <= 5) {
      setScale(scale + valueToAdd)
    }
  }

  function handleResetZoom() {
    if (fit) {
      setScale(scaleFit.current)
      return
    }

    setScale(defaultScale)
    scaleFit.current = defaultScale
  }

  function handleZoomOut(valueToAdd: number) {
    if (scale >= 0.3) {
      setScale(scale - valueToAdd)
    }
  }

  const handleFitToContainer = useCallback(
    (
      container?: HTMLDivElement | null | undefined,
      canvas?: HTMLCanvasElement | null | undefined,
    ) => {
      if (!container || !canvas) return

      const { width: containerWidth, height: containerHeight } =
        container.getBoundingClientRect()

      const buffer = 2
      const adjustedContainerWidth = containerWidth - buffer
      const adjustedContainerHeight = containerHeight - buffer

      const { width: currentCanvasWidth, height: currentCanvasHeight } =
        canvas.getBoundingClientRect()

      const newScale = calculateScale(
        currentCanvasWidth / scale,
        currentCanvasHeight / scale,
        adjustedContainerWidth,
        adjustedContainerHeight,
      )

      scaleFit.current = newScale
      setScale(newScale)
    },
    [scale],
  )

  const handleFitToContainerWidth = useCallback(
    (container?: HTMLDivElement | null | undefined) => {
      if (!container) return

      setScale(defaultScale)
    },
    [defaultScale],
  )

  const handleToggleFit = useCallback(
    ({
      canvas,
      container,
    }: {
      container?: HTMLDivElement | null | undefined
      canvas?: HTMLCanvasElement | null | undefined
    }) => {
      setFit((oldValue) => !oldValue)
      if (!fit) {
        handleFitToContainer(container, canvas)

        return
      }

      handleFitToContainerWidth(container)
    },
    [fit, handleFitToContainer, handleFitToContainerWidth],
  )

  return {
    fit,
    scale,
    handleZoomIn,
    handleResetZoom,
    handleZoomOut,
    handleToggleFit,
  }
}
