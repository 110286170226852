import { useAcl } from 'acl/hooks/useAcl'
import { type INewSidebarNavMenuItems } from 'components/Sidebar/Desktop/constants/integratorNavbarItems'
import { useAmplitude } from 'hooks/useAmplitude'
import useWindowSize from 'hooks/useWindowSize'
import { useSignals } from 'layouts/Dashboard/components/NavBar/components/NavigationListItem/hooks/useSignals'
import { useHistory } from 'react-router-dom'
import { notificationsTracker } from 'services/tracker/events/notifications/trackers'
import { useSidebarMobileStore } from 'store/modals/SidebarMobileStore'
import { urlLogin } from 'urls'
import { clearStorage } from 'utils/clearStorage'

export function useSidebarMobile() {
  const history = useHistory()
  const { signals } = useSignals()
  const { isOpen, reset } = useSidebarMobileStore()
  const { checkIfUserCanAccessRoute } = useAcl()
  const { logEvent } = useAmplitude()
  const { width } = useWindowSize()
  const IS_MOBILE = width < 768

  const validatePermission = (page: INewSidebarNavMenuItems) => {
    return checkIfUserCanAccessRoute(page.href)
  }

  function onRedirect(item: INewSidebarNavMenuItems) {
    logEvent(`Menu Lateral esquerdo: ${item.title}`)
    reset()
  }

  function handleLogout() {
    clearStorage()

    return (window.location.href = urlLogin as string)
  }

  function handleOpenedNotifications() {
    logEvent(notificationsTracker.actions.openNotifications, {
      origin: 'sidebar',
      type: 'Button',
    })
  }

  function handleClickOnLogo() {
    history.push('/')
    reset()
  }

  return {
    isOpen,
    reset,
    signals,
    validatePermission,
    onRedirect,
    handleLogout,
    IS_MOBILE,
    handleOpenedNotifications,
    handleClickOnLogo,
    history,
  }
}
