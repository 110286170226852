import { useState } from 'react'

export interface IUsePaginateProps {
  defaultPage?: number
  onChangedPage?: (from: number, to: number) => void
}

export function usePaginate({
  defaultPage = 1,
  onChangedPage,
}: IUsePaginateProps = {}) {
  const [totalPages, setTotalPages] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(defaultPage)

  function handleNextPage() {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
      onChangedPage?.(currentPage, currentPage + 1)
    }
  }

  function handlePreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
      onChangedPage?.(currentPage, currentPage - 1)
    }
  }

  function handleSetTotalPages(value: number) {
    setTotalPages(value)
  }

  return {
    currentPage,
    totalPages,
    handleNextPage,
    handlePreviousPage,
    handleSetTotalPages,
  }
}
