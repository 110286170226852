import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  container: {
    flexGrow: 1,
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: '16px',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  arrowToggleButton: ({ isDescFilter }) => ({
    backgroundColor: 'transparent !important',
    border: 'none',
    transform: `rotate(${isDescFilter ? '0deg' : '180deg'})`,
    transition: 'transform 0.3s ease-in-out',
  }),
  arrowToggleIcon: {
    width: '24px',
    color: palette.grayscale[800],
  },
  filterToggleButton: ({ isOrdenationOpen }) => ({
    backgroundColor: 'transparent !important',
    border: 'none',
    color: isOrdenationOpen ? palette.primary[300] : palette.grayscale[800],
  }),
  filterToggleIcon: ({ isOrdenationOpen }) => ({
    width: '24px',
    color: isOrdenationOpen ? palette.primary[300] : palette.grayscale[800],
  }),
  modalContent: {
    backgroundColor: 'white',
    padding: '36px',
  },
  deleteIconContent: {
    backgroundColor: '#FF0000',
    display: 'flex',
    borderRadius: '50%',
    padding: '16px',
  },
  archiveIconContent: {
    backgroundColor: '#3371F2',
    display: 'flex',
    borderRadius: '50%',
    padding: '24px',
  },
  icon: {
    width: '60px',
    height: '60px',
    '& > path': {
      fill: 'white',
    },
  },
  modalLabel: {
    fontSize: '24px',
    padding: '16px',
    textAlign: 'center',
  },
  slide: {
    '& #project-slide': {
      height: 'calc(100vh - 100px)',
      zIndex: 0,
    },
  },
  scrollContainerDesktop: {
    height: 'calc(var(--app-height) - 328px)',
    overflow: 'auto',
  },

  scrollContainerMobile: {
    height: 'calc(var(--app-height) - 365px)',
    overflow: 'auto',
    overflowX: 'hidden',
  },
  mobileDesktop: {
    textAlign: 'center',
    padding: '16px',
    marginBottom: '24px',
  },
  mobileLoading: {
    height: '60px',
  },
}))

export default useStyles
