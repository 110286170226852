import styled from 'styled-components'
import { fontFamily, fontWeight } from '@77sol-ui/tokens'

export const StyledTitle = styled.h1`
  font-size: 20px;
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.sans[0]};
  padding-top: 8px;
  padding-bottom: 8px;

  width: 100%;
  overflow-x: hidden;
  min-height: 38px;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (min-width: 576px) {
    min-height: 52px;
    font-size: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
`
