import { ReactPDFViewer } from 'modules/PDF/PDFTemplateRender/components/ReactPDFViewer'
import { Content } from './styles'
import { Button } from '@77sol-ui/atoms'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { useNavigateBetweenQuotations } from './hooks/useNavigateBetweenQuotations'
import { type IDimensioningGETResponse } from 'domains/dimensioning/services/types'
import { type IUserSettingsDTO } from 'dto/UserSettingsDTO'
import { type IUserProfileDTO } from 'dto/UserProfileDTO'
import { type IPDFData } from '../../hooks/useModalExportPDFSteps'
import { type IProposalQuotationPaymentMethodsResponse } from 'domains/proposal/proposal-quotation-payment_methods/services/types'

import { BREAKPOINTS_VALUES } from 'constants/breakpoints'
import { CircularProgress } from 'components'
import { ErrorState } from 'components/ErrorState'
import { memo, useCallback, useMemo, useState } from 'react'
import { useQuotationQueries } from './hooks/useQuotationQueries'
import { handlePaymentMethodsData } from './utils/handlePaymentMethodsData'
import {
  type IQueries,
  useBindQuotationQueries,
} from './hooks/useBindQuotationQueries'
import useWindowSize from 'hooks/useWindowSize'
import { type IQuotationDTO } from 'dto/QuotationDTO'
import { type IQuotationBudgetDTO } from 'dto/QuotationBudgetDTO'
import { type ICustomerDTO } from 'dto/CustomerDTO'
import { type IROIDTO } from 'dto/ROIDTO/ROIDTO'
import { type IQuotationPDFData } from '../../store/usePDFDataStore'
import { Tooltip } from 'components/Tooltip'
import { useAmplitude } from 'hooks/useAmplitude'
import { proposalPDFTracker } from 'services/tracker/events/proposalPDF'

export interface IProposalPreviewProps {
  dimensioning: IDimensioningGETResponse | undefined
  pdfData: IPDFData
  userSettings: IUserSettingsDTO | undefined
  userLogged: IUserProfileDTO
  onAllPDFsLoaded: (value: IQuotationPDFData[]) => void
  disableNextNavigation?: boolean
  onFirstPdfLoaded: (value: boolean) => void
  selectedTemplate: '1' | '2' | '3'
}

export const ProposalPreview = memo(
  ({
    pdfData,
    userSettings,
    userLogged,
    dimensioning,
    onAllPDFsLoaded,
    onFirstPdfLoaded,
    selectedTemplate,
  }: IProposalPreviewProps) => {
    const [isCreatingPDF, setIsCreatingPDF] = useState(true)
    const { width } = useWindowSize()

    const { logEvent } = useAmplitude()

    const isMobile = width < BREAKPOINTS_VALUES.md

    const {
      hasMultipleQuotations,
      hasNextQuotation,
      hasPrevQuotation,
      handleNextQuotation,
      handlePrevQuotation,
      selectedQuotation,
      selectedQuotationIndex,
    } = useNavigateBetweenQuotations({
      quotations: pdfData?.selectedQuotations,
      onNavigationSuccess: (type) => {
        const eventProps = new Map()

        eventProps.set('origin', 'Modal Proposta PDF')

        if (type === 'next') {
          eventProps.set('action', 'Avançar')
        } else if (type === 'prev') {
          eventProps.set('action', 'Voltar')
        }

        logEvent(proposalPDFTracker.actions.navigateBetweenQuotations, {
          ...Object.fromEntries(eventProps),
        })
      },
    })

    const queries = useQuotationQueries({
      quotations: pdfData?.selectedQuotations ?? [],
      customerId: Number(dimensioning?.proposal?.customer_id),
      proposalId: dimensioning?.proposal?.id as number,
      onAllPDFsLoaded,
    })

    const { isLoading, data, refetchAll } = useBindQuotationQueries({
      queries: queries as unknown as IQueries,
      selectedQuotationIndex,
    })

    const isError = queries.errorQueries
    const disabledNavigation = isLoading || isError || isCreatingPDF

    const paymentMethods = useMemo(
      () =>
        data?.paymentMethodsData?.find(
          (item: IQuotationDTO) => item.id === selectedQuotation.id,
        ),
      [data?.paymentMethodsData, selectedQuotation.id],
    )

    const handlePaymentMethod = useMemo(
      () =>
        (
          paymentMethodData:
            | IProposalQuotationPaymentMethodsResponse
            | undefined,
        ) =>
          handlePaymentMethodsData(paymentMethodData?.payments_methods),
      [],
    )

    const Preview = useCallback(() => {
      if (isLoading) {
        return <CircularProgress text="Carregando..." />
      }

      if (isError) {
        return (
          <ErrorState
            title="Ops! Algo deu errado ao carregar as informações."
            subtitle="Não foi possível carregar as informações."
            showImage
            buttonText="Tentar novamente"
            onButtonAction={() => {
              refetchAll()
            }}
          />
        )
      }

      return (
        <div className="viewer">
          <ReactPDFViewer
            viewerProps={{
              headerText: `Cotação #${selectedQuotation.id}`,
              documentProps: {
                onLoadSuccess: () => {
                  setIsCreatingPDF(false)
                },
              },
            }}
            userSettings={{
              ...(userSettings as IUserSettingsDTO),
              default_template_id: selectedTemplate,
            }}
            proposal={dimensioning?.proposal}
            dimensioningProps={dimensioning?.dimensioning_properties}
            products={[
              ...(selectedQuotation.products || []),
              ...(selectedQuotation.added_products || []),
            ]}
            issuedIn={selectedQuotation?.updated_at}
            financingQuotationPaymentMethods={paymentMethods?.payments_methods}
            paymentMethods={handlePaymentMethod(paymentMethods)}
            userLogged={userLogged}
            quotationBudget={data?.budgetsData as IQuotationBudgetDTO[]}
            clientInfo={data?.customerData as ICustomerDTO}
            roi={data?.roiData as IROIDTO}
            onLoaded={() => {
              onFirstPdfLoaded(true)
            }}
          />
        </div>
      )
    }, [
      data?.budgetsData,
      data?.customerData,
      data?.roiData,
      dimensioning?.dimensioning_properties,
      dimensioning?.proposal,
      handlePaymentMethod,
      isError,
      isLoading,
      onFirstPdfLoaded,
      paymentMethods,
      refetchAll,
      selectedQuotation.id,
      selectedQuotation.products,
      selectedQuotation?.updated_at,
      selectedTemplate,
      userLogged,
      userSettings,
    ])

    if (!hasMultipleQuotations) {
      return (
        <Content withPadding>
          <Preview />
        </Content>
      )
    }

    if (isMobile) {
      return (
        <Content>
          <div className="button__container">
            <Button
              variant="outline"
              asIcon
              disabled={!hasPrevQuotation || disabledNavigation}
              onClick={handlePrevQuotation}
            >
              <ChevronLeft size={20} />
            </Button>
            {selectedQuotation && <span>Cotação #{selectedQuotation.id}</span>}
            <Button
              variant="outline"
              asIcon
              disabled={!hasNextQuotation || disabledNavigation}
              onClick={handleNextQuotation}
            >
              <ChevronRight size={20} />
            </Button>
          </div>
          <Preview />
        </Content>
      )
    }

    return (
      <Content>
        <Tooltip variant="white" content="Cotação Anterior">
          <Button
            variant="ghost"
            disabled={!hasPrevQuotation || disabledNavigation}
            onClick={handlePrevQuotation}
          >
            <ChevronLeft size={24} />
          </Button>
        </Tooltip>
        <Preview />

        <Tooltip variant="white" content="Próxima Cotação">
          <Button
            variant="ghost"
            disabled={!hasNextQuotation || disabledNavigation}
            onClick={handleNextQuotation}
          >
            <ChevronRight size={24} />
          </Button>
        </Tooltip>
      </Content>
    )
  },
)
