import { Button } from '@77sol-ui/atoms'
import { StyledHeader, ContentLeft, ContentRight } from './styles'
import { ChevronLeftIcon, Expand, LayoutPanelTop } from 'lucide-react'
import { SendProposal } from 'containers/SendProposal'

export interface IHeaderProps extends React.HTMLAttributes<HTMLElement> {
  onShowSections?: () => void
  onBack?: () => void
  urlToken?: string
  onShowFullscreen?: () => void
  buttonHideSectionsRef?: React.MutableRefObject<HTMLButtonElement>
  dimensioningId: string
}

export function Header({
  onShowFullscreen,
  onShowSections,
  urlToken,
  onBack,
  buttonHideSectionsRef,
  dimensioningId,
  ...props
}: IHeaderProps) {
  return (
    <StyledHeader {...props}>
      <ContentLeft>
        <Button variant="ghost" onClick={onBack}>
          <ChevronLeftIcon size={24} />
        </Button>
        <h3>Editar proposta</h3>
      </ContentLeft>
      <ContentRight>
        <Button
          ref={buttonHideSectionsRef}
          variant="secondary"
          onClick={onShowSections}
        >
          <LayoutPanelTop size={16} />
          <span>Seções ocultadas</span>
        </Button>
        <Button variant="secondary" onClick={onShowFullscreen}>
          <Expand size={16} />
          <span>Ver em tela cheia</span>
        </Button>
        <SendProposal urlToken={urlToken} dimensioningId={dimensioningId} />
      </ContentRight>
    </StyledHeader>
  )
}
