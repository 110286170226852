import {
  TEMPLATES_NAME,
  TEMPLATES_INFOS,
} from 'containers/PDFTemplatesSelector/constants'
import { TemplateSelector } from '../../TemplateSelector'
import { TEMPLATE_QUOTATION_LIST_ID } from 'containers/PDFTemplatesSelector/test/constants'

interface IQuotationListProps {
  showPreview?: boolean
  onExpandPreview?: () => void
}

export function QuotationList({
  onExpandPreview,
  showPreview = true,
}: IQuotationListProps) {
  const { id, image, title } = TEMPLATES_INFOS.QUOTATION_LIST

  return (
    <TemplateSelector
      data-testid={TEMPLATE_QUOTATION_LIST_ID}
      name={TEMPLATES_NAME}
      image={image}
      title={title}
      value={id}
      onExpandPreview={onExpandPreview}
      showPreview={showPreview}
    />
  )
}
