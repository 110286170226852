import * as Styled from './styles'
import { Button, Radio } from '@77sol-ui/atoms'
import {
  TEMPLATE_SELECTOR_ID,
  TEMPLATE_SELECTOR_TITLE_ID,
  TEMPLATE_SELECTOR_RADIO_ID,
  TEMPLATE_SELECTOR_CONTENT_ID,
  TEMPLATE_SELECTOR_IMAGE_ID,
  TEMPLATE_SELECTOR_BUTTON_OPEN_PREVIEW,
} from 'containers/PDFTemplatesSelector/test/constants'

interface ITemplateSelector {
  name: string
  value: string
  image: string
  title: string
  showPreview?: boolean
  onExpandPreview?: () => void
}

export function TemplateSelector({
  name,
  value,
  image,
  title,
  onExpandPreview,
  showPreview = true,
  ...props
}: ITemplateSelector) {
  const templateId = `${name}-${value}`

  return (
    <Styled.Root data-testid={TEMPLATE_SELECTOR_ID} {...props}>
      <Styled.Header>
        <Styled.Title data-testid={TEMPLATE_SELECTOR_TITLE_ID}>
          {title}
        </Styled.Title>
        {showPreview && (
          <Button
            asIcon
            variant="ghost"
            type="button"
            onClick={onExpandPreview}
            data-testid={TEMPLATE_SELECTOR_BUTTON_OPEN_PREVIEW}
          >
            <Styled.Icon />
          </Button>
        )}
      </Styled.Header>
      <Styled.Content
        data-testid={TEMPLATE_SELECTOR_CONTENT_ID}
        htmlFor={templateId}
      >
        <Styled.Check>
          <Radio.Item
            id={templateId}
            data-testid={TEMPLATE_SELECTOR_RADIO_ID}
            name={name}
            value={value}
          />
        </Styled.Check>
        <Styled.Image
          data-testid={TEMPLATE_SELECTOR_IMAGE_ID}
          alt={title}
          src={image}
        />
      </Styled.Content>
    </Styled.Root>
  )
}
