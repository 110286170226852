import { fontSize } from '@77sol-ui/tokens'
import { BREAKPOINTS } from 'constants/breakpoints'
import styled, { css } from 'styled-components'

export const Content = styled.div<{ withPadding?: boolean }>`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;

  .viewer {
    height: 100%;
    width: 100%;
    flex: auto;
    min-width: 0;
    min-height: 0;
  }

  .button__container {
    display: flex;
    gap: 4px;
    align-items: center;
    align-self: end;

    > span {
      font-size: ${fontSize.sm};
      margin: 0px 4px;
    }
  }

  @media (min-width: ${BREAKPOINTS.md}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  }

  @media (min-width: ${BREAKPOINTS.xxl}) {
    ${({ withPadding }) =>
      withPadding &&
      css`
        padding: 0 94px;
      `}
    gap: 36px;
  }
`
