function UploadIcon() {
  return (
    <svg
      width={46}
      height={47}
      viewBox="0 0 46 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x={3} y={3.5} width={40} height={40} rx={20} fill="#CAE0FC" />
      <rect
        x={3}
        y={3.5}
        width={40}
        height={40}
        rx={20}
        stroke="#ECF2FE"
        strokeWidth={6}
      />
      <path
        d="M19.667 26.833L23 23.5m0 0l3.334 3.333M23 23.5V31m6.667-3.548a4.583 4.583 0 00-2.917-8.12.516.516 0 01-.445-.25 6.25 6.25 0 10-9.816 7.58"
        stroke="#2D69F6"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UploadIcon
