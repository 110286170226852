import { Button } from '@77sol-ui/atoms'
import { Carousel, Modal } from '@77sol-ui/molecules'
import { useDisplayNewsAnnouncement } from 'containers/NewFeaturesInformativeContainer/hooks/useDisplayNewsAnnouncement'
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Paragraph, ModalTitle } from './styles'
import { useAmplitude } from 'hooks/useAmplitude'
import { proposalTracker } from 'services/tracker/events/proposal/trackers'
import { newFeatureModalPDFTracker } from 'services/tracker/events/newFeatureModal'

interface INewConfigPDFScreenAnnouncementProps {
  enable: boolean
  localStorageKey: string
}

export function NewConfigPDFScreenAnnouncement({
  enable,
  localStorageKey,
}: INewConfigPDFScreenAnnouncementProps) {
  const { logEvent } = useAmplitude()
  const { isOpen, handleChangeIsOpen } = useDisplayNewsAnnouncement({
    enable,
    localStorageKey,
  })

  const history = useHistory()

  const handleRedirectToPdfConfig = useCallback(() => {
    logEvent(proposalTracker.actions.accessCustomizeProposal, {
      origin: `${window.location.pathname} > Modal de novidade`,
    })

    history.push('/configuracoes/propostas')
  }, [history, logEvent])

  const handlePreventModalCloseWithClickOutside = useCallback((e: Event) => {
    e.preventDefault()
  }, [])

  useEffect(() => {
    if (isOpen) {
      logEvent(newFeatureModalPDFTracker.actions.openModal, {
        origin: `${window.location.pathname} > Modal de novidade`,
        newFeature: 'Proposta PDF 3x',
      })
    }
  }, [isOpen, logEvent])

  return (
    <Modal.Root open={isOpen} onOpenChange={handleChangeIsOpen}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Wrapper
          id="new-feature-informative-modal"
          test-id="new-feature-informative-modal"
          maxWidth="768px"
          maxHeight="95dvh"
          onPointerDownOutside={handlePreventModalCloseWithClickOutside}
          onOpenAutoFocus={(e) => {
            e.preventDefault()
          }}
        >
          <Modal.Header>
            <ModalTitle>
              A REVOLUÇÃO DA PROPOSTA PDF DA 77SOL CHEGOU!
            </ModalTitle>
          </Modal.Header>

          <Modal.Content>
            <Paragraph>
              Novos modelos de PDF para suas propostas: mais profissional e
              ágil. Envie digital ou impresso para seus clientes, e mostre a
              qualidade do seu trabalho. Configure agora!
            </Paragraph>

            <Carousel.Root
              key="false"
              cssEase="linear"
              dots
              slidesToScroll={1}
              slidesToShow={1}
              infinite={true}
            >
              <Carousel.Item>
                <Carousel.Image src="https://77solassets.s3.sa-east-1.amazonaws.com/proposal-pdf/pdf-config.gif" />
              </Carousel.Item>
              <Carousel.Item>
                <Carousel.Image src="https://77solassets.s3.sa-east-1.amazonaws.com/proposal-pdf/pdf-config-pt2.gif" />
              </Carousel.Item>
            </Carousel.Root>
          </Modal.Content>

          <Modal.Footer>
            <Modal.Close asChild>
              <Button variant="outline" fullWidth>
                Configurar depois
              </Button>
            </Modal.Close>

            <Button onClick={handleRedirectToPdfConfig} fullWidth>
              Configurar agora
            </Button>
          </Modal.Footer>
        </Modal.Wrapper>
      </Modal.Portal>
    </Modal.Root>
  )
}
