interface IuseDisableButtonsProps {
  currentStep: number
  hasSelectedQuotation: boolean
  allPDFsLoaded: boolean
  userSettingsDataIsLoaded: boolean
  firstPDFPreviewLoaded: boolean
  downloadIsLoading: boolean
}
export const useDisableButtons = ({
  currentStep,
  hasSelectedQuotation,
  allPDFsLoaded,
  userSettingsDataIsLoaded,
  firstPDFPreviewLoaded,
  downloadIsLoading,
}: IuseDisableButtonsProps) => {
  const disabledChooseQuotation = currentStep === 1 && !hasSelectedQuotation
  const disabledChoosePreviewQuotation =
    (currentStep === 2 && !allPDFsLoaded) ||
    (currentStep === 2 && !userSettingsDataIsLoaded) ||
    (currentStep === 2 && !firstPDFPreviewLoaded)

  const disabledDownloads = downloadIsLoading

  const okIsLoading = downloadIsLoading
  const okDisabled =
    disabledChooseQuotation ||
    disabledChoosePreviewQuotation ||
    disabledDownloads

  const cancelDisabled = false

  return { okDisabled, cancelDisabled, okIsLoading }
}
