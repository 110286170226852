import { useSelector } from 'react-redux'
import { type IReduxStore } from 'types/redux'

export function useUserId() {
  const userIdStorage = localStorage.getItem('sessionDataId')
  const userIdReducer = useSelector(
    (state: IReduxStore) => state.ReducerProfile.userProfile.id,
  )

  return Number(userIdReducer || userIdStorage)
}
