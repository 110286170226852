import { useFinancingSimulationsSlideStore } from 'views/Financing/stores/financingSimulationsSlideStore'
import { useOrdersReserved } from 'domains/orders/reserved/hooks/useOrdersReserved'

export function useOrdersData(fetchOrders = true) {
  const { orderId } = useFinancingSimulationsSlideStore()

  const {
    data: orderList = [],
    isError: isErrorOrders,
    isLoading: isLoadingOrders,
  } = useOrdersReserved({
    enabled: fetchOrders,
  })

  const orderSelected = orderList.find(
    ({ id }) => Number(id) === Number(orderId),
  )

  return {
    orderList,
    isErrorOrders,
    isLoadingOrders,
    orderSelected,
  }
}
