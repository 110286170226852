import { Button, DropdownMenu } from '@77sol-ui/atoms'
import { fontFamily } from '@77sol-ui/tokens'
import styled, { css } from 'styled-components'

export const StyledDropdownItem = styled(DropdownMenu.Item)`
  font-family: ${fontFamily.sans[0]};
  font-size: 0.875rem;
`

export const ButtonSend = styled(Button)<{ size: 'md' | 'lg' }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  > span {
    font-family: ${fontFamily.sans[0]};
  }

  gap: 16px;
  padding: 12px 32px;

  ${(props) =>
    props.size === 'md' &&
    css`
      padding: 0.5rem 1rem;
      gap: 8px;
    `}
`
