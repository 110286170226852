import React from 'react'
import { Content } from '../../styles'

export const DownloadPDF: React.FC = () => {
  return (
    <Content>
      <span>
        Parabéns! <br /> <br /> Sua proposta em PDF está perfeitamente
        configurada e pronta para ser exportada. Após exportar, você pode
        enviá-la ao seu cliente.
      </span>
    </Content>
  )
}
