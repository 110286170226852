import styled from 'styled-components'
import { MoveDiagonal } from 'lucide-react'
import { colors, fontFamily, fontSize, fontWeight } from '@77sol-ui/tokens'

export const Root = styled.div`
  width: fit-content;

  display: grid;
  grid-gap: 14px;
`

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.h5`
  font-size: ${fontSize.xs};
  font-weight: ${fontWeight.medium};
  font-family: ${fontFamily.sans.toString()};
`

export const Icon = styled(MoveDiagonal)`
  height: 14px;
  width: 14px;

  color: ${colors.blue[300]};
`

export const Content = styled.label`
  cursor: pointer;

  height: 230px;
  width: 163px;

  position: relative;
  overflow: hidden;

  border-radius: 7px;
  border: thin solid ${colors.gray[100]};

  &:has(button[data-state='checked']) {
    border: thin solid ${colors.blue[300]};
  }
`

export const Image = styled.img`
  height: 100%;
  width: 100%;
`

export const Check = styled.div`
  position: absolute;
  right: 0;
  padding: 14px;
`
