/* eslint-disable @tanstack/query/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
import { wrap } from 'comlink'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { type IPDFTemplatesPreviewProps } from 'modules/PDF/PDFTemplateRender'
import { useQuery } from '@tanstack/react-query'
import { type WorkerType } from 'modules/PDF/PDFTemplateRender/workers/pdf.worker'

export interface IUseReactPDFViewerProps {
  previewProps: IPDFTemplatesPreviewProps
}

export function useReactPDFViewer({ previewProps }: IUseReactPDFViewerProps) {
  const urlToRevokeRef = useRef<string | null>(null)
  const [url, setUrl] = useState<string>('')

  const pdfWorker = useMemo(() => {
    return wrap<WorkerType>(
      new Worker(
        new URL(
          'modules/PDF/PDFTemplateRender/workers/pdf.worker',
          import.meta.url,
        ),
      ),
    )
  }, [])

  const generateQueryKey = useMemo(() => {
    return {
      ...previewProps,
      userSettings: {
        ...previewProps.userSettings,
        created_at: '',
        updated_at: '',
      },
    }
  }, [previewProps])

  const {
    data: blob,
    isLoading,
    isError,
    isSuccess,
  } = useQuery({
    queryKey: ['pdf-blob', generateQueryKey],
    queryFn: async ({ signal }) => {
      const blob = await pdfWorker.generatePDFBlob(previewProps)

      if (signal?.aborted) {
        throw new Error('Query was canceled')
      }

      return blob
    },
    staleTime: Infinity,
    cacheTime: 900000, // 15 minutes
  })

  const revokeObjectURL = useCallback(() => {
    if (urlToRevokeRef.current) {
      URL.revokeObjectURL(urlToRevokeRef.current)
      urlToRevokeRef.current = null
    }
  }, [])

  useEffect(() => {
    if (isSuccess && Boolean(blob)) {
      urlToRevokeRef.current = URL.createObjectURL(blob)

      setUrl(urlToRevokeRef.current)
    }

    return () => {
      pdfWorker.terminate()
      revokeObjectURL()
    }
  }, [blob, isSuccess])

  return {
    isLoading,
    isError,
    url,
    revokeObjectURL,
  }
}
