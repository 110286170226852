import { pdfExpress } from 'apis/pdfExpress'
import { READ_USER_SETTINGS } from '../constants/endpointKeys'
import {
  type IReadUserSettingsParams,
  type IReadUserSettingsResponse,
} from './types'

export const readUserSettings = async ({ userId }: IReadUserSettingsParams) => {
  return await pdfExpress
    .get<IReadUserSettingsResponse>(`${READ_USER_SETTINGS}${userId}`)
    .then(({ data }) => data)
}
