import { PROPOSAL_QUOTATION_PAYMENT_METHODS } from '../constants/endpointKeys'
import api from 'api'
import {
  type IProposalQuotationPaymentMethodsParams,
  type IProposalQuotationPaymentMethodsResponse,
} from './types'

export const proposalQuotationPaymentMethods = async ({
  proposalId,
}: IProposalQuotationPaymentMethodsParams) => {
  return await api
    .get<IProposalQuotationPaymentMethodsResponse[]>(
      `${PROPOSAL_QUOTATION_PAYMENT_METHODS}/${proposalId}`,
    )
    .then(({ data }) => data)
}
