import { colors } from '@77sol-ui/tokens'
import styled from 'styled-components'

export const AlertContainer = styled.div`
  margin: 12px 0px;

  p {
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
`

export const DropzoneContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 250px;

  h3 {
    margin-top: 10px;
    font-family: DM Sans;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: ${colors.gray[600]};
  }

  span {
    font-family: DM Sans;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    margin-top: 4px;
    color: ${colors.gray[500]};
  }

  @media (max-width: 991px) {
    h3 {
      font-size: 14px;
    }
    span {
      font-size: 12px;
    }
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    font-family: DM Sans;
    line-height: 24px;
  }
`
