import { Button } from '@77sol-ui/atoms'
import { Carousel, Modal } from '@77sol-ui/molecules'
import { useDisplayNewsAnnouncement } from 'containers/NewFeaturesInformativeContainer/hooks/useDisplayNewsAnnouncement'
import { useCallback, useState } from 'react'
import { Paragraph, ModalTitle, ModalWrapper, CarouselContent } from './styles'
import { useOpenExportModalStore } from 'containers/SendProposal/stores/useOpenExportModalStore'

interface INewDownloadPDFScreenAnnouncementProps {
  enable: boolean
  localStorageKey: string
}

export function NewDownloadPDFScreenAnnouncement({
  enable,
  localStorageKey,
}: INewDownloadPDFScreenAnnouncementProps) {
  const { isOpen, handleChangeIsOpen } = useDisplayNewsAnnouncement({
    enable,
    localStorageKey,
  })

  const [imgIsLoading, setImgIsLoading] = useState(true)

  const { openExportModal } = useOpenExportModalStore()

  const handlePreventModalCloseWithClickOutside = useCallback((e: Event) => {
    e.preventDefault()
  }, [])

  return (
    <Modal.Root open={isOpen} onOpenChange={handleChangeIsOpen}>
      <Modal.Portal>
        <Modal.Overlay />
        <ModalWrapper
          id="new-feature-informative-modal"
          test-id="new-feature-informative-modal"
          maxWidth="768px"
          onPointerDownOutside={handlePreventModalCloseWithClickOutside}
          onOpenAutoFocus={(e) => {
            e.preventDefault()
          }}
          data-loading={imgIsLoading}
        >
          <Modal.Close />

          <Modal.Header>
            <ModalTitle>PROPOSTAS EM PDF: BAIXE AGORA!</ModalTitle>
          </Modal.Header>

          <Modal.Content>
            <Paragraph>
              Novos modelos de propostas em PDF disponíveis para download e
              envio! Economize tempo na negociação com seus clientes, com
              documentos profissionais prontos para uso.
            </Paragraph>
            <CarouselContent data-loading={imgIsLoading}>
              <Carousel.Root
                key="false"
                cssEase="linear"
                dots
                slidesToScroll={1}
                slidesToShow={1}
                infinite={true}
              >
                <Carousel.Item>
                  <picture>
                    <source
                      srcSet="https://77solassets.s3.sa-east-1.amazonaws.com/proposal-pdf/pdf-download-mobile.gif"
                      media="(max-width: 1279px)"
                    />
                    <img
                      onLoad={() => {
                        setImgIsLoading(false)
                      }}
                      src="https://77solassets.s3.sa-east-1.amazonaws.com/proposal-pdf/pdf-download.gif"
                    />
                  </picture>
                </Carousel.Item>
                <Carousel.Item>
                  <Carousel.Image src="https://77solassets.s3.sa-east-1.amazonaws.com/proposal-pdf/pdf-download-pt2.gif" />
                </Carousel.Item>
              </Carousel.Root>
            </CarouselContent>
          </Modal.Content>

          <Modal.Footer>
            <Button variant="outline" fullWidth asChild>
              <a
                target="_blank"
                href="/configuracoes/propostas"
                rel="noopener noreferrer"
              >
                Configurações do PDF
              </a>
            </Button>

            <Button
              onClick={() => {
                handleChangeIsOpen(false)
                openExportModal(true)
              }}
              fullWidth
            >
              Ver meu PDF agora!
            </Button>
          </Modal.Footer>
        </ModalWrapper>
      </Modal.Portal>
    </Modal.Root>
  )
}
