import simpleImage from '../assets/simple.png'
import quotationListImage from '../assets/quotation-list.png'
import quotationGridImage from '../assets/quotation-grid.png'
import { type TemplateInfos } from '../types'

export const TEMPLATES_NAME = 'pdf-template'

export const TEMPLATES_INFOS: TemplateInfos = {
  SIMPLE: {
    id: '1',
    title: 'Modelo 1',
    image: simpleImage,
  },
  QUOTATION_LIST: {
    id: '2',
    title: 'Modelo 2',
    image: quotationListImage,
  },
  QUOTATION_GRID: {
    id: '3',
    title: 'Modelo 3',
    image: quotationGridImage,
  },
}

export const TEMPLATE_DEFAULT = TEMPLATES_INFOS.SIMPLE.id
