export const BREAKPOINTS_VALUES = {
  xs: 480,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
}

export const BREAKPOINTS = {
  xs: `${BREAKPOINTS_VALUES.xs}px`,
  sm: `${BREAKPOINTS_VALUES.sm}px`,
  md: `${BREAKPOINTS_VALUES.md}px`,
  lg: `${BREAKPOINTS_VALUES.lg}px`,
  xl: `${BREAKPOINTS_VALUES.xl}px`,
  xxl: `${BREAKPOINTS_VALUES.xxl}px`,
}
