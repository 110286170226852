import { saveAs } from 'file-saver'
import { wrap } from 'comlink'
import { useCallback, useState } from 'react'
import {
  type IDimensioningPropertiesDTO,
  type IDimensioningGETResponse,
} from 'domains/dimensioning/services/types'
import { type WorkerType } from 'modules/PDF/PDFTemplateRender/workers/pdf.worker'
import { type IQuotationPDFData } from '../store/usePDFDataStore'
import { type IUserProfileDTO } from 'dto/UserProfileDTO'
import { type IReadUserSettingsResponse } from 'domains/pdf-express/read-user-settings/services/types'
import { isMobileDevice } from 'utils/deviceDetection'

interface IProps {
  pdfData: IQuotationPDFData[]
  dimensioningProps: IDimensioningPropertiesDTO
  proposal: IDimensioningGETResponse['proposal']
  userLogged: IUserProfileDTO
  userSettings: IReadUserSettingsResponse
}

interface IBlobData {
  blob: Blob
  fileName: string
}

async function handleMobileDownload(blobs: IBlobData[]) {
  const pdfFiles = blobs.map(({ blob, fileName }) => {
    const pdfBlob = new Blob([blob], { type: 'application/pdf' })
    return new File([pdfBlob], fileName, { type: 'application/pdf' })
  })

  const title = pdfFiles.length > 1 ? 'Propostas PDF' : 'Proposta PDF'

  if (navigator.share && navigator.canShare({ files: pdfFiles })) {
    try {
      await navigator.share({
        files: pdfFiles,
        title,
      })
      return true
    } catch (error) {
      return false
    }
  }
  return false
}

function openPDFsInNewTabs(blobs: IBlobData[]) {
  const urls: string[] = []

  blobs.forEach(({ blob }) => {
    const pdfBlob = new Blob([blob], { type: 'application/pdf' })
    const url = URL.createObjectURL(pdfBlob)
    window.open(url, '_blank')
    urls.push(url)
  })
  return urls
}

export function useDownloadPDF() {
  const [isLoading, setIsLoading] = useState(false)
  const [urlsTorevoke, setUrlsToRevoke] = useState<string[]>([] as string[])

  const isMobile = isMobileDevice()

  const handleDownloadPDF = useCallback(
    async ({
      pdfData,
      dimensioningProps,
      proposal,
      userLogged,
      userSettings,
    }: IProps) => {
      setIsLoading(true)
      const blobPromises = pdfData.map(async (pdf) => {
        const pdfWorker = wrap<WorkerType>(
          new Worker(
            new URL(
              'modules/PDF/PDFTemplateRender/workers/pdf.worker',
              import.meta.url,
            ),
          ),
        )

        const blob = await pdfWorker.generatePDFBlob({
          roi: pdf.roi,
          products: pdf.products,
          paymentMethods: pdf.paymentMethods,
          quotationBudget: pdf.quotationBudget,
          clientInfo: pdf.clientInfo,
          dimensioningProps,
          proposal,
          userLogged,
          userSettings,
          issuedIn: pdf.issuedIn,
          financingQuotationPaymentMethods:
            pdf.financingQuotationPaymentMethods,
        })

        pdfWorker.terminate()

        const fileName = `proposta_${pdf?.clientInfo?.nome as string}_cotação_${
          pdf.quotationId
        }.pdf`

        return {
          blob,
          fileName,
        }
      })

      const blobs = await Promise.all(blobPromises)

      if (isMobile) {
        const shareSuccessful = await handleMobileDownload(blobs)
        if (!shareSuccessful) {
          const urls = openPDFsInNewTabs(blobs)
          setUrlsToRevoke(urls)
        }
      } else {
        blobs.forEach(({ blob, fileName }) => {
          saveAs(blob, fileName)
        })
      }

      setIsLoading(false)
    },
    [isMobile],
  )

  const revokeURLS = useCallback(() => {
    urlsTorevoke.forEach((url) => {
      URL.revokeObjectURL(url)
    })
  }, [urlsTorevoke])

  return { handleDownloadPDF, isLoading, revokeURLS }
}
