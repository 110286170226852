import { Modal } from '@77sol-ui/molecules'
import { BREAKPOINTS } from 'constants/breakpoints'
import { fontFamily } from '@77sol-ui/tokens'
import styled, { keyframes } from 'styled-components'

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const ModalWrapper = styled(Modal.Wrapper)`
  transition: max-width 0.3s, max-height 0.3s, height 0.3s;

  &[data-step='2'] {
    height: 100% !important;
    max-height: 90dvh !important;

    @media (min-width: ${BREAKPOINTS.sm}) {
      max-width: 80% !important;
    }

    @media (min-width: ${BREAKPOINTS.lg}) {
      max-width: 65% !important;
    }

    @media (min-width: ${BREAKPOINTS.xxl}) {
      max-width: 50% !important;
    }
  }
  * {
    font-family: ${fontFamily.sans.toString()};
  }
`

export const Content = styled.div`
  animation: ${fade} 0.15s ease-in-out;
`

export const Description = styled(Modal.Description)`
  max-width: 90%;
`

export const FooterButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 0.75rem;
  & > button {
    height: 2.5625rem;
  }
`
