import { Template } from './components/Templates'
import { Radio } from '@77sol-ui/atoms'
import { PDF_TEMPLATE_ID } from './test/constants'
import { useState } from 'react'
import { TEMPLATES_INFOS } from './constants'
import { PreviewModal } from './components/PreviewModal'
import { type ITemplatesProps, Templates } from './styles'
import { type TemplateId, type ITemplateAction } from './types'
import { QUOTATION_PDF_TEMPLATE_NAME } from 'enums/quotationPDF/QuotationPDFEnum'
import { useAmplitude } from 'hooks/useAmplitude'
import { proposalPDFTracker } from 'services/tracker/events/proposalPDF'

export interface IPDFTemplatesSelector
  extends ITemplateAction,
    ITemplatesProps {
  selectedTemplate: TemplateId
  defaultTemplate?: TemplateId
  showPreview?: boolean
}

export function PDFTemplatesSelector({
  onSelect,
  noWrap,
  selectedTemplate,
  showPreview = true,
}: IPDFTemplatesSelector) {
  const { logEvent } = useAmplitude()
  const [isPreviewOpen, setIsPreviewOpen] = useState<
    { isOpen: true; template: TemplateId } | { isOpen: false; template: null }
  >({
    isOpen: false,
    template: null,
  })

  return (
    <>
      <Radio.Root>
        <Radio.Group
          size="small"
          iconType="success"
          value={selectedTemplate}
          onValueChange={(value) => {
            onSelect(value as TemplateId)
            logEvent(proposalPDFTracker.actions.changeProposalModel, {
              template: QUOTATION_PDF_TEMPLATE_NAME[value as TemplateId],
              origin: window.location.pathname,
            })
          }}
        >
          <Templates data-testid={PDF_TEMPLATE_ID} noWrap={noWrap}>
            <Template.Simple
              showPreview={showPreview}
              onExpandPreview={() => {
                setIsPreviewOpen({
                  isOpen: true,
                  template: TEMPLATES_INFOS.SIMPLE.id,
                })
              }}
            />
            <Template.QuotationList
              showPreview={showPreview}
              onExpandPreview={() => {
                setIsPreviewOpen({
                  isOpen: true,
                  template: TEMPLATES_INFOS.QUOTATION_LIST.id,
                })
              }}
            />
            <Template.QuotationGrid
              onExpandPreview={() => {
                setIsPreviewOpen({
                  isOpen: true,
                  template: TEMPLATES_INFOS.QUOTATION_GRID.id,
                })
              }}
              showPreview={showPreview}
            />
          </Templates>
        </Radio.Group>
      </Radio.Root>

      {isPreviewOpen.isOpen && (
        <PreviewModal
          isOpen={isPreviewOpen.isOpen}
          templateId={isPreviewOpen.template}
          onOpenChange={() => {
            setIsPreviewOpen({
              isOpen: false,
              template: null,
            })
          }}
          onSelect={(templateId) => {
            onSelect(templateId)
            setIsPreviewOpen({
              isOpen: false,
              template: null,
            })
          }}
        />
      )}
    </>
  )
}
