import {
  TEMPLATES_NAME,
  TEMPLATES_INFOS,
} from 'containers/PDFTemplatesSelector/constants'
import { TemplateSelector } from '../../TemplateSelector'
import { TEMPLATE_QUOTATION_GRID_ID } from 'containers/PDFTemplatesSelector/test/constants'

interface IQuotationGridProps {
  onExpandPreview?: () => void
  showPreview?: boolean
}

export function QuotationGrid({
  onExpandPreview,
  showPreview = true,
}: IQuotationGridProps) {
  const { id, image, title } = TEMPLATES_INFOS.QUOTATION_GRID

  return (
    <TemplateSelector
      data-testid={TEMPLATE_QUOTATION_GRID_ID}
      name={TEMPLATES_NAME}
      image={image}
      title={title}
      value={id}
      onExpandPreview={onExpandPreview}
      showPreview={showPreview}
    />
  )
}
