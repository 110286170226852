import { Badge, type IBadgeProps } from '@77sol-ui/atoms'
import { ScrollTable } from '@77sol-ui/molecules'

import { type IFinancing } from 'domains/home/dashboard-financing-requests/services/types'
import { formatDate, parseBRL } from 'utils'
import { useFinancingStatusRequestBadge } from './hooks/useFinancingRequestStatus'
import { useHistory } from 'react-router-dom'
import { Row } from './styles'
import { useAmplitude } from 'hooks/useAmplitude'
import { tableFiltersTrackers } from 'services/tracker/events/tableFilters/trackers'
import { formatDoc } from 'utils/format'

export interface IFinancingRequestItemProps {
  financing: IFinancing
}

export function FinancingItem({ financing }: IFinancingRequestItemProps) {
  const history = useHistory()
  const { logEvent } = useAmplitude()

  const status = useFinancingStatusRequestBadge(financing.status)

  function onRedirectToFinancing() {
    logEvent(tableFiltersTrackers.actions.rowClick, {
      origin: '/dashboard',
      table: 'Financiamentos',
    })
    history.push(`/financiamentos/${financing.id}`)
  }

  return (
    <Row
      key={financing.id}
      data-testid="financing-table-row"
      role="button"
      onClick={onRedirectToFinancing}
    >
      <ScrollTable.Td pin>#{financing.id}</ScrollTable.Td>
      <ScrollTable.Td truncate>{financing.requester_name}</ScrollTable.Td>
      <ScrollTable.Td>{formatDoc(financing.requester_doc)}</ScrollTable.Td>
      <ScrollTable.Td>{parseBRL(financing.value)} </ScrollTable.Td>
      <ScrollTable.Td>
        <Badge
          variant="filled"
          color={status.color as IBadgeProps['color']}
          text={status.text}
        />
      </ScrollTable.Td>
      <ScrollTable.Td>{formatDate(financing.created_at, true)}</ScrollTable.Td>
    </Row>
  )
}
