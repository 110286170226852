export enum FINANCING_REQUEST_STATUS_ENUM {
  SOLICITADO = 1,
  PRE_APROVADO = 2,
  NEGADO = 4,
  CANCELADO = 5,
  CONCLUIDO = 6,
  CREDITO_APROVADO = 7,
  COM_PENDENCIAS = 8,
  EM_ANALISE = 9,
}
