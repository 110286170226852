import { colors } from '@77sol-ui/tokens'

export const dropZoneStyles = (image) => {
  return {
    dropzone: {
      padding: '30px',
      border: image ? 'none' : `1px dashed ${colors.blue[200]}`,
      flexGrow: 1,
      alignSelf: 'stretch',
      borderRadius: '8px',
      flexDirection: 'column',
      overflow: 'hidden',
      color: '#1335c6',
      width: '100%',
      height: image ? '100%' : 'auto',
    },
    preview: {
      border: 'none',
    },
    input: {
      display: 'none',
    },
    inputLabel: {
      color: '#1335c6',
      fontSize: '16px',
      textAlign: 'center',
      padding: image ? '0px' : '16px',
    },
  }
}
