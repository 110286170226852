import { Document, type DocumentProps, Page, type PageProps } from 'react-pdf'

import { pdfjs } from 'react-pdf'

import {
  ButtonCircle,
  Container,
  Header,
  Wrapper,
  Content,
  Text,
  ActionsGroup,
  ScrollArea,
} from './styles'
import {
  ChevronDown,
  ChevronUp,
  Expand,
  ScanSearch,
  Shrink,
  ZoomIn,
  ZoomOut,
} from 'lucide-react'
import { CircularProgress } from 'components/CircularProgress'
import { ErrorState } from 'components/ErrorState'
import { useRef } from 'react'

import { usePaginate } from './hooks/usePaginate'
import { useZoom } from './hooks/useZoom'
import { Tooltip } from 'components/Tooltip'
import { useCalculateWidth } from './hooks/useCalculateWidth'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString()

export interface IPDFViewerProps {
  url: string
  headerText?: string
  documentProps?: DocumentProps
  pageProps?: PageProps
  onChangedPage?: (from: number, to: number) => void
}

export function PDFViewer({
  url,
  headerText,
  documentProps,
  pageProps,
  onChangedPage,
}: IPDFViewerProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const pageRef = useRef<HTMLCanvasElement>(null)

  const {
    fit,
    scale,
    handleZoomIn,
    handleZoomOut,
    handleResetZoom,
    handleToggleFit,
  } = useZoom({
    defaultScale: 1,
  })

  const {
    currentPage,
    handleNextPage,
    handlePreviousPage,
    totalPages,
    handleSetTotalPages,
  } = usePaginate({
    onChangedPage,
  })

  const { handleCalculateWidth, pageWidth } = useCalculateWidth()

  return (
    <Wrapper>
      <Content>
        <Header>
          <div className="header__left">
            <Tooltip variant="white" content="Página anterior">
              <ButtonCircle
                disabled={currentPage === 1}
                onClick={() => {
                  handlePreviousPage()
                }}
              >
                <ChevronUp />
              </ButtonCircle>
            </Tooltip>

            <Text hidden={totalPages === 0}>
              {currentPage} de {totalPages}
            </Text>

            <Tooltip variant="white" content="Proxima página">
              <ButtonCircle
                disabled={currentPage === totalPages}
                onClick={() => {
                  handleNextPage()
                }}
              >
                <ChevronDown />
              </ButtonCircle>
            </Tooltip>
          </div>

          <div className="header__center">
            {headerText && <Text>{headerText}</Text>}
          </div>

          <div className="header__right">
            <ActionsGroup>
              <Tooltip
                variant="white"
                content={fit ? 'Ajustar à largura' : 'Ajustar à página'}
              >
                <ButtonCircle
                  onClick={() => {
                    handleToggleFit({
                      container: containerRef.current,
                      canvas: pageRef.current,
                    })
                  }}
                >
                  {fit ? <Expand /> : <Shrink />}
                </ButtonCircle>
              </Tooltip>
            </ActionsGroup>
            <Tooltip variant="white" content="Diminuir Zoom">
              <ButtonCircle
                disabled={scale <= 0.3}
                onClick={() => {
                  handleZoomOut(0.1)
                }}
              >
                <ZoomOut />
              </ButtonCircle>
            </Tooltip>
            <Tooltip variant="white" content="Redefinir Zoom">
              <ButtonCircle onClick={handleResetZoom}>
                <ScanSearch />
              </ButtonCircle>
            </Tooltip>

            <Tooltip variant="white" content="Aumentar Zoom">
              <ButtonCircle
                disabled={scale >= 5}
                onClick={() => {
                  handleZoomIn(0.1)
                }}
              >
                <ZoomIn />
              </ButtonCircle>
            </Tooltip>
          </div>
        </Header>
        <ScrollArea>
          <Container ref={containerRef}>
            <Document
              file={url}
              loading={
                <CircularProgress
                  text="Carregando..."
                  className="delay-to-appear"
                />
              }
              error={
                <ErrorState
                  data-testid="error-react-pdf-viewer"
                  title="Ops! Algo deu errado ao carregar o PDF."
                  subtitle="Não foi possível carregar o visualidor de PDF."
                  showImage
                />
              }
              {...documentProps}
              onLoadSuccess={(e) => {
                handleSetTotalPages(e.numPages)

                documentProps?.onLoadSuccess?.(e)
              }}
            >
              <Page
                canvasRef={pageRef}
                pageNumber={currentPage}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                loading={
                  <CircularProgress
                    text="Carregando..."
                    className="delay-to-appear"
                  />
                }
                scale={scale}
                error={
                  <ErrorState
                    id="error-container"
                    data-testid="error-react-pdf-viewer"
                    title="Ops! Algo deu errado ao carregar o PDF."
                    subtitle="Não foi possível carregar o visualidor de PDF."
                    showImage
                  />
                }
                width={pageWidth}
                {...pageProps}
                onLoadSuccess={(...args) => {
                  if (!fit) {
                    handleCalculateWidth(containerRef.current, pageRef.current)
                  }
                  pageProps?.onLoadSuccess?.(...args)
                }}
              />
            </Document>
          </Container>
        </ScrollArea>
      </Content>
    </Wrapper>
  )
}
