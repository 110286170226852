import { useState } from 'react'
import { Grid, Tooltip, useMediaQuery } from '@material-ui/core'
import { Typography } from 'components'
import { Spacer, Paper } from '@77sol/core'
import palette from 'app_palette'
import { EditIcon, DeleteIcon } from '@77sol/icons/dist'
import CircularProgress from '@material-ui/core/CircularProgress'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import { Alert } from '@77sol-ui/atoms'
import * as S from './styles'
import UploadIcon from './UploadIcon'
import { dropZoneStyles } from './dropzoneStyles'

function WorkImagesEdit(props) {
  const {
    imagens,
    handleRemoveImage,
    handleSaveWorkImages,
    handleEditImage,
    noPadding,
    loading,
  } = props

  const [editImage, setEditImage] = useState('')

  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const handleChangeStatus = ({ file, remove }, status) => {
    if (status === 'preparing') {
      remove()
      if (editImage) {
        if (handleEditImage) {
          handleEditImage(file, editImage)
        } else {
          handleSaveWorkImages(file)
        }
        setEditImage('')
      } else {
        handleSaveWorkImages(file)
      }
    }
  }

  function ImagePreview(index) {
    return (
      <div
        style={{
          backgroundImage: `url(${imagens[index]})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <Paper
          style={{
            position: 'absolute',
            bottom: '8px',
            right: '8px',
            display: 'flex',
            width: 'auto',
            height: 'auto',
            padding: '8px',
          }}
        >
          <Tooltip title="Alterar">
            <EditIcon
              width="24"
              color={palette.grayscale[800]}
              onClick={() => {
                setEditImage(imagens[index])
              }}
            />
          </Tooltip>
          <Spacer size="16" direction="vertical" />
          <Tooltip title="Remover">
            <DeleteIcon
              onClick={(e) => {
                handleRemoveImage(imagens[index])
                e.preventDefault()
                e.stopPropagation()
              }}
              width="24"
              color={palette.red[300]}
            />
          </Tooltip>
        </Paper>
      </div>
    )
  }

  const tamanho = Math.min(imagens.length + 1, 6)
  const imageSlots = Array.from(Array(tamanho).keys())

  return (
    <div style={{ padding: noPadding ? '0px' : '0px 32px' }}>
      <S.HeaderContainer>
        <Typography
          type={downSm ? 'text_small' : 'text_medium'}
          color="grayscale"
          colorWeight="700"
        >
          Insira imagens dos seus projetos passados
        </Typography>
        <Typography type="text_x_small" color="grayscale" colorWeight="500">
          Deixe seu portfólio pronto para a hora de gerar nova proposta
          selecionar as imagens desejadas
        </Typography>
      </S.HeaderContainer>
      <S.AlertContainer className="context">
        <Alert.Root rounded variant="info">
          <Alert.Content alignment="horizontal">
            <Alert.Icon variant="info" />
            <Alert.Description description="São suportados apenas arquivos em PNG ou JPEG de até 10MB." />
          </Alert.Content>
        </Alert.Root>
      </S.AlertContainer>
      <Spacer size="16" direction="horizontal" />
      <Grid spacing={2} container>
        {imageSlots.map((_image, i) => (
          <Grid item xs={12} md={6} lg={6} xl={6} key={i}>
            <Paper
              style={{
                display: 'flex',
                width: '100%',
                height: '220px',
                alignItems: 'center',
                padding: '0px',
              }}
            >
              <Dropzone
                maxFiles={1}
                onChangeStatus={handleChangeStatus}
                multiple={false}
                PreviewComponent={(props) => (
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress
                      disableShrink
                      style={{ color: palette.primary[300] }}
                      size={30}
                      thickness={4}
                    />
                  </div>
                )}
                inputContent={
                  imagens && imagens[i] ? (
                    ImagePreview(i)
                  ) : loading ? (
                    <div
                      style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <CircularProgress
                        disableShrink
                        style={{ color: palette.primary[300] }}
                        size={30}
                        thickness={4}
                      />
                    </div>
                  ) : (
                    <S.DropzoneContent>
                      <UploadIcon />
                      <h3>Clique para fazer o upload da imagem</h3>
                      <span>PNG ou JPG (max. 10MB)</span>
                    </S.DropzoneContent>
                  )
                }
                accept="image/*"
                styles={dropZoneStyles(imagens[i])}
              />
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default WorkImagesEdit
