import {
  PDFTemplatesSelector,
  type IPDFTemplatesSelector,
} from 'containers/PDFTemplatesSelector'
import React from 'react'
import * as S from './styles'
import { Content } from '../../styles'

interface IChooseTemplateProps extends IPDFTemplatesSelector {}

export const ChooseTemplate: React.FC<IChooseTemplateProps> = (props) => {
  return (
    <Content>
      <S.ChooseTemplateStepWrapper>
        <PDFTemplatesSelector {...props} noWrap showPreview={false} />
      </S.ChooseTemplateStepWrapper>
    </Content>
  )
}
