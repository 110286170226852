import { Button } from '@77sol-ui/atoms'
import { Modal } from '@77sol-ui/molecules'
import { useModalExportPDFSteps } from './hooks/useModalExportPDFSteps'
import { useReadUserSettings } from 'domains/pdf-express/read-user-settings/hooks/useReadUserSettings'
import { useUserId } from 'hooks/useUserId'
import { useGetDimensioningQuotation } from 'domains/dimensioning/hooks/useGetDimensioningQuotations'
import { QuotationAlert } from './components/QuotationAlert'
import * as S from './styles'
import { useEffect } from 'react'
import { handleRemoveQueries } from './utils/handleRemoveQueries'
import { useAmplitude } from 'hooks/useAmplitude'
import { proposalTracker } from 'services/tracker/events/proposal/trackers'

export interface IModalExportPDFProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  dimensioningId: string
}

export function ModalExportPDF({
  open,
  onOpenChange,
  dimensioningId,
}: IModalExportPDFProps) {
  const userId = useUserId()
  const { logEvent } = useAmplitude()
  const {
    data: dimensioningData,
    isLoading,
    isError,
  } = useGetDimensioningQuotation({
    dimensioningId,
  })

  const { data } = useReadUserSettings({ userId })

  const {
    handleNextStep,
    handlePrevStep,
    stepToRender,
    PDFData,
    currentStep,
    okDisabled,
    cancelDisabled,
    revokeURLS,
  } = useModalExportPDFSteps({
    userSettingsData: data,
    dimensioningDataIsLoading: isLoading,
    dimensioningDataIsError: isError,
    onOpenChange,
    dimensioningData,
  })

  useEffect(() => {
    return () => {
      handleRemoveQueries()
      revokeURLS()
    }
  }, [])

  const showAlert = PDFData.selectedQuotations.length > 1 && currentStep === 1

  const handleOpenConfigProposal = () => {
    logEvent(proposalTracker.actions.accessCustomizeProposal, {
      origin: 'Modal Proposta PDF',
    })
  }

  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      <Modal.Portal>
        <Modal.Overlay />
        <S.ModalWrapper
          data-step={currentStep}
          maxHeight={stepToRender.modalHeight}
          maxWidth={stepToRender.modalWidth}
        >
          <Modal.Close />
          <Modal.Header>
            <Modal.Icon icon={stepToRender.icon} size={24} />
            <Modal.Title asChild>
              <span>{stepToRender.title}</span>
            </Modal.Title>
            <S.Description>
              {typeof stepToRender.description === 'function'
                ? stepToRender.description(handleOpenConfigProposal)
                : stepToRender.description}
            </S.Description>
          </Modal.Header>
          <Modal.Content>{stepToRender.content}</Modal.Content>
          {showAlert && <QuotationAlert />}
          <Modal.Footer>
            <S.FooterButtonsContainer>
              <Button
                fullWidth
                variant="outline"
                onClick={handlePrevStep}
                disabled={cancelDisabled}
              >
                {stepToRender.cancelText}
              </Button>
              <Button
                onClick={handleNextStep}
                fullWidth
                data-testid="nextButton"
                disabled={okDisabled}
                isLoading={stepToRender.okIsLoading}
              >
                {stepToRender.okText}
              </Button>
            </S.FooterButtonsContainer>
          </Modal.Footer>
        </S.ModalWrapper>
      </Modal.Portal>
    </Modal.Root>
  )
}
