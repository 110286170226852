type PersistStorageData = string[]

export function clearStorage(
  persistData: PersistStorageData = [
    '@77sol-admin:expiressRankingModal',
    'dateViewFreePlanModal',
  ],
  prefixs: string[] = ['news-'],
) {
  Object.keys(localStorage).forEach((key) => {
    if (
      !persistData.includes(key) &&
      !prefixs.some((prefix) => key.startsWith(prefix))
    ) {
      localStorage.removeItem(key)
    }
  })
}
