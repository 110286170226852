import { useCallback, useState } from 'react'
import {
  type DefaultValuesForm,
  type ICreateFinancingPerQuotationFormData,
} from '../components/FormManager/types'
import { useUserHasFullRegistration } from 'hooks/useUserHasFullRegistration'
import { useFinancingCreateSimulation } from 'domains/financing/create-simulation'
import { type INewFinancingSimulationPOSTPayload } from 'domains/financing/create-simulation/services/types'
import { useSuccessFinancingRequestModalContext } from 'context/modals/financing/SuccessFinancingRequestModalContext'
import { useAmplitude } from 'hooks/useAmplitude'
import { financingTracker } from 'services/tracker/events/financing/trackers'
import { format } from 'date-fns'
import { FINANCING_TYPE, INICIAL_VALUES_FORM } from '../constants'
import { PERSON_TYPE_ENUM } from 'enums/PersonTypeEnum'
import { useCreateFinancingPerQuotationModalStore } from '../store'
import { queryClient } from 'services/queryClient'
import { QUERY_KEY_PROPOSAL_QUOTATIONS_ALL } from 'domains/proposal/quotations_all/constants/endpointKeys'
import { calculateClientYearsOld } from 'containers/Financing/helpers/calculateClientYearsOld'
import { INSURANCE_YEARS_OLD_LIMIT } from 'containers/Financing/constants'

export function useCreateFinancingPerQuotationModal() {
  const { mutateAsync, isError, isLoading } = useFinancingCreateSimulation()
  const { validateUserRegistration } = useUserHasFullRegistration()

  const [requestFallback, setRequestFallback] = useState<
    DefaultValuesForm | ICreateFinancingPerQuotationFormData
  >(INICIAL_VALUES_FORM)

  const setFinancingPerQuotationModalClose =
    useCreateFinancingPerQuotationModalStore(
      (state) => state.setFinancingPerQuotationModalClose,
    )

  const { setSuccessFinancingModalOpen } =
    useSuccessFinancingRequestModalContext()

  const { logEvent } = useAmplitude()

  const manageRequestFallback = useCallback(
    (data: ICreateFinancingPerQuotationFormData) => {
      const { client_birth_date, ...rest } = data

      setRequestFallback({
        ...rest,
        client_birth_date: format(client_birth_date as Date, 'dd-MM-yyyy'),
      })
    },
    [],
  )

  const handleCreateFinancingPerQuotationRequest = useCallback(
    async (data: ICreateFinancingPerQuotationFormData, projectID: number) => {
      manageRequestFallback(data)

      const {
        client_birth_date,
        client_type,
        client_income,
        input_value,
        project_value,
        is_insurance = false,
        ...rest
      } = data

      const clientType =
        client_type === PERSON_TYPE_ENUM.NATURAL
          ? 'Pessoa Física'
          : 'Pessoa Jurídica'

      logEvent(financingTracker.actions.financingCreated, {
        financingType: 'Cotação',
        clientType,
      })

      if (!validateUserRegistration()) return

      const { yearsOld: clientYearsOld } = calculateClientYearsOld(
        format(client_birth_date as Date, 'dd/MM/yyyy'),
      )
      const payload = {
        ...rest,
        client_type,
        client_birth_date: format(client_birth_date as Date, 'yyyy-MM-dd'),
        origin: FINANCING_TYPE,
        origin_id: projectID,
        client_income: client_income.toFixed(2),
        input_value: input_value.toFixed(2),
        total_value: project_value ? project_value - input_value : 0,
        ...(client_type === PERSON_TYPE_ENUM.NATURAL && {
          is_insurance,
        }),
        ...(clientYearsOld > INSURANCE_YEARS_OLD_LIMIT && {
          is_insurance: false,
        }),
      }

      mutateAsync(payload as INewFinancingSimulationPOSTPayload).then(
        async ({ origin_id }) => {
          setFinancingPerQuotationModalClose()
          setSuccessFinancingModalOpen(true, origin_id)
          await queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_PROPOSAL_QUOTATIONS_ALL],
          })
        },
      )
    },
    [
      logEvent,
      mutateAsync,
      manageRequestFallback,
      validateUserRegistration,
      setSuccessFinancingModalOpen,
      setFinancingPerQuotationModalClose,
    ],
  )

  return {
    isError,
    isLoading,
    handleCreateFinancingPerQuotationRequest,
    requestFallback,
  }
}
