import { Modal } from '@77sol-ui/molecules'
import { fontFamily, fontSize } from '@77sol-ui/tokens'
import { BREAKPOINTS } from 'constants/breakpoints'
import styled from 'styled-components'

export const Paragraph = styled.p`
  font-family: ${fontFamily.sans[0]};

  font-size: ${fontSize.sm};

  margin-bottom: 24px;
  @media (min-width: ${BREAKPOINTS.sm}) {
    font-size: ${fontSize.base};
  }
`

export const ModalTitle = styled(Modal.Title)`
  font-size: ${fontSize.sm};

  @media (min-width: ${BREAKPOINTS.xs}) {
    font-size: ${fontSize.base};
  }

  @media (min-width: ${BREAKPOINTS.sm}) {
    font-size: ${fontSize.lg};
  }
`
