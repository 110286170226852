export function calculateScale(
  elementWidth: number,
  elementHeight: number,
  containerWidth: number,
  containerHeight: number,
): number {
  const scaleX = containerWidth / elementWidth
  const scaleY = containerHeight / elementHeight

  return Math.min(scaleX, scaleY)
}
