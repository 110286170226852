import {
  type ITooltipContentProps,
  type ITooltipProviderProps,
  Tooltip as PrimitiveTooltip,
} from '@77sol-ui/molecules'

import React, { useCallback, useState } from 'react'

interface ITooltipProps extends Omit<ITooltipContentProps, 'content'> {
  children: React.ReactNode
  delayDuration?: ITooltipProviderProps['delayDuration']
  showArrow?: boolean
  content: React.ReactNode
}

export function Tooltip({
  children,
  side = 'bottom',
  sideOffset = 10,
  align = 'center',
  maxWidth = '90dvw',
  textAlign = 'start',
  variant = 'blue',
  delayDuration = 50,
  showArrow = true,
  content,
  ...props
}: ITooltipProps) {
  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (open) {
        e.preventDefault()
      }
      setOpen(true)
    },
    [open],
  )

  const handleClose = useCallback(
    (event: Event) => {
      if (!open) {
        event.preventDefault()
      }
      setOpen(false)
    },
    [open],
  )

  return (
    <PrimitiveTooltip.Provider delayDuration={delayDuration}>
      <PrimitiveTooltip.Root>
        <PrimitiveTooltip.Trigger onClick={handleOpen} asChild>
          {children}
        </PrimitiveTooltip.Trigger>

        <PrimitiveTooltip.Portal>
          <PrimitiveTooltip.Content
            align={align}
            maxWidth={maxWidth}
            side={side}
            sideOffset={sideOffset}
            textAlign={textAlign}
            variant={variant}
            {...props}
            onPointerDownOutside={handleClose}
          >
            {content}
            {showArrow && <PrimitiveTooltip.Arrow variant={variant} />}
          </PrimitiveTooltip.Content>
        </PrimitiveTooltip.Portal>
      </PrimitiveTooltip.Root>
    </PrimitiveTooltip.Provider>
  )
}
