import { add, format } from 'date-fns'
import { Modal } from 'components/Modal'
import { Check } from 'lucide-react'
import { AlertWithTitle } from 'components/AlertWithTitle'
import { Stepper } from '@77sol-ui/molecules'
import { Button } from '@77sol-ui/atoms'
import { useFooterActions } from './hooks/useFooterActions'
import * as S from './styles'

export interface ISuccessOrderModalProps {
  isOpen: boolean
  orderId: number
  paymentLink: string | null
  paymentTypeSlug: string
  onClose: () => void
}

export function SuccessOrderModal({
  orderId,
  paymentLink,
  paymentTypeSlug,
  onClose,
}: ISuccessOrderModalProps): JSX.Element {
  const expirationDate = format(add(new Date(), { days: 2 }), 'dd/MM/yyyy')
  const footer = useFooterActions({
    orderId,
    paymentLink,
    paymentTypeSlug,
    onClose,
  })
  const offset = 20

  const handleCloseModal = () => {
    onClose()
    footer.handleExit()
  }

  return (
    <Modal.Root open>
      <Modal.Portal>
        <Modal.Overlay />
        <S.Wrapper id="modal-cancel-proposal">
          <Modal.Header>
            <Modal.Close onClick={handleCloseModal} />
            <Modal.Icon icon={Check} />
            <Modal.Title>Sua reserva foi confirmada!</Modal.Title>
            <S.HeaderDescription>
              Sua reserva é válida até o dia {expirationDate}.{' '}
              {footer.descriptionContent}
            </S.HeaderDescription>
          </Modal.Header>
          <Modal.Content>
            <S.SucessDefaultContainer>
              <AlertWithTitle
                withButton={false}
                variant="info"
                description="O prazo de entrega começa após a confirmação do pagamento ou disponibilidade dos equipamentos, caso existam itens com disponibilidade futura (sujeito a atualização devido ao fluxo de importação)."
              />
              <div>
                <span className="steppetTitle">
                  Todas as reservas passam pelas seguintes etapas:
                </span>
                <div className="overflow-container">
                  <S.StepperContainer>
                    <Stepper.Root direction="horizontal">
                      <Stepper.Step active align="center" offset={offset}>
                        <span>Pedido reservado</span>
                      </Stepper.Step>
                      <Stepper.Connector />
                      <Stepper.Step align="center" offset={offset}>
                        <span>Análise de pagamento</span>
                      </Stepper.Step>
                      <Stepper.Connector />
                      <Stepper.Step align="center" offset={offset}>
                        <span>Pagamento confirmado</span>
                      </Stepper.Step>
                      <Stepper.Connector />
                      <Stepper.Step align="center" offset={offset}>
                        <span>Expedição</span>
                      </Stepper.Step>
                      <Stepper.Connector />
                      <Stepper.Step align="center" offset={offset}>
                        <span>Transporte</span>
                      </Stepper.Step>
                      <Stepper.Connector />
                      <Stepper.Step align="center" offset={offset}>
                        <span>Entrega realizada</span>
                      </Stepper.Step>
                    </Stepper.Root>
                  </S.StepperContainer>
                </div>
              </div>

              <Modal.Footer>
                <Button fullWidth variant="outline" onClick={footer.handleExit}>
                  {footer.exitText}
                </Button>
                <Button fullWidth onClick={footer.handleContinue}>
                  {footer.continueText}
                </Button>
              </Modal.Footer>
            </S.SucessDefaultContainer>
          </Modal.Content>
        </S.Wrapper>
      </Modal.Portal>
    </Modal.Root>
  )
}
