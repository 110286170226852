import { type IResponseGetFinancingRequestsData } from 'domains/home/dashboard-financing-requests/services/types'
import { ScrollTable } from '@77sol-ui/molecules'
import { Button } from '@77sol-ui/atoms'
import { useFinancingRedirect } from 'views/Financing/hooks/useFinancingRedirect'
import { EmptyState } from './components/EmptyState'
import { ListSkeleton } from '../Skeleton'
import { FinancingItem } from './components/FinancingRequestItem'
import { Wrapper } from './styles'
import { useAmplitude } from 'hooks/useAmplitude'
import { tableFiltersTrackers } from 'services/tracker/events/tableFilters/trackers'
import { queryClient } from 'services/queryClient'
import { DASHBOARD_FINANCING_REQUESTS } from 'domains/home/dashboard-financing-requests/constants/endpointKeys'
import { ErrorState } from '../ErrorState'

export interface IFinancingListProps {
  financings?: IResponseGetFinancingRequestsData['financings']
  isLoading?: boolean
  isError?: boolean
  alreadyCreatedFinancing?: boolean
  filterApplied?: boolean
  numberOfRows?: number
}

export function FinancingList({
  financings,
  alreadyCreatedFinancing,
  isLoading,
  filterApplied,
  numberOfRows = 0,
  isError,
}: IFinancingListProps) {
  const { logEvent } = useAmplitude()
  const { redirectFinancingList } = useFinancingRedirect()

  const hasFinancings = financings && financings.length > 0 && !isLoading

  function onSeeAllFinancings() {
    logEvent(tableFiltersTrackers.actions.seeAll, {
      origin: '/dashboard',
      table: 'Financiamentos',
    })
    redirectFinancingList()
  }

  const retryFetchData = async () => {
    await queryClient.refetchQueries({
      queryKey: [DASHBOARD_FINANCING_REQUESTS],
    })
  }

  return (
    <Wrapper numberOfRows={numberOfRows} data-testid="financing-list">
      <ScrollTable.ScrollArea className="table-scroll-area">
        <ScrollTable.Table className="table">
          <ScrollTable.Head>
            <ScrollTable.Tr>
              <ScrollTable.Th pin>ID</ScrollTable.Th>
              <ScrollTable.Th>Nome do Solicitante</ScrollTable.Th>
              <ScrollTable.Th>Documento</ScrollTable.Th>
              <ScrollTable.Th>Valor </ScrollTable.Th>
              <ScrollTable.Th>Status</ScrollTable.Th>
              <ScrollTable.Th>Data da Solicitação</ScrollTable.Th>
            </ScrollTable.Tr>
          </ScrollTable.Head>
          <ScrollTable.Body>
            {isLoading && <ListSkeleton numberOfRows={numberOfRows} />}

            {hasFinancings &&
              financings?.map((financing) => (
                <FinancingItem key={financing.id} financing={financing} />
              ))}
          </ScrollTable.Body>
        </ScrollTable.Table>
      </ScrollTable.ScrollArea>

      {hasFinancings && (
        <ScrollTable.Footer className="table-footer" justify="center">
          <Button variant="outline" onClick={onSeeAllFinancings}>
            Ver tudo
          </Button>
        </ScrollTable.Footer>
      )}

      {isError && !hasFinancings && !isLoading && (
        <ErrorState
          title="Ocorreu um erro ao listar os financiamentos."
          subtitle="Atualize a página e tente novamente."
          buttonText="Tentar novamente"
          numberOfRows={numberOfRows}
          onButtonAction={retryFetchData}
        />
      )}

      {!isError && !hasFinancings && !isLoading && (
        <EmptyState
          filterApplied={filterApplied}
          alreadyCreatedFinancing={alreadyCreatedFinancing}
        />
      )}
    </Wrapper>
  )
}
