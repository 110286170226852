import { useQueries, type UseQueryOptions } from '@tanstack/react-query'
import { proposalListBudgets } from '../services/api'
import { PROPOSAL_LIST_BUDGETS } from '../constants/endpointKeys'

import {
  type IProposalListBudgetsResponse,
  type IProposalListBudgetsParamsAll,
} from '../services/types'

type returnQueryType = Array<{
  data: IProposalListBudgetsResponse[] | undefined
  error: unknown
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
}>

export function useProposalListBudgetsAll({
  quotationIds,
  ...queryOptions
}: IProposalListBudgetsParamsAll & UseQueryOptions) {
  return useQueries({
    queries: quotationIds.map((quotationId) => ({
      queryKey: [`${PROPOSAL_LIST_BUDGETS}`, quotationId],
      queryFn: async () => await proposalListBudgets({ quotationId }),
      ...queryOptions,
    })),
  }) as returnQueryType
}
