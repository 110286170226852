import { useCallback, useMemo } from 'react'

interface queryProps {
  isLoading: boolean
  isError: boolean
  refetch: () => void
  data: any
}
export interface IQueries {
  roiQueries: queryProps[]
  budgetQueries: queryProps[]
  paymentMethodsQuery: queryProps
  clienteViewQuery: queryProps
}

export const useBindQuotationQueries = ({
  queries,
  selectedQuotationIndex,
}: {
  queries: IQueries
  selectedQuotationIndex: number
}) => {
  const roi = useMemo(
    () => queries.roiQueries[selectedQuotationIndex],
    [queries.roiQueries, selectedQuotationIndex],
  )

  const budgets = useMemo(
    () => queries.budgetQueries[selectedQuotationIndex],
    [queries.budgetQueries, selectedQuotationIndex],
  )

  const paymentMethods = useMemo(
    () => queries.paymentMethodsQuery,
    [queries.paymentMethodsQuery],
  )

  const customer = useMemo(
    () => queries.clienteViewQuery,
    [queries.clienteViewQuery],
  )

  const allQueries = useMemo(
    () => [roi, budgets, paymentMethods, customer],
    [budgets, customer, paymentMethods, roi],
  )

  const isError = allQueries.some((item) => item?.isError)
  const isLoading = allQueries.some((item) => item?.isLoading)

  const [roiData, budgetsData, paymentMethodsData, customerData] = useMemo(
    () => allQueries.map((query) => query.data),
    [allQueries],
  )

  const refetchAll = useCallback(() => {
    if (!isError) return

    if (roi.isError) roi.refetch()
    if (budgets.isError) budgets.refetch()
    if (paymentMethods.isError) paymentMethods.refetch()
    if (customer.isError) customer.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isError,
    budgets.isError,
    customer.isError,
    paymentMethods.isError,
    roi.isError,
    roi.refetch,
    budgets.refetch,
    customer.refetch,
    paymentMethods.refetch,
  ])

  return {
    isLoading,
    data: { roiData, budgetsData, paymentMethodsData, customerData },
    refetchAll,
  }
}
