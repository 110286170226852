import { useEffect } from 'react'
import { SLIDE_CONTENT_ENUM } from 'views/Financing/enums/simulationSlide'
import { useFinancingSimulationsSlideStore } from 'views/Financing/stores/financingSimulationsSlideStore'
import { useCheckoutModalStore } from 'store/modals/CheckoutModalStore'
import { useOrdersData } from './useOrdersData'

export function usePayCreatedOrder(fetchOrders = true) {
  const { orderSelected } = useOrdersData(fetchOrders)
  const { setFinancingId, setSimulationId } = useCheckoutModalStore()
  const { financingId, simulationId, setOrderId, setSlideContent } =
    useFinancingSimulationsSlideStore()

  const isPayingOrder = Boolean(orderSelected)

  function startOrderPayment() {
    setFinancingId(financingId)
    setSimulationId(simulationId)
  }

  function closeOrderPayment() {
    setOrderId(null)
  }

  function payOrder(id: number) {
    setOrderId(id)
  }

  useEffect(() => {
    if (isPayingOrder) {
      setSlideContent(SLIDE_CONTENT_ENUM.SELECT_ORDER)
    }
  }, [isPayingOrder, setSlideContent])

  return {
    startOrderPayment,
    closeOrderPayment,
    payOrder,
  }
}
