import { Table } from '@77sol-ui/molecules'
import { colors, fontFamily } from '@77sol-ui/tokens'
import type React from 'react'
import styled from 'styled-components'

interface StyledTableCellProps {
  align?: React.CSSProperties['textAlign']
  paddingLeft?: React.CSSProperties['paddingLeft']
}

export const IdCheckBoxContainer = styled.div`
  display: flex;
  gap: 12px;
`

export const StyledTableCell = styled(Table.Cell)<StyledTableCellProps>`
  text-align: ${({ align = 'start' }) => align};
  > span {
    padding-left: ${({ paddingLeft = '0px' }) => paddingLeft};
    color: ${colors.blue['800']};
  }
`

export const StyledTableHead = styled(Table.Head)`
  color: ${colors.gray['600']};
`

export const StyledTableRoot = styled(Table.Root)`
  * {
    font-family: ${fontFamily.sans.toString()};
    font-weight: 500;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;

  & .loadingContainer {
    height: 120px;
  }
`
