import { type IQuotationDTO } from 'dto/QuotationDTO'
import { create } from 'zustand'
import { type QUOTATION_PDF_TEMPLATE_ID_ENUM } from 'enums/quotationPDF/QuotationPDFEnum'
import { type IPDFTemplatesPreviewProps } from 'modules/PDF/PDFTemplateRender'

export interface IFileToDownloadType {
  file: Blob
  quotationId: number
}
export interface IQuotationPDFData
  extends Omit<
    IPDFTemplatesPreviewProps,
    'userSettings' | 'userLogged' | 'proposal' | 'dimensioningProps'
  > {
  quotationId: number
}

const INITIAL_PDF_DATA = {
  template: null,
  selectedQuotations: [],
  quotationsPDFBuilt: [],
  pdfsToDownload: [],
  allPDFsLoaded: false,
  firstPDFPreviewLoaded: false,
}

interface IPDFDataStoreProps {
  template: string | null
  selectedQuotations: IQuotationDTO[]
  quotationsPDFBuilt: IQuotationPDFData[]
  allPDFsLoaded: boolean
  firstPDFPreviewLoaded: boolean
  setSelectedQuotations: (quotation: IQuotationDTO[]) => void
  setQuotationsPDFBuilt: (quotationsPDFBuilt: IQuotationPDFData[]) => void
  setTemplate: (templateId: QUOTATION_PDF_TEMPLATE_ID_ENUM) => void
  setAllPDFsLoaded: (allPDFsLoaded: boolean) => void
  reset: () => void
  setfirstPDFPreviewLoaded: (value: boolean) => void
}

export const usePDFDataStore = create<IPDFDataStoreProps>((set) => ({
  ...INITIAL_PDF_DATA,
  setTemplate: (templateId: QUOTATION_PDF_TEMPLATE_ID_ENUM) => {
    set({ template: templateId })
  },
  setSelectedQuotations: (selectedQuotations: IQuotationDTO[]) => {
    set({
      selectedQuotations,
    })
  },
  setQuotationsPDFBuilt: (quotationsPDFBuilt: IQuotationPDFData[]) => {
    set({
      quotationsPDFBuilt,
    })
  },
  setAllPDFsLoaded: (allPDFsLoaded: boolean) => {
    set({
      allPDFsLoaded,
    })
  },
  setfirstPDFPreviewLoaded: (value) => {
    set({
      firstPDFPreviewLoaded: value,
    })
  },
  reset: () => {
    set({
      ...INITIAL_PDF_DATA,
    })
  },
}))
