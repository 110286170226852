import { PROPOSAL_QUOTATION_ROI_CALCULATE } from '../constants/endpointKeys'
import api from 'api'
import {
  type IProposalQuotationRoiCalculateParams,
  type IProposalQuotationRoiCalculateResponse,
} from './types'

export const proposalQuotationRoiCalculate = async ({
  quotationId,
}: IProposalQuotationRoiCalculateParams) => {
  return await api
    .get<IProposalQuotationRoiCalculateResponse>(
      `${PROPOSAL_QUOTATION_ROI_CALCULATE}/${quotationId}`,
    )
    .then(({ data }) => data)
}
