interface InputData {
  billets?: {
    id: number
    quotation_id: number
    billet_one: string | null
    label_billet_one: string | null
    billet_two: string | null
    label_billet_two: string | null
    billet_three: string | null
    label_billet_three: string | null
    billet_four: string | null
    label_billet_four: string | null
    created_at: string
    updated_at: string
    deleted_at: string | null
  } | null
  cards?: {
    id: number
    quotation_id: number
    number_installments: number
    created_at: string
    updated_at: string
    deleted_at: string | null
  } | null
  bank_transfer?: {
    id: number
    account_type: string
    quotation_id: number
    bank: string
    agency: string
    account: string
    digit: string
    cpf: string
    cnpj: string
    account_name: string
    created_at: string
    updated_at: string
    deleted_at: string | null
  } | null
  financing?: Array<{
    id: number
    quotation_id: number
    name_financier: string
    total_value: string
    value_installment: string
    entrie: string | null
    number_installments: number
    waiting_period: number
    monthly_interest_rate: string
    image_financier: string
    created_at: string
    updated_at: string
    max_value_installment: string | null
    range_installments: string | null
    status: number
    key_image_financier: string
    deleted_at: string | null
    config_simulation: string
  }> | null
}

interface OutputData {
  invoice: {
    enable: boolean
    value: string
  }
  credit_card: {
    enable: boolean
    value: string
  }
  bank_transfer: {
    enable: boolean
    value: string
  }
  financing: {
    enable: boolean
    value: string
  }
}

const DEFAULT_OUTPUT = {
  bank_transfer: {
    enable: false,
    value: '',
  },
  credit_card: {
    enable: false,
    value: '',
  },
  financing: {
    enable: false,
    value: '',
  },
  invoice: {
    enable: false,
    value: '',
  },
}

/**
 * Transforms input payment data into a structured output format.
 * Handles cases where `billets`, `cards`, `bank_transfer`, or `financing` might be null or undefined.
 *
 * @param input - The input data containing information about billets, credit cards, bank transfers, and financing.
 * @returns An object with structured payment information, including whether each payment method is enabled and its corresponding value.
 *
 */
export function handlePaymentMethodsData(input?: InputData): OutputData {
  if (!input) return DEFAULT_OUTPUT

  const billetCount = input?.billets
    ? [
        input?.billets?.billet_one,
        input?.billets?.billet_two,
        input?.billets?.billet_three,
        input?.billets?.billet_four,
      ].filter((billet) => billet !== null).length
    : 0

  const invoiceText =
    billetCount === 1 ? 'Em até 1 boleto' : `Em até ${billetCount} boletos`

  const creditCardText =
    input.cards?.number_installments === 1
      ? 'Em até 1 vez'
      : `Em até ${input.cards?.number_installments ?? 0} vezes`

  return {
    invoice: {
      enable: billetCount > 0,
      value: billetCount > 0 ? invoiceText : '',
    },
    credit_card: {
      enable: Boolean(input.cards?.number_installments),
      value: input.cards?.number_installments ? creditCardText : '',
    },
    bank_transfer: {
      enable: Boolean(input.bank_transfer?.account_type),
      value: input.bank_transfer?.account_type ? 'Transferência bancária' : '',
    },
    financing: {
      enable: Boolean(input.financing?.length),
      value: '',
    },
  }
}
