import { type IBadgeProps } from '@77sol-ui/atoms'
import { FINANCING_REQUEST_STATUS_ENUM } from 'enums/financingRequest/FinancingRequestStatusEnum'

interface IFinancingStatusColor {
  color: IBadgeProps['color']
  text: string
}

export const useFinancingStatusRequestBadge = (
  status: FINANCING_REQUEST_STATUS_ENUM,
) => {
  const statusVariant: Record<
    FINANCING_REQUEST_STATUS_ENUM,
    IFinancingStatusColor
  > = {
    [FINANCING_REQUEST_STATUS_ENUM.SOLICITADO]: {
      color: 'orange',
      text: 'Solicitado',
    },
    [FINANCING_REQUEST_STATUS_ENUM.PRE_APROVADO]: {
      color: 'blue',
      text: 'Pré aprovado',
    },
    [FINANCING_REQUEST_STATUS_ENUM.CREDITO_APROVADO]: {
      color: 'green',
      text: 'Crédito aprovado',
    },
    [FINANCING_REQUEST_STATUS_ENUM.NEGADO]: {
      color: 'red',
      text: 'Negado',
    },
    [FINANCING_REQUEST_STATUS_ENUM.CANCELADO]: {
      color: 'gray',
      text: 'Cancelado',
    },
    [FINANCING_REQUEST_STATUS_ENUM.EM_ANALISE]: {
      color: 'orange',
      text: 'Em análise',
    },
    [FINANCING_REQUEST_STATUS_ENUM.COM_PENDENCIAS]: {
      color: 'purple',
      text: 'Com pendências',
    },
    [FINANCING_REQUEST_STATUS_ENUM.CONCLUIDO]: {
      color: 'dark-blue',
      text: 'Concluído',
    },
  }

  return (
    statusVariant?.[status] || {
      color: 'gray',
      text: 'Não solicitado',
    }
  )
}
