import { CLIENTES_VIEW } from '../constants/endpointKeys'
import api from 'api'
import {
  type IClientesViewParams,
  type IClientesViewParamsResponse,
} from './types'

export const clientesView = async ({ customerId }: IClientesViewParams) => {
  return await api
    .get<IClientesViewParamsResponse>(`${CLIENTES_VIEW}/${customerId}`)
    .then(({ data }) => data)
}
