import { makeStyles } from '@material-ui/core/styles'
import { effects } from '@77sol/styles'
import palette from 'app_palette'

const styles = makeStyles((theme) => ({
  container: ({
    noMargin,
    sticky,
    half,
    size,
    zIndex,
    onBack,
    downSm,
    primary,
    fixedHeight,
    backgroundColor,
    titleColor,
  }) => ({
    cursor: onBack ? 'pointer' : 'inherit',
    display: 'flex',
    position: sticky ? 'sticky' : 'inherit',
    top: sticky ? '0px' : 'inherit',
    zIndex: zIndex || (sticky ? '1' : '0'),
    alignItems: 'center',
    width: half ? '50%' : '100%',
    justifyContent: 'space-between',
    ...(backgroundColor && {
      backgroundColor,
    }),
    ...(!backgroundColor && {
      backgroundColor: downSm && !primary ? palette.primary[300] : '#FFFFFF',
    }),
    boxShadow: effects.boxShadow.small,
    padding:
      downSm && !primary
        ? '12px 24px'
        : size === 'small'
        ? '16px 24px'
        : size === 'huge'
        ? '32px 24px'
        : '24px',
    flexWrap: 'wrap',
    containerName: 'header',
    containerType: 'inline-size',
    marginBottom: noMargin ? '0px' : '32px',
    '& p': {
      ...(titleColor && {
        color: titleColor,
      }),
      ...(!titleColor && {
        color:
          downSm && !primary ? `${theme.palette.white} !important` : 'inherit',
      }),
      paddingLeft: size === 'small' ? '8px' : onBack ? '8px' : '0px',
    },
    height: fixedHeight && '92px',
    [theme.breakpoints.down('sm')]: {
      gap: 16,
      height: fixedHeight && '62px',
    },
  }),
  icon: ({ size, downSm, primary, titleColor }) => ({
    height: size === 'small' || (downSm && !primary) ? '24px' : '32px',
    width: size === 'small' || (downSm && !primary) ? '24px' : '32px',
    ...(titleColor && {
      color: titleColor,
    }),
    ...(!titleColor && {
      color: downSm && !primary ? 'white' : '#444A58',
    }),

    cursor: 'pointer',
  }),
  navBar: () => ({
    zIndex: 3,
    width: 230,
    minWidth: 230,
    flex: '0 0 auto',
    overflowY: 'auto',
  }),
  menuContainer: () => ({
    display: 'block',
    cursor: 'pointer',
    marginRight: '16px',
    '@media (min-width: 1280px)': {
      display: 'none',
    },
  }),
}))

export default styles
