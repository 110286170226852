import styled from 'styled-components'

export interface ITemplatesProps {
  noWrap?: boolean
}

export const Templates = styled.main<ITemplatesProps>`
  display: flex;
  flex-wrap: ${({ noWrap }) => (noWrap ? 'nowrap' : 'wrap')};
  justify-content: center;
  gap: 24px;
`
