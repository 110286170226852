import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFinancingRequestParams } from './useFinancingRequestParams'
import { type IFinancingSimulationParams } from '../types'

export function useFinancingRedirect() {
  const history = useHistory()

  const { params } = useFinancingRequestParams()
  const { financingId, simulationId } = useParams<IFinancingSimulationParams>()

  const redirectFinancingList = useCallback(() => {
    history.push(`/financiamentos?${params}`)
  }, [params, history])

  const redirectSimulationList = useCallback(
    (id = financingId) => {
      history.push(`/financiamentos/${id}?${params}`)
    },
    [params, history, financingId],
  )

  const redirectSimulationHistory = useCallback(
    (id = simulationId) => {
      history.push(`/financiamentos/${financingId}/simulacao/${id}?${params}`)
    },
    [params, history, financingId, simulationId],
  )

  return {
    financingId,
    simulationId,
    redirectFinancingList,
    redirectSimulationList,
    redirectSimulationHistory,
  }
}
