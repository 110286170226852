import { CLIENTES_VIEW } from 'domains/clientes/clientes-view/constants/endpointKeys'
import { PROPOSAL_LIST_BUDGETS } from 'domains/proposal/proposal-list-budgets/constants/endpointKeys'
import { PROPOSAL_QUOTATION_PAYMENT_METHODS } from 'domains/proposal/proposal-quotation-payment_methods/constants/endpointKeys'
import { PROPOSAL_QUOTATION_ROI_CALCULATE } from 'domains/proposal/proposal-quotation-roi-calculate/constants/endpointKeys'
import { queryClient } from 'services/queryClient'

export function handleRemoveQueries() {
  queryClient.removeQueries({
    queryKey: [PROPOSAL_LIST_BUDGETS],
    exact: false,
  })

  queryClient.removeQueries({
    queryKey: [PROPOSAL_QUOTATION_PAYMENT_METHODS],
    exact: false,
  })

  queryClient.removeQueries({
    queryKey: [PROPOSAL_QUOTATION_ROI_CALCULATE],
    exact: false,
  })

  queryClient.removeQueries({
    queryKey: [CLIENTES_VIEW],
    exact: false,
  })
}
