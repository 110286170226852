import { useEffect, useRef } from 'react'

export function useScrollToCenter() {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  function scrollToCenter(
    container: HTMLElement,
    elementId: string,
    type: 'horizontal' | 'vertical' = 'horizontal',
  ) {
    timeoutRef.current && clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      const element = document.querySelector(`#${elementId}`) as HTMLElement

      if (element && container) {
        if (type === 'horizontal') {
          const distanceToCenter =
            element.offsetLeft -
            container.offsetWidth / 2 +
            element.offsetWidth / 2

          container.scrollTo({
            left: distanceToCenter,
            behavior: 'smooth',
          })
          return
        }
        if (type === 'vertical') {
          const distanceToCenter =
            element.offsetTop -
            container.offsetHeight / 2 +
            element.offsetHeight / 2

          container.scrollTo({
            top: distanceToCenter,
            behavior: 'smooth',
          })
        }
      }
    }, 50)
  }

  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current)
    }
  }, [])

  return { scrollToCenter }
}
