import { create } from 'zustand'

interface IOpenModalExportStoreProps {
  exportModalIsOpen: boolean
  openExportModal: (value: boolean) => void
  resetExportModalStore: () => void
}

const INITIAL_EXPORT_MODAL = {
  exportModalIsOpen: false,
}

export const useOpenExportModalStore = create<IOpenModalExportStoreProps>(
  (set) => ({
    ...INITIAL_EXPORT_MODAL,
    openExportModal: (value: boolean) => {
      set({ exportModalIsOpen: value })
    },
    resetExportModalStore: () => {
      set(INITIAL_EXPORT_MODAL)
    },
  }),
)
